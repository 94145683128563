import { createSlice } from "@reduxjs/toolkit";

type LoaderState = {
  isShowLoader: boolean;
};

const initialState: LoaderState = {
  isShowLoader: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isShowLoader = true;
    },
    hiddenLoader: (state) => {
      state.isShowLoader = false;
    },
    clearLoaderState: () => {
      return initialState;
    },
  },
});

export const { showLoader, hiddenLoader, clearLoaderState } =
  loaderSlice.actions;

export default loaderSlice.reducer;
