import React, { FC, useState } from "react";
import classNames from "classnames";
import styles from "./DropDownElement.module.scss";
import ArrowDownIcon from "assets/svg/arrow-down.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface DropDownElementProps {
  setSelectedStudio: (val: { id: number; name: string }) => void;
  selectedStudio: { id: number; name: string } | null;
  title: string;
  data: { id: number; name: string }[];
}

const DropDownElement: FC<DropDownElementProps> = ({
  selectedStudio,
  setSelectedStudio,
  title,
  data,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const selectStudio = (val: { id: number; name: string }) => {
    setSelectedStudio(val);
    handleMenuClose();
  };

  return (
    <div
      className={classNames(styles.studioTypeContainer, "flex gap8 a-items-c")}
    >
      <div className={"flex a-items-c flex-start gap24"}>
        <div
          onClick={handleMenuOpen}
          className={"flex a-items-c cursor-pointer"}
          style={{
            background: "rgba(26, 26, 26, 1)",
            height: 32,
            paddingLeft: 8,
            paddingRight: 8,
            borderRadius: 8,
          }}
        >
          <span
            style={{
              width: 100,
              marginLeft: 10,
              color: "rgba(152, 152, 152, 1)",
              fontFamily: "MatterRegular",
              fontSize: 12,
            }}
          >
            {selectedStudio ? selectedStudio.name : title}
          </span>
          <img src={ArrowDownIcon} alt="" width={24} height={24} />
        </div>
        <Menu
          id="menu-basic"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              background: "rgba(30, 30, 30, 1)",
              borderRadius: "16px",
              borderWidth: 0,
              color: "rgba(152, 152, 152, 1)",
              fontSize: 12,
              fontFamily: "MatterRegular",
            },
          }}
          PaperProps={{ sx: { background: "inherit" } }}
        >
          {data.map((studio) => (
            <MenuItem key={studio.id} onClick={() => selectStudio(studio)}>
              {studio.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default DropDownElement;
