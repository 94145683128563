import React, { useEffect, useState } from "react";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import styles from "./HomePage.module.scss";
import classNames from "classnames";
import Logo from "assets/svg/logo.svg";
import UnionIcon from "assets/svg/Union.svg";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import { clearPersistor, useAppSelector } from "../../store";
import AppLoader from "../../components/AppLoader/AppLoader";
import { clearToken } from "../../helpers/localStorageHelpers";

const HomePage = () => {
  const { isShowLoader } = useAppSelector((state) => state.loader);
  const [formType, setFormType] = useState<"login" | "signup" | "forgotPass">(
    "login",
  );

  useEffect(() => {
    clearPersistor().catch();
    clearToken();
  }, []);

  return (
    <div
      className={classNames(
        styles.container,
        "full-height full-width flex padding24",
      )}
    >
      <div className="full-width full-height flex flex-column gap24">
        <div
          className="border-light border-radius32 flex flex-column a-items-c flex-start"
          style={{
            height: "90%",
            backgroundColor: "rgba(26, 26, 26, 1)",
            borderWidth: 1.5,
            paddingTop: "3vh",
          }}
        >
          <img src={Logo} alt="" width={275} />
          {formType === "login" ? (
            <SignIn setFormType={setFormType} />
          ) : formType === "signup" ? (
            <SignUp setFormType={setFormType} />
          ) : (
            <ForgotPassword setFormType={setFormType} />
          )}
        </div>
        <div
          className="border-light border-radius32 flex a-items-c"
          style={{
            height: "10%",
            backgroundColor: "rgba(26, 26, 26, 1)",
            borderWidth: 1.5,
          }}
        >
          <p className={styles.description}>
            © 2024. Remote is a{" "}
            <span className="text-with-underline light-text">Studio Pod</span>{" "}
            Product.{" "}
          </p>
        </div>
      </div>
      <div
        className={classNames(
          "border-radius32 flex a-items-c",
          styles.rightSide,
        )}
      >
        <img src={UnionIcon} alt="" />
      </div>
      {isShowLoader && <AppLoader />}
    </div>
  );
};

export default HomePage;
