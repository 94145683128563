import React, { ReactNode } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs } from "@mui/material";

const BreadcrumbsComponent = ({ breadcrumbs }: { breadcrumbs: ReactNode }) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" sx={{ color: "white" }} />}
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
