import React, { ChangeEvent, useEffect, useState } from "react";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import styles from "./EditContactDetails.module.scss";
import { useAppDispatch, useAppSelector } from "store";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import { isValidEmail } from "utils/validatiors";
import { hiddenLoader, showLoader } from "store/slices/loader";
import http from "helpers/http";
import { updateAllContacts } from "store/slices/contacts";
import ActionsModal from "components/modals/ActionsModal/ActionsModal";
import { contactsTabName } from "constants/tabs";
import { setActiveContactsActiveTab } from "store/slices/tabs";
const EditContactDetails = ({
  setIsEditMode,
}: {
  setIsEditMode: (val: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { activeContactDetailsData } = useAppSelector(
    (state) => state.contacts,
  );

  const [firstName, setFirstName] = useState(
    activeContactDetailsData?.firstName || "",
  );
  const [lastName, setLastName] = useState(
    activeContactDetailsData?.lastName || "",
  );
  const [email, setEmail] = useState(activeContactDetailsData?.email || "");
  const [phone, setPhone] = useState(activeContactDetailsData?.phone || "");
  const { contactsLists } = useAppSelector((state) => state.contacts);
  const [checkedLists, setCheckedLists] = useState<number[]>([]);

  // console.log("contactsLists", contactsLists);

  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const newCheckedLists = contactsLists
      .filter(
        (contact) =>
          activeContactDetailsData?.contactLists.some(
            (selected) => selected.id === contact.id,
          ),
      )
      .map((contact) => contact.id);
    setCheckedLists(newCheckedLists);
  }, [activeContactDetailsData]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setCheckedLists([...checkedLists, +value]);
    } else {
      setCheckedLists(checkedLists.filter((item) => item !== +value));
    }
  };
  const handleChangeFirstName = (val: string) => {
    setFirstName(val);
  };

  const handleChangeLastName = (val: string) => {
    setLastName(val);
  };

  const handleChangeEmail = (val: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmail(val);
  };
  const handleChangePhone = (val: string) => {
    setPhone(val);
  };

  const onEditContact = async () => {
    if (email) {
      if (isValidEmail(email) && activeContactDetailsData?.id) {
        dispatch(showLoader());
        try {
          await http.put("/contact/update", {
            firstName: firstName ? firstName : null,
            lastName: lastName ? lastName : null,
            email,
            phone: phone ? phone : null,
            contactListIds: checkedLists.length ? checkedLists : null,
            id: activeContactDetailsData.id,
          });
          const res: any = await http.get("/contact/getAll", {
            params: {
              PageSize: 100,
              PageNumber: 1,
            },
          });
          dispatch(updateAllContacts(res?.data?.items));
          dispatch(hiddenLoader());
          setIsEditMode(false);
        } catch (e: any) {
          if (e?.responseStatus?.code === 1034) {
            setEmailError("Email is already taken");
          }
          dispatch(hiddenLoader());
        }
      } else {
        setEmailError("Email is not valid");
      }
    } else {
      setEmailError("Email is required");
    }
  };

  const onDeleteContact = async () => {
    try {
      dispatch(showLoader());
      if (activeContactDetailsData?.id) {
        await http.delete(`/contact/delete/${activeContactDetailsData.id}`);
      }
      dispatch(hiddenLoader());
      toggleDeleteModal(false);
      dispatch(setActiveContactsActiveTab(contactsTabName.list));
    } catch (e) {
      console.log(e);
      dispatch(hiddenLoader());
    }
  };

  return (
    <>
      <div className="flex justify-space-between a-items-c mt-4">
        <h2>Edit contact</h2>
        <div className="flex gap8">
          <WhiteButton
            text="Cancel"
            onClick={() => setIsEditMode(false)}
            customStyles={"black-btn custom-studios-btn"}
          />
          <WhiteButton
            text="Delete"
            onClick={() => toggleDeleteModal(true)}
            customStyles={"red-btn custom-studios-btn"}
          />
          <WhiteButton
            text="Save"
            customStyles={"custom-studios-btn"}
            onClick={onEditContact}
            // isDisabled={!studioName}
          />
        </div>
      </div>
      <div className="mt40 flex justify-space-between">
        <div className={styles.grayContainer}>
          <div className="full-width">
            <DefaultInput
              label="First Name"
              placeholder="Enter first name"
              onChange={handleChangeFirstName}
              inputVal={firstName}
              maxLength={100}
            />
          </div>
          <div className="full-width">
            <DefaultInput
              label="Last Name"
              placeholder="Enter last name"
              onChange={handleChangeLastName}
              inputVal={lastName}
              maxLength={100}
            />
          </div>
          <div className={"full-width"}>
            <DefaultInput
              label="Email"
              placeholder="Enter email"
              onChange={handleChangeEmail}
              inputVal={email}
              error={emailError}
              maxLength={256}
            />
          </div>
          <div className="full-width">
            <DefaultInput
              label="Phone"
              placeholder="Enter phone number"
              onChange={handleChangePhone}
              inputVal={phone}
              maxLength={20}
            />
          </div>
        </div>
        {contactsLists.length && (
          <div className={styles.contactListContainer}>
            <div
              style={{ width: "90%", textOverflow: "ellipsis" }}
              className="overflow-hidden"
            >
              <p className="font14 light-text mb-10">Contact lists</p>
              <div
                className="flex flex-column a-items-start full-height flex-start"
                style={{
                  height: 328,
                  overflow: "scroll",
                }}
              >
                {contactsLists
                  .filter((item) => item.id !== 0)
                  .map((item) => {
                    return (
                      <div key={item.id} className="flex a-items-c gap8">
                        <input
                          type="checkbox"
                          id={`checkbox-${item.id}`}
                          value={item.id}
                          checked={checkedLists.includes(item.id)}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor={`checkbox-${item.id}`}
                          className="font16 light-text text-nowrap overflow-ellipsis"
                        >
                          {item.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        <ActionsModal
          isOpen={isDeleteModalOpen}
          onClose={() => toggleDeleteModal(false)}
          successAction={onDeleteContact}
          title={"Delete contact"}
          successActionText={"Delete"}
          isWarning
        >
          <p className="text-white mt10">
            Are you sure you&apos;d like to delete this contact? Submissions for
            this contact will also be deleted.
          </p>
        </ActionsModal>
      </div>
    </>
  );
};

export default EditContactDetails;
