import React, { useEffect } from "react";
import http from "helpers/http";
import { useAppDispatch, useAppSelector } from "store";
import { setGalleryData } from "store/slices/gallery";
import { hiddenLoader, showLoader } from "store/slices/loader";
import ImageCard from "./components/ImageCard/ImageCard";
import JSZip from "jszip";
import ImportIcon from "assets/svg/import-black.svg";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";

const Gallery = () => {
  const dispatch = useAppDispatch();
  const { galleryData } = useAppSelector((state) => state.gallery);
  const fetchGalleryData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/gallery/getAllAcceptedSubmission", {
        params: { PageSize: 100, PageNumber: 1 },
      });
      if (res.success) {
        dispatch(setGalleryData(res.data.items));
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };
  const downloadImages = async () => {
    const imagesArray =
      galleryData.length > 100
        ? [...galleryData].slice(0, 100).map((item) => item.url)
        : galleryData.map((item) => item.url);

    const zip = new JSZip();

    try {
      const downloadPromises = imagesArray.map(async (image, index) => {
        const imageName = image.split("/");
        const lastItem = imageName[imageName.length - 1];
        const response = await fetch(image);

        if (!response.ok) {
          throw new Error(`Failed to download image: ${image}`);
        }

        const blob = await response.blob();
        return zip.file(`image-${index}-${lastItem}`, blob);
      });

      await Promise.all(downloadPromises);

      const content = await zip.generateAsync({ type: "blob" });
      const url = window.URL.createObjectURL(content);

      const link = document.createElement("a");
      link.href = url;
      link.download = "images.zip";
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading images:", error);
    }
  };

  useEffect(() => {
    fetchGalleryData().catch();
  }, []);

  return (
    <div className="full-height full-width">
      <div className="flex justify-space-between a-items-c">
        <h2>Gallery</h2>
        <WhiteButton
          text="Download Displayed Photos"
          onClick={downloadImages}
          icon={ImportIcon}
          customStyles={"custom-studios-btn"}
          isDisabled={!galleryData.length}
        />
      </div>
      <div className="flex gap20 mt40 flex-start" style={{ flexWrap: "wrap" }}>
        {galleryData.length ? (
          galleryData.map((item) => (
            <ImageCard key={item.id} imgUrl={item.url} />
          ))
        ) : (
          <h2>You don`t have photos.</h2>
        )}
      </div>
    </div>
  );
};

export default Gallery;
