import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LeftDrawer from "components/LeftDrawer/LeftDrawer";
import TopContainer from "components/TopContainer/TopContainer";
import Dashboard from "./Tabs/Dashboard/Dashboard";
import "./styles.scss";
import Studios from "./Tabs/Studios/Studios";
import { useAppDispatch, useAppSelector } from "store";
import { tabsName } from "constants/tabs";
import AppLoader from "components/AppLoader/AppLoader";
import http from "helpers/http";
import {
  setStudioBackgroundOptions,
  setStudioPoseProfiles,
  setStudioReminderPeriods,
  updateStudioList,
} from "store/slices/studios";
import Contacts from "./Tabs/Contacts/Contacts";
import {
  setPredictions,
  updateAllContacts,
  updateContactsLists,
  updateListData,
} from "store/slices/contacts";
import Outputs from "./Tabs/Outputs/Outputs";
import { setOutputs, setOutputsOptions } from "store/slices/outputs";
import { hiddenLoader, showLoader } from "store/slices/loader";
import Submissions from "./Tabs/Submissions/Submissions";
import { setAllSubmissionsData } from "store/slices/submissions";
import Gallery from "./Tabs/Gallery/Gallery";

const Layout = () => {
  const dispatch = useAppDispatch();
  const { activeTab } = useAppSelector((state) => state.tabs);
  const { isShowLoader } = useAppSelector((state) => state.loader);
  const predictionsContainerRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        predictionsContainerRef?.current &&
        !predictionsContainerRef.current.contains(event.target as Node)
      ) {
        setSearchTerm("");
        dispatch(setPredictions([]));
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [predictionsContainerRef]);

  const fetchStudiosList = async () => {
    try {
      dispatch(showLoader());
      const resGetList: any = await http.get("/Studio/getAll", {
        params: {
          PageSize: 100,
          PageNumber: 1,
        },
      });
      dispatch(updateStudioList(resGetList.data.items));
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const fetchContactsLists = async () => {
    try {
      const res: any = await http.get("/contactList/getAll", {
        params: { PageSize: 100, PageNumber: 1 },
      });
      dispatch(updateContactsLists(res.data.items));
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStudioManageOptions = async () => {
    try {
      const res: any = await http.get("/studio/reminder-periods");
      const res2: any = await http.get("/studio/system-studio-pose-profiles");
      dispatch(setStudioReminderPeriods(res));
      if (res2.success) dispatch(setStudioPoseProfiles(res2.data));
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllSubmissionsData = async () => {
    try {
      const res: any = await http.get("/session/getAllCompleted", {
        params: { PageSize: 100, PageNumber: 1 },
      });
      if (res.success) {
        dispatch(setAllSubmissionsData(res.data.items));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStudioBackgroundOptions = async () => {
    try {
      const res: any = await http.get("/studio/studio-background-options");
      if (res.success) {
        dispatch(setStudioBackgroundOptions(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchOutputs = async () => {
    try {
      const res: any = await http.get("/Output/getAll", {
        params: { PageSize: 100, PageNumber: 1 },
      });
      if (res.success) {
        dispatch(setOutputs(res.data.items));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchOutputsOptions = async () => {
    try {
      const res: any = await http.get("output/configuration-options");
      dispatch(setOutputsOptions(res.data));
    } catch (e) {
      console.log(e);
    }
  };

  const fetchContacts = async () => {
    try {
      const res: any = await http.get("/contact/getAll", {
        params: {
          PageSize: 10,
          PageNumber: 1,
        },
      });
      dispatch(updateListData({ id: 0, contacts: res.data.items }));
      dispatch(updateAllContacts(res.data.items));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (activeTab === tabsName.studios) {
      // fetchStudiosList().catch();
    }
    if (activeTab === tabsName.contacts) {
      fetchContactsLists().catch();
    }
  }, [activeTab]);

  useEffect(() => {
    fetchStudiosList().catch();
    fetchContacts().catch();
    fetchOutputs().catch();
    fetchStudioManageOptions().catch();
    fetchStudioBackgroundOptions().catch();
    fetchOutputsOptions().catch();
    fetchAllSubmissionsData().catch();
  }, []);

  return (
    <div className={"full-height"}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          backgroundColor: "rgba(17, 17, 17, 1)",
        }}
      >
        <LeftDrawer />
        <div className={"full-width position-relative full-height"}>
          <TopContainer
            ref={predictionsContainerRef}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          <div
            className={
              "a-items-c full-width position-relative overflow-scroll custom-height"
            }
          >
            <div
              className={
                "a-items-c full-width position-relative contentContainer full-height"
              }
            >
              {activeTab === tabsName.dashboard && <Dashboard />}
              {activeTab === tabsName.studios && <Studios />}
              {activeTab === tabsName.contacts && <Contacts />}
              {activeTab === tabsName.outputs && <Outputs />}
              {activeTab === tabsName.submissions && <Submissions />}
              {activeTab === tabsName.gallery && <Gallery />}
            </div>
          </div>
          {/*{tabSelected === "contacts" && (*/}
          {/*  <Card sx={{ minWidth: 275 }}>*/}
          {/*    <CardContent>*/}
          {/*      <ContactsCard />*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*)}*/}
        </div>
        {isShowLoader && <AppLoader />}
      </Box>
    </div>
  );
};

export default Layout;
