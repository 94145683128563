import * as React from "react";
import { FC, useState } from "react";
import http from "helpers/http";
import styles from "./ForgotPassword.module.scss";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";
import { isValidEmail } from "utils/validatiors";
import { useAppDispatch } from "../../../../store";
import { hiddenLoader, showLoader } from "../../../../store/slices/loader";

type ForgotPasswordProps = {
  setFormType: (val: "login" | "signup" | "forgotPass") => void;
};

const ForgotPassword: FC<ForgotPasswordProps> = ({ setFormType }) => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, toggleModal] = useState(false);
  const [isSendMessageSuccess, sendMessageSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleChangeEmail = (val: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmail(val);
  };
  const onClickLogin = async () => {
    dispatch(showLoader());
    if (isValidEmail(email)) {
      try {
        const res: any = await http.post("/user/forget-password", {
          email,
        });
        dispatch(hiddenLoader());
        if (res?.success) {
          sendMessageSuccess(true);
          setModalMessage(
            "Check email. A letter was sent to the e-mail address.",
          );
          toggleModal(true);
        }
      } catch (e: any) {
        if (e.responseStatus.code === 1000 || e.responseStatus.code === 1013) {
          setEmailError(
            "We could not find this email. Please check your email and try again.",
          );
        }
        dispatch(hiddenLoader());
      }
    } else {
      setEmailError(
        "You have entered an invalid email address. Please try again.",
      );
      dispatch(hiddenLoader());
    }
  };

  const closeNotificationModal = () => {
    if (isSendMessageSuccess) {
      setFormType("login");
    }
    toggleModal(false);
  };

  return (
    <div className={styles.container}>
      <p
        className="light-text t-align-center"
        style={{ fontSize: 23, marginTop: "3vh" }}
      >
        You will receive instructions for password recovery
      </p>
      <div className="flex flex-column gap16 mt40">
        <DefaultInput
          label="Email"
          placeholder="Enter email address"
          onChange={handleChangeEmail}
          inputVal={email}
          error={emailError}
        />
      </div>
      <div className="full-width" style={{ height: 44 }}>
        <WhiteButton
          text="Send"
          customStyles="full-width t-align-center border-radius16 flex a-items-c mt-20"
          onClick={onClickLogin}
          isDisabled={!email || !!modalMessage}
        />
        <div
          className="light-gray-text font14 t-align-right mt10 cursor-pointer"
          onClick={() => setFormType("login")}
        >
          Back to{" "}
          <span className="text-with-underline light-text">Sign in</span>
        </div>
      </div>
      <NotificationModal
        onClose={closeNotificationModal}
        isOpen={isModalOpen}
        text={modalMessage}
      />
    </div>
  );
};

export default ForgotPassword;
