import { createSlice } from "@reduxjs/toolkit";

type Tab = {
  id: number;
  name: string;
};

export type InvitedContact = {
  contactEmail: string;
  contactFirstName: null | string;
  contactId: number;
  contactLastName: null | string;
  createdAt: string;
  createdDate: Date;
  id: number;
  lastReminderAt: string;
  studioId: number;
  studioName: string;
};

export type Submission = {
  id: 64;
  state: 2;
  url: string;
};

export type Session = {
  contact: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    phone: string | null;
  };
  sessionCreatedAt: string;
  sessionCreatedDate: Date;
  sessionId: number;
  sessionUrl: string;
  state: number;
  studio: {
    id: number;
    name: string;
  };
  submissions: Submission[];
};

type SubmissionsState = {
  submissionsLists: Tab[];
  invitedData: InvitedContact[];
  allSubmissions: Session[];
  reviewData: Session[];
  rejectedData: Session[];
  acceptedData: Session[];
};

const initialState: SubmissionsState = {
  submissionsLists: [
    { id: 0, name: "All Submissions" },
    { id: 1, name: "Review" },
    { id: 2, name: "Accepted" },
    { id: 3, name: "Rejected" },
    { id: 4, name: "Invited" },
  ],
  invitedData: [],
  allSubmissions: [],
  reviewData: [],
  rejectedData: [],
  acceptedData: [],
};

export const submissionsSlice = createSlice({
  name: "submissions",
  initialState,
  reducers: {
    setInvitedData: (state, action) => {
      state.invitedData = [...action.payload];
    },
    setAllSubmissionsData: (state, action) => {
      state.allSubmissions = [...action.payload];
    },
    setAllReviewData: (state, action) => {
      state.reviewData = [...action.payload];
    },
    setAllRejectedData: (state, action) => {
      state.rejectedData = [...action.payload];
    },
    setAllAcceptedData: (state, action) => {
      state.acceptedData = [...action.payload];
    },
    clearSubmissionsLists: () => {
      return initialState;
    },
  },
});

export const {
  clearSubmissionsLists,
  setInvitedData,
  setAllSubmissionsData,
  setAllReviewData,
  setAllRejectedData,
  setAllAcceptedData,
} = submissionsSlice.actions;

export default submissionsSlice.reducer;
