import React from "react";
import { useAppSelector } from "store";
import { outputsTabName } from "constants/tabs";
import ListsComponent from "./components/ListsComponent/ListsComponent";
import EditOutputComponent from "./components/EditOutputComponent/EditOutputComponent";

const Contacts = () => {
  const { outputsActiveTab } = useAppSelector((state) => state.tabs);
  return (
    <div className="full-height full-width">
      {outputsActiveTab === outputsTabName.list ? (
        <ListsComponent />
      ) : (
        <EditOutputComponent />
      )}
    </div>
  );
};

export default Contacts;
