import { createSlice } from "@reduxjs/toolkit";

export type Output = {
  id: number;
  isDisplayToContactInStudio: boolean;
  name: string;
  outputCropFactor: number;
  outputCropPosition: number;
  outputImageFormat: number;
  outputPhotoFilter: number;
  outputResize: number;
  outputSampleImageUrl: string;
};

type OutputsState = {
  outputs: Output[];
  outputsOptions: {
    cropFactors: { Id: number; Name: string }[];
    cropPositions: { Id: number; Name: string }[];
    imageFormats: { Id: number; Name: string }[];
    photoFilters: { Id: number; Name: string }[];
    resizes: { Id: number; Name: string }[];
  } | null;
  activeOutputId: number | null;
};

const initialState: OutputsState = {
  outputs: [],
  outputsOptions: null,
  activeOutputId: null,
};

export const outputsSlice = createSlice({
  name: "outputs",
  initialState,
  reducers: {
    setOutputs: (state, action) => {
      state.outputs = [...action.payload];
    },
    setOutputsOptions: (state, action) => {
      state.outputsOptions = { ...action.payload };
    },
    setActiveOutputId: (state, action) => {
      state.activeOutputId = action.payload;
    },
    clearOutputs: () => {
      return initialState;
    },
  },
});

export const {
  setOutputs,
  clearOutputs,
  setOutputsOptions,
  setActiveOutputId,
} = outputsSlice.actions;

export default outputsSlice.reducer;
