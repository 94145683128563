import React, { useState } from "react";
import styles from "./CreateStudio.module.scss";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import MenuItem from "@mui/material/MenuItem";
import ArrowDownIcon from "assets/svg/arrow-down.svg";
import SelectedRadioBtn from "assets/svg/chosed-radio-icon.svg";
import DefaultRadioBtn from "assets/svg/default-radio-icon.svg";
import IOSSwitch from "../IOSswitcher/IOSswitcher";
import classNames from "classnames";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import http from "helpers/http";
import { useAppDispatch } from "store";
import { setActiveStudiosTab } from "store/slices/tabs";
import { studiosTabName } from "constants/tabs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import moment from "moment/moment";
import { hiddenLoader, showLoader } from "store/slices/loader";
import Menu from "@mui/material/Menu";
import GlobalWhiteIcon from "assets/svg/global.svg";
import GlobalIcon from "assets/svg/global-icon.svg";

const CreateStudio = () => {
  const [studioName, setStudioName] = useState("");
  const [selectedPhotoReview, setSelectedPhotoReview] = useState("require");
  const [isShowGalleryChecked, toggleShowGallery] = useState(false);
  const [isSuggestMobileDeviceChecked, toggleSuggestMobileDevice] =
    useState(false);
  const [isSendAutomaticRemindersChecked, toggleSendAutomaticReminders] =
    useState(false);

  const dispatch = useAppDispatch();
  const onChangeStudioName = (val: string) => {
    setStudioName(val);
  };

  const onToggleShowGallery = () => {
    toggleShowGallery((prev) => !prev);
  };

  const onToggleSendAutomaticReminders = () => {
    toggleSendAutomaticReminders((prev) => !prev);
  };

  const onToggleSuggestMobileDevice = () => {
    toggleSuggestMobileDevice((prev) => !prev);
  };

  const [studioType, setStudioType] = useState("Public");
  const [startStudioDate, setStartStudioDate] = useState(new Date());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const createStudio = async () => {
    try {
      dispatch(showLoader());
      const newStartDate = moment(startStudioDate).format("YYYY-MM-DD");
      const res: any = await http.post("/Studio/create", {
        name: studioName,
        startDate: newStartDate,
        studioType: studioType === "Public" ? 2 : 1,
        photoReviewAndAccess: {
          isAutoAcceptSubmissions: selectedPhotoReview !== "require",
          isShowGallery: isShowGalleryChecked,
          isSuggestMobileDevice: isSuggestMobileDeviceChecked,
        },
        isSendAutomaticReminders: isSendAutomaticRemindersChecked,
      });
      if (res.success) {
        dispatch(hiddenLoader());
        dispatch(setActiveStudiosTab(studiosTabName.list));
      }
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const handleChangDate = (e: Date) => {
    setStartStudioDate(e);
  };

  return (
    <>
      <div className="flex justify-space-between a-items-c">
        <h2>Create Studio</h2>
        <div className="flex gap8">
          <WhiteButton
            text="Cancel"
            onClick={() => dispatch(setActiveStudiosTab(studiosTabName.list))}
            customStyles={"black-btn custom-studios-btn"}
          />
          <WhiteButton
            text="Create"
            customStyles={"custom-studios-btn"}
            onClick={createStudio}
            isDisabled={!studioName}
          />
        </div>
      </div>
      <div className="mt40">
        <div className={styles.studioGrayContainer}>
          <div className="flex a-items-c gap8 flex-start">
            <p className="light-text t-align-left">Studio Name:</p>
            <DefaultInput
              onChange={onChangeStudioName}
              inputVal={studioName}
              placeholder={"Enter Studio Name"}
            />
          </div>
          <div className={"flex a-items-c flex-start gap8 position-relative"}>
            <p className="light-text t-align-left">Studios Type:</p>
            <div
              onClick={handleMenuOpen}
              className={"flex a-items-c cursor-pointer"}
              style={{
                background: "rgba(26, 26, 26, 1)",
                height: 40,
                paddingLeft: 8,
                paddingRight: 8,
                borderRadius: 8,
                width: 174,
              }}
            >
              <img src={GlobalWhiteIcon} alt="" width={24} height={24} />
              <span
                style={{
                  width: 100,
                  marginLeft: 10,
                  color: "rgba(152, 152, 152, 1)",
                  fontFamily: "MatterRegular",
                }}
              >
                {studioType}
              </span>
              <img src={ArrowDownIcon} alt="" width={24} height={24} />
            </div>
            <Menu
              id="menu-basic"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                sx: {
                  // Style the entire MenuList container
                  background: "rgba(30, 30, 30, 1)",
                  borderRadius: "16px",
                  borderWidth: 0,
                  color: "rgba(152, 152, 152, 1)",
                  fontSize: 12,
                  fontFamily: "MatterRegular",
                },
              }}
              PaperProps={{ sx: { background: "inherit", width: "174px" } }}
            >
              <MenuItem
                onClick={() => {
                  setStudioType("Public");
                  handleMenuClose();
                }}
              >
                Public
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setStudioType("Private");
                  handleMenuClose();
                }}
              >
                Private
              </MenuItem>
            </Menu>
          </div>
          <div className="flex a-items-c gap8 flex-start">
            <p className="font14 light-text">Studio Start Date:</p>
            <DatePicker selected={startStudioDate} onChange={handleChangDate} />
          </div>
        </div>
        <div className="mt40 flex gap20 flex-start a-items-start">
          <div className={styles.blockContainer}>
            <div className="flex flex-start gap8 a-items-c">
              <img src={GlobalIcon} alt="" />{" "}
              <p className="gold-text">Photo Review & Access</p>
            </div>
            <div className="bg-dark-gray padding16 border-radius16 mt-20 flex flex-column gap24">
              <div
                className={"flex flex-start a-items-start gap8 cursor-pointer"}
                onClick={() => setSelectedPhotoReview("require")}
              >
                <img
                  src={
                    selectedPhotoReview === "require"
                      ? SelectedRadioBtn
                      : DefaultRadioBtn
                  }
                  alt=""
                />
                <div>
                  <p className="light-text font13">Require Submission Review</p>
                  <p className="gray2-text font12 mt6">
                    All submissions for this studio must be accepted or
                    rejected.
                  </p>
                </div>
              </div>
              <div
                className={"flex flex-start a-items-start gap8 cursor-pointer"}
                onClick={() => setSelectedPhotoReview("auto")}
              >
                <img
                  src={
                    selectedPhotoReview === "auto"
                      ? SelectedRadioBtn
                      : DefaultRadioBtn
                  }
                  alt=""
                />
                <div>
                  <p className="light-text font13">Auto-Accept Submissions</p>
                  <p className="gray2-text font12 mt6">
                    All submissions for this studio are automatically accepted.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <div className={"flex flex-start a-items-start gap8"}>
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={onToggleShowGallery}
                  checked={isShowGalleryChecked}
                />
                <div>
                  <p className="light-text font13">Show Gallery</p>
                  <p className="gray2-text font12 mt6">
                    Display a gallery screen to contacts once their submission
                    is approved.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <div className={"flex flex-start a-items-start gap8"}>
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={onToggleSuggestMobileDevice}
                  checked={isSuggestMobileDeviceChecked}
                />
                <div>
                  <p className="light-text font13">Suggest Mobile Device</p>
                  <p className="gray2-text font12 mt6">
                    if the user is using non-mobile device, show them a message
                    suggesting they use their phone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.blockContainer)}>
            <div className="flex flex-start gap8 a-items-c">
              <img src={GlobalIcon} alt="" />{" "}
              <p className="gold-text">Invite Reminders</p>
            </div>
            <div className="flex flex-column full-height mt-20">
              <div className={"flex flex-start a-items-start gap8"}>
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={onToggleSendAutomaticReminders}
                  checked={isSendAutomaticRemindersChecked}
                />
                <div>
                  <p className="light-text font13">Invite Reminders</p>
                  <p className="gray2-text font12 mt6">
                    Automatically send reminder emails to contacts who have been
                    and have yet to submit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStudio;
