import React, { FC } from "react";
import Modal from "react-modal";
import styles from "./SubmissionsModal.module.scss";
import CloseIcon from "assets/svg/close-icon.svg";
import classNames from "classnames";
import WhiteButton from "../../buttons/WhiteButton/WhiteButton";
import { Session } from "store/slices/submissions";
import ViewOrigin from "assets/svg/send.svg";
import ExportIcon from "assets/svg/export.svg";
import { submissionStatus } from "constants/sybmissions";
import TrashIcon from "assets/svg/trash.svg";
import CancelIcon from "assets/svg/close-circle.svg";
import http from "../../../helpers/http";
import { useAppDispatch } from "store";
import { hiddenLoader, showLoader } from "store/slices/loader";
import TickIcon from "assets/svg/tick-square.svg";
import JSZip from "jszip";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(26, 26, 26, 1)",
    boxShadow: "0 24px 45.20000076293945px 0 rgba(0, 0, 0, 0.25)",
    borderRadius: 24,
    padding: 24,
  },
};

type ActionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  submission: Session | null;
  fetchSubmissionsData: any;
};

const SubmissionsModal: FC<ActionsModalProps> = ({
  isOpen,
  onClose,
  title,
  submission,
  fetchSubmissionsData,
}) => {
  const dispatch = useAppDispatch();

  const onDeleteSession = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.delete(
        `/session/delete/${submission?.sessionId}`,
      );
      if (res.success) {
        await fetchSubmissionsData();
      }
      onClose();
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const onChangeSessionStatus = async (state: number) => {
    try {
      dispatch(showLoader());
      const res: any = await http.put("/session/change-state", {
        sessionId: submission?.sessionId,
        state: state,
      });
      if (res.success) {
        await fetchSubmissionsData();
      }
      onClose();
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const downloadImages = async () => {
    const zip = new JSZip();
    const imgFormat = submission?.submissions[0].url.split(".").pop();

    if (submission?.submissions) {
      for await (const image of submission.submissions) {
        const response = await fetch(image.url);
        const blob = await response.blob();

        zip.file(
          `image-${submission.submissions.indexOf(image)}.${imgFormat}`,
          blob,
        );
      }

      const content = await zip.generateAsync({ type: "blob" });
      const url = window.URL.createObjectURL(content);

      const link = document.createElement("a");
      link.href = url;
      link.download = "images.zip";
      link.click();

      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.header,
            "flex a-items-c justify-space-between",
          )}
        >
          <div className="light-text">{title}</div>
          <div onClick={onClose} className="cursor-pointer">
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className={classNames(styles.horizontalLine, "mt-4")} />
        <div className="mt-4 flex full-width gap40 flex-start padding-h8">
          <div className="text-white flex flex-column gap8">
            <p className="font10 gray2-text">First name</p>
            <p
              className="font14  text-nowrap text-ellipsis overflow-hidden"
              style={{ maxWidth: 185 }}
            >
              {submission?.contact.firstName
                ? submission.contact?.firstName
                : "Not indicated"}
            </p>
          </div>
          <div className="text-white flex flex-column gap8">
            <p className="font10 gray2-text">Last name</p>
            <p
              className="font14 text-nowrap text-ellipsis overflow-hidden"
              style={{ maxWidth: 185 }}
            >
              {submission?.contact.lastName
                ? submission.contact?.lastName
                : "Not indicated"}
            </p>
          </div>
          <div className="text-white flex flex-column gap8">
            <p className="font10 gray2-text">Email address</p>
            <p
              className="font14 text-nowrap text-ellipsis overflow-hidden"
              style={{ maxWidth: 185 }}
            >
              {submission?.contact.email
                ? submission.contact?.email
                : "Not indicated"}
            </p>
          </div>
          <div className="text-white flex flex-column gap8">
            <p className="font10 gray2-text">Phone number</p>
            <p className="font14">
              {submission?.contact.phone
                ? submission.contact?.phone
                : "Not indicated"}
            </p>
          </div>
        </div>
        <div className={classNames(styles.horizontalLine, "mt-4")} />
        <div>
          <div className="flex mt16 flex-end gap8">
            <a
              className="small-btn"
              target="_blank"
              href={submission?.sessionUrl}
              rel="noreferrer"
            >
              <img src={ViewOrigin} alt="" />{" "}
              <p className="gray3-text font12">View original</p>
            </a>
            <div className="small-btn" onClick={downloadImages}>
              <img src={ExportIcon} alt="" />{" "}
              <p className="gray3-text font12">Download</p>
            </div>
          </div>
        </div>
        <div className="flex mt16 gap12">
          <div className={styles.imageContainer}>
            <p className={"font11 gray5-text"}>Default</p>
            <a
              className={classNames(
                styles.imageInnerContainer,
                "cursor-pointer",
              )}
              target="_blank"
              href={submission?.sessionUrl}
              rel="noreferrer"
            >
              <img src={submission?.sessionUrl} alt="" />
            </a>
          </div>
          {submission?.state === submissionStatus.accepted.id && (
            <div className={styles.imageContainer}>
              <p className={"font11 gray5-text"}>Accepted</p>
              <div className={"flex gap12"}>
                {submission?.submissions.map((item) => (
                  <a
                    className={classNames(
                      styles.imageInnerContainer,
                      "cursor-pointer",
                    )}
                    key={item.id}
                    target="_blank"
                    href={item.url}
                    rel="noreferrer"
                  >
                    <img src={item.url} alt="" />
                  </a>
                ))}
              </div>
            </div>
          )}
          {submission?.state === submissionStatus.rejected.id && (
            <div className={styles.imageContainer}>
              <p className={"font11 gray5-text"}>Rejected</p>
              <div className={"flex gap12"}>
                {submission?.submissions.map((item) => (
                  <div className={styles.imageInnerContainer} key={item.id}>
                    <img src={item.url} alt="" />
                  </div>
                ))}
              </div>
            </div>
          )}
          {submission?.state === submissionStatus.review.id && (
            <div className={styles.imageContainer}>
              <p className={"font11 gray5-text"}>Review</p>
              <div className={"flex gap12"}>
                {submission?.submissions.map((item) => (
                  <div className={styles.imageInnerContainer} key={item.id}>
                    <img src={item.url} alt="" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex gap8 mt-4 justify-space-between">
          <WhiteButton
            text="Cancel"
            onClick={onClose}
            customStyles={"black-btn custom-studios-btn"}
          />
          <div className="flex gap12">
            <WhiteButton
              text="Delete"
              onClick={onDeleteSession}
              customStyles={"black-btn custom-studios-btn text-orange"}
              icon={TrashIcon}
            />
            {submission?.state === submissionStatus.accepted.id && (
              <WhiteButton
                text="Reject"
                onClick={() => onChangeSessionStatus(3)}
                icon={CancelIcon}
                customStyles={"orange-btn custom-studios-btn"}
              />
            )}
            {submission?.state === submissionStatus.rejected.id && (
              <WhiteButton
                text="Accept"
                onClick={() => onChangeSessionStatus(2)}
                icon={TickIcon}
                customStyles={"green-btn custom-studios-btn"}
              />
            )}
            {submission?.state === submissionStatus.review.id && (
              <>
                <WhiteButton
                  text="Accept"
                  onClick={() => onChangeSessionStatus(2)}
                  icon={TickIcon}
                  customStyles={"green-btn custom-studios-btn"}
                />
                <WhiteButton
                  text="Reject"
                  onClick={() => onChangeSessionStatus(3)}
                  icon={CancelIcon}
                  customStyles={"orange-btn custom-studios-btn"}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubmissionsModal;
