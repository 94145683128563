import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
} from "redux-persist";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import studiosReducer, { clearStudiosList } from "./slices/studios";
import tabsReducer, { clearTabsState } from "./slices/tabs";
import loaderReducer, { clearLoaderState } from "./slices/loader";
import userReducer, { clearUserState } from "./slices/user";
import contactsReducer, { clearContactsList } from "./slices/contacts";
import outputsReducer, { clearOutputs } from "./slices/outputs";
import galleryReducer, { clearGalleryState } from "./slices/gallery";
import submissionsReducer, {
  clearSubmissionsLists,
} from "./slices/submissions";
import {
  clearRefreshToken,
  clearToken,
  clearUserEmail,
  clearUserRole,
} from "../helpers/localStorageHelpers";

const studiosConfig = {
  key: "studios",
  storage,
};

const contactsConfig = {
  key: "contacts",
  storage,
};

const tabsConfig = {
  key: "tabs",
  storage,
};

const loaderConfig = {
  key: "loader",
  storage,
};

const userConfig = {
  key: "user",
  storage,
};

const outputsConfig = {
  key: "outputs",
  storage,
};

const submissionsConfig = {
  key: "submissions",
  storage,
};

const galleryConfig = {
  key: "gallery",
  storage,
};

const persistedStudiosReducer = persistReducer(studiosConfig, studiosReducer);
const persistedTabsReducer = persistReducer(tabsConfig, tabsReducer);
const persistedLoaderReducer = persistReducer(loaderConfig, loaderReducer);
const persistedUserReducer = persistReducer(userConfig, userReducer);
const persistedOutputsReducer = persistReducer(outputsConfig, outputsReducer);
const persistedGalleryReducer = persistReducer(galleryConfig, galleryReducer);
const persistedSubmissionsReducer = persistReducer(
  submissionsConfig,
  submissionsReducer,
);
const persistedContactsReducer = persistReducer(
  contactsConfig,
  contactsReducer,
);

const rootReducer = combineReducers({
  studios: persistedStudiosReducer,
  tabs: persistedTabsReducer,
  loader: persistedLoaderReducer,
  user: persistedUserReducer,
  contacts: persistedContactsReducer,
  outputs: persistedOutputsReducer,
  submissions: persistedSubmissionsReducer,
  gallery: persistedGalleryReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const clearPersistor = async () => {
  persistor.purge();
  store.dispatch(clearStudiosList());
  store.dispatch(clearTabsState());
  store.dispatch(clearContactsList());
  store.dispatch(clearUserState());
  store.dispatch(clearOutputs());
  store.dispatch(clearSubmissionsLists());
  store.dispatch(clearGalleryState());
  store.dispatch(clearLoaderState());
  clearToken();
  clearUserRole();
  clearUserEmail();
  clearRefreshToken();
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
