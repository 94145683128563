export const submissionStatus = {
  review: {
    id: 1,
    name: "Review",
  },
  accepted: {
    id: 2,
    name: "Accepted",
  },
  rejected: {
    id: 3,
    name: "Rejected",
  },
};
