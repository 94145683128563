import React, { FC, useState } from "react";
import styles from "../../ManageStudio.module.scss";
import GlobalIcon from "assets/svg/global-icon.svg";
import { useAppSelector } from "store";
import { PoseProfile } from "store/slices/studios";
import CheckIcon from "assets/svg/check-circle.svg";
import classNames from "classnames";
import SelectedRadioBtn from "assets/svg/chosed-radio-icon.svg";
import DefaultRadioBtn from "assets/svg/default-radio-icon.svg";
import IOSSwitch from "../../../IOSswitcher/IOSswitcher";
import UploadIcon from "assets/svg/upload-icon.svg";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { HexColorPicker } from "react-colorful";
import DeleteIcon from "assets/svg/delete-icon.svg";

import "./styles.scss";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";

type PhotoConfigurationProps = {
  setSelectedPose: (val: PoseProfile) => void;
  selectedPose: PoseProfile | null;
  userGuidelines: string;
  selectedBackgroundOption: number;
  setSelectedBackgroundOption: (val: number) => void;
  isAllowPhotoUpload: boolean;
  setIsAllowPhotoUpload: (val: boolean) => void;
  backgroundImages: any[];
  setBackgroundImages: (image: string) => void;
  onSetBackgroundColor: (color: string) => void;
  backgroundColors: any[];
  onDeleteColor: (color: string) => void;
  onDeleteImage: (image: string) => void;
  toggleNotificationModal: (val: boolean) => void;
  isNotificationModalOpen: boolean;
  setNotificationText: (val: string) => void;
  notificationText: string;
};

const poses = [
  {
    id: 1,
    name: "No Outline",
    url: "https://www.dropbox.com:443/scl/fi/n12c5ubf9jgmjqomjlath/no_outline.png?rlkey=ykqgv52e2r0wadw2sqejbrb6c&dl=0&raw=1",
    studioPoseProfileExamples: [
      {
        id: 1,
        url: "https://www.dropbox.com:443/scl/fi/3m0aevx8486ozufzkp6vv/no_outline_example1.jpg?rlkey=8og4j0xwqaqtdr4sj781qh8cb&dl=0&raw=1",
      },
      {
        id: 2,
        url: "https://www.dropbox.com:443/scl/fi/yj6ew9xbj0pg2f9jitmtr/no_outline_example2.jpg?rlkey=r30jgmzbnenwhxlmgfdalh705&dl=0&raw=1",
      },
      {
        id: 3,
        url: "https://www.dropbox.com:443/scl/fi/h9h8xa8ovxsgtbtgodlb7/no_outline_example3.jpg?rlkey=xgyzuy0bv48rouc848fcj9m6i&dl=0&raw=1",
      },
    ],
  },
  {
    id: 2,
    name: "Shoulder Height",
    url: "https://www.dropbox.com:443/scl/fi/qyd6wgd2p3dj6d7e0mmqb/shoulder_height.png?rlkey=5z0od1fjdq5vchut9pwfth3zv&dl=0&raw=1",
    studioPoseProfileExamples: [
      {
        id: 4,
        url: "https://www.dropbox.com:443/scl/fi/ewe2mg61ioda6fpuikkif/shoulder_height_example1.jpg?rlkey=b5ma8eo28nbol7x4r72o19ii6&dl=0&raw=1",
      },
      {
        id: 5,
        url: "https://www.dropbox.com:443/scl/fi/657pf4u9awsma8vsrhmjq/shoulder_height_example2.jpg?rlkey=zjr4l0k3hpt4oxj5qsyyzgguj&dl=0&raw=1",
      },
      {
        id: 6,
        url: "https://www.dropbox.com:443/scl/fi/a2kslsnw4aeq9h0rpfr41/shoulder_height_example3.jpg?rlkey=2k8z2u90kkwik58fq1ljwz7s5&dl=0&raw=1",
      },
    ],
  },
  {
    id: 3,
    name: "Shoulder Height Close",
    url: "https://www.dropbox.com:443/scl/fi/ets70jlvppx5mxhb1sr24/shoulder_height_close.png?rlkey=e4v4amwmf7murz7knft6hboqj&dl=0&raw=1",
    studioPoseProfileExamples: [
      {
        id: 7,
        url: "https://www.dropbox.com:443/scl/fi/vw6wf0alaqh6k6a4o8zi0/shoulder_height_close_example1.jpg?rlkey=eg7zb1nu6c4aq4to7t9u1m431&dl=0&raw=1",
      },
      {
        id: 8,
        url: "https://www.dropbox.com:443/scl/fi/e6g1e3op9cigs6wt3mwwu/shoulder_height_close_example2.jpg?rlkey=7n0kxpc0osk2t9eh4qy70or6z&dl=0&raw=1",
      },
      {
        id: 9,
        url: "https://www.dropbox.com:443/scl/fi/74i5eexvv0ptxkf3tf2f0/shoulder_height_close_example3.jpg?rlkey=9c1t6cp3rup42v9gxwstr2ksr&dl=0&raw=1",
      },
    ],
  },
  {
    id: 4,
    name: "Shoulder Height - Custom",
    url: "https://www.dropbox.com:443/scl/fi/u9d9rqbyy6fzqw5s1mh51/shoulder_height_custom.png?rlkey=imsn9xvpourdxyf0mkhyjgbhf&dl=0&raw=1",
    studioPoseProfileExamples: [
      {
        id: 10,
        url: "https://www.dropbox.com:443/scl/fi/yq8ng7etct63ea7loy6up/shoulder_height_custom_example1.jpg?rlkey=yd52equ8xvh3wbrbkgtvi6289&dl=0&raw=1",
      },
      {
        id: 11,
        url: "https://www.dropbox.com:443/scl/fi/m7eazsf6y9hslw0d4pj3q/shoulder_height_custom_example2.jpg?rlkey=ijc2ov5t7sgcbsyeohtlrmdvn&dl=0&raw=1",
      },
      {
        id: 12,
        url: "https://www.dropbox.com:443/scl/fi/26komenv98qwbb30uwias/shoulder_height_custom_example3.jpg?rlkey=wnzwpprt0tsr0lgtf8cj09lea&dl=0&raw=1",
      },
    ],
  },
  {
    id: 5,
    name: "Waist Height",
    url: "https://www.dropbox.com:443/scl/fi/fdc4f4qdss00m6yd4nvx7/waist_height.png?rlkey=ebv1b4f5689c70lgbg7miewwy&dl=0&raw=1",
    studioPoseProfileExamples: [
      {
        id: 13,
        url: "https://www.dropbox.com:443/scl/fi/4jgdwd3hqsuovoqzct8t4/waist_height_example1.jpg?rlkey=j6u6hnvs7fwigza9vddk14otv&dl=0&raw=1",
      },
      {
        id: 14,
        url: "https://www.dropbox.com:443/scl/fi/iqlfionekown9ldpy36h7/waist_height_example2.jpg?rlkey=rqgqk7zy30se1n7wl4tkzvp0x&dl=0&raw=1",
      },
      {
        id: 15,
        url: "https://www.dropbox.com:443/scl/fi/djiku8bshh2dyc18hisn2/waist_height_example3.jpg?rlkey=g9dxogn2sr0whtuwmhw1j7ems&dl=0&raw=1",
      },
    ],
  },
];

const PhotoConfiguration: FC<PhotoConfigurationProps> = ({
  selectedPose,
  setSelectedPose,
  userGuidelines,
  selectedBackgroundOption,
  setSelectedBackgroundOption,
  isAllowPhotoUpload,
  setIsAllowPhotoUpload,
  setBackgroundImages,
  backgroundImages,
  onSetBackgroundColor,
  backgroundColors,
  onDeleteColor,
  onDeleteImage,
  toggleNotificationModal,
  isNotificationModalOpen,
  setNotificationText,
  notificationText,
}) => {
  const { studioBackgroundOptions } = useAppSelector((state) => state.studios);

  const [anchorSelectType, setAnchorSelectType] = useState<null | HTMLElement>(
    null,
  );

  const [color, setColor] = useState("#aabbcc");

  const [isShowColorPicker, showColorPicker] = useState(false);
  // const [value, setValue] = useState("#ffffff");
  //
  // const handleChange = (newValue: string) => {
  //   setValue(newValue);
  // };
  const handleContextMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!isShowColorPicker) setAnchorSelectType(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setAnchorSelectType(null);
  };

  const onSelectColor = () => {
    onSetBackgroundColor(color);
    showColorPicker(false);
  };

  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        if (base64String) {
          const img = new Image();
          img.src = base64String as string;
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            if (width <= 2000 && height <= 2000) {
              setBackgroundImages(base64String as string);
            } else {
              toggleNotificationModal(true);
              setNotificationText(
                "The maximum expansion of the image is 2000 x 2000 pixels",
              );
              //error message
            }
          };
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    setAnchorSelectType(null);
  };

  return (
    <div className="mt40">
      <div className={styles.blockContainer}>
        <div className="flex flex-start gap8 a-items-c">
          <img src={GlobalIcon} alt="" />{" "}
          <p className="gold-text">Photo configuration</p>
        </div>
        <div className="mt-20">
          <p className="light-text font13">Profile</p>
          <p className="gray2-text font12">
            Choose a profile for contacts to use when posing for the photo.
          </p>
          <div className="flex justify-space-between mt10">
            {poses.map((pose) => (
              <div
                key={pose.id}
                style={{ width: "18%" }}
                onClick={() => {
                  setSelectedPose(pose);
                }}
              >
                <div className={classNames("bg-light-dark position-relative")}>
                  <img
                    src={
                      pose.id === 1
                        ? require("assets/images/frames/no_outline.png")
                        : pose.id === 2
                          ? require("assets/images/frames/shoulder_height.png")
                          : pose.id === 3
                            ? require("assets/images/frames/shoulder_height_close.png")
                            : pose.id === 4
                              ? require("assets/images/frames/shoulder_height_custom_examples.png")
                              : require("assets/images/frames/waist_height.png")
                    }
                    alt=""
                  />
                  {selectedPose && selectedPose.id === pose.id && (
                    <div className={"position-absolute top-1 left-1"}>
                      <img src={CheckIcon} alt="" />
                    </div>
                  )}
                </div>
                <p className="light-text t-align-center mt10 font13">
                  {pose.name}
                </p>
              </div>
            ))}
          </div>
          <div className="mt10">
            <p className="light-text font13">Example images</p>
            <div className="flex flex-start mt6 gap20" style={{ height: 151 }}>
              {/*{exampleImages.map((example) => (*/}
              {/*  <div style={{ width: "18%" }} key={example.id}>*/}
              {/*    <img src={example.url} alt="" />*/}
              {/*  </div>*/}
              {/*))}*/}
              {selectedPose?.id === 1 ? (
                <>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/NoOutline/no_outline_example1.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/NoOutline/no_outline_example2.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/NoOutline/no_outline_example3.jpg")}
                      alt=""
                    />
                  </div>
                </>
              ) : selectedPose?.id === 2 ? (
                <>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeight/shoulder_height_example1.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeight/shoulder_height_example2.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeight/shoulder_height_example3.jpg")}
                      alt=""
                    />
                  </div>
                </>
              ) : selectedPose?.id === 3 ? (
                <>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeightClose/shoulder_height_close_example1.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeightClose/shoulder_height_close_example2.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeightClose/shoulder_height_close_example3.jpg")}
                      alt=""
                    />
                  </div>
                </>
              ) : selectedPose?.id === 4 ? (
                <>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeightCustomExamples/shoulder_height_custom_examples_example1.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeightCustomExamples/shoulder_height_custom_examples_example2.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/ShoulderHeightCustomExamples/shoulder_height_custom_examples_example3.jpg")}
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/WaistHeight/waist_height_example1.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/WaistHeight/waist_height_example2.jpg")}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <img
                      src={require("assets/images/examples/WaistHeight/waist_height_example3.jpg")}
                      alt=""
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="mt-20">
            <p className="light-text font13">Guidelines</p>
            <p className="mt6 gray2-text font12">{userGuidelines}</p>
          </div>
          <div className="mt-20">
            <div className={"flex flex-start a-items-start gap8"}>
              <IOSSwitch
                sx={{ m: 1 }}
                onChange={() => setIsAllowPhotoUpload(!isAllowPhotoUpload)}
                checked={isAllowPhotoUpload}
              />
              <div>
                <p className="light-text font13">Allow photo upload</p>
                <p className="gray2-text font12 mt6">
                  Contacts can upload a photo from their device when enabled.
                  Disable this option to enforce new photos per submission.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-20">
            <p className="light-text font13">Background</p>
            <div className="mt6 flex flex-column gap8">
              {studioBackgroundOptions.map((option) => (
                <div
                  key={option.Id}
                  className=""
                  onClick={() => setSelectedBackgroundOption(option.Id)}
                >
                  <div className="flex flex-start a-items-start gap8 cursor-pointer">
                    <img
                      src={
                        selectedBackgroundOption === option.Id
                          ? SelectedRadioBtn
                          : DefaultRadioBtn
                      }
                      alt=""
                    />
                    <div>
                      <p className="light-text font13">{option.Name}</p>
                    </div>
                  </div>
                  {option.Id === 2 && selectedBackgroundOption === 2 && (
                    <div className="mt6 mb-10 flex gap20 flex-start flex-wrap">
                      {!!backgroundImages.length &&
                        backgroundImages.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: "rgba(30, 30, 30, 1)",
                              width: 150,
                              height: 150,
                              borderRadius: 8,
                              objectFit: "contain",
                              overflow: "hidden",
                            }}
                            className="flex a-items-c flex-column picker-image-container position-relative"
                          >
                            <img
                              src={
                                item.imageBase64 ? item.imageBase64 : item.url
                              }
                              alt=""
                              style={{ height: "inherit", width: "auto" }}
                            />
                            <div
                              className="delete-image-container position-absolute"
                              onClick={() =>
                                onDeleteImage(
                                  item.imageBase64
                                    ? item.imageBase64
                                    : item.url,
                                )
                              }
                            >
                              <img src={DeleteIcon} alt="" />
                            </div>
                          </div>
                        ))}
                      {!!backgroundColors.length &&
                        backgroundColors.map((color, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: `${color.hex}`,
                              width: 150,
                              height: 150,
                              borderRadius: 8,
                              objectFit: "contain",
                              overflow: "hidden",
                            }}
                            className="flex a-items-c flex-column picker-data-container"
                          >
                            <div
                              className="delete-container"
                              onClick={() => onDeleteColor(color.hex)}
                            >
                              <img src={DeleteIcon} alt="" />
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          backgroundColor: "rgba(30, 30, 30, 1)",
                          width: 150,
                          height: 150,
                          borderRadius: 8,
                        }}
                        className="flex a-items-c flex-column position-relative"
                        onClick={handleContextMenuOpen}
                      >
                        <img src={UploadIcon} alt="" />
                        {isShowColorPicker && (
                          <div className="position-absolute">
                            <HexColorPicker color={color} onChange={setColor} />
                            <WhiteButton
                              customStyles={
                                "mt6 full-width t-align-center flex"
                              }
                              text={`Select ${color}`}
                              onClick={onSelectColor}
                            />
                          </div>
                        )}
                      </div>
                      <Menu
                        id="menu-basic"
                        anchorEl={anchorSelectType}
                        open={Boolean(anchorSelectType)}
                        onClose={handleContextMenuClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                          sx: {
                            // Style the entire MenuList container
                            background: "rgba(30, 30, 30, 1)",
                            borderRadius: "16px",
                            borderWidth: 0,
                            color: "rgba(152, 152, 152, 1)",
                            fontSize: 12,
                            fontFamily: "MatterRegular",
                          },
                        }}
                        PaperProps={{ sx: { background: "inherit" } }}
                      >
                        <MenuItem>
                          <label
                            htmlFor={"formId"}
                            className="full-width flex justify-content-start i-align-c gap8"
                          >
                            <p>Upload image</p>
                          </label>
                          <input
                            id="formId"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            name="myImage"
                            style={{ display: "none" }}
                            onChange={handleUpload}
                          />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            showColorPicker(true);
                            setAnchorSelectType(null);
                          }}
                        >
                          Select color
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        onClose={() => toggleNotificationModal(false)}
        isOpen={isNotificationModalOpen}
        text={notificationText}
      />
    </div>
  );
};

export default PhotoConfiguration;
