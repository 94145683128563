import React, { useState } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  getPaginationRowModel,
  Row,
} from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "store";
import { Contact, setActiveContactDetailsId } from "store/slices/contacts";
import { contactsTabName } from "constants/tabs";
import { setActiveContactsActiveTab } from "store/slices/tabs";
import "./styles.scss";

const columnHelper = createColumnHelper<Contact>();

const columns = [
  columnHelper.accessor("firstName", {
    cell: (info) => info.getValue(),
    header: () => "First Name",
    size: 200,
  }),
  columnHelper.accessor("lastName", {
    cell: (info) => info.getValue(),
    header: () => "Last Name",
    size: 200,
  }),
  columnHelper.accessor("email", {
    cell: (info) => info.getValue(),
    header: () => "Email",
    size: 150,
  }),
  columnHelper.accessor("submissionsCount", {
    header: () => "Submissions",
    cell: (info) => info.renderValue(),
    size: 120,
  }),
  columnHelper.accessor("createdAt", {
    header: () => "Created at",
    cell: (info) => info.renderValue(),
    size: 150,
  }),
];
const DataGrid = () => {
  const dispatch = useAppDispatch();
  const { contactsActiveListId } = useAppSelector((state) => state.tabs);
  const { contactsLists, allContacts } = useAppSelector(
    (state) => state.contacts,
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: contactsLists[contactsActiveListId].contacts || allContacts,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onOpenContactDetails = (row: Row<Contact>) => {
    dispatch(setActiveContactDetailsId(row.original.id));
    dispatch(setActiveContactsActiveTab(contactsTabName.contact));
  };

  return (
    <div className="flex flex-col pt-4 pb-6 full-width padding24">
      <table className={"full-width"} style={{ tableLayout: "fixed" }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="table-border-b table-header">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="py-4 font-medium t-align-left"
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none flex min-w-[36px] flex-start position-relative"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <span className="pl-2">↑</span>,
                        desc: <span className="pl-2">↓</span>,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {table.getRowModel()?.rows?.length > 0 ? (
          <tbody style={{ width: "100%" }}>
            {table.getRowModel()?.rows?.map((row) => (
              <tr
                key={row.id}
                className="table-border-b custom-table-row"
                onClick={() => onOpenContactDetails(row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="pt-[14px] pb-[18px] table-item">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={columns.length} className="text-center py-4">
                <p className="text-white font-s20">No contacts found.</p>
              </td>
            </tr>
          </tbody>
        )}
      </table>

      <div
        className="sm:flex-row flex-col w-full mt-8 items-center text-xs gap24 justify-end"
        style={{ display: "flex" }}
      >
        <div className="sm:mb-0 mb-2">
          <span className="mr-2 text-white">Rows per page:</span>
          <select
            className="p-1 w-16 table-selector-bg"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="flex gap-2">
          <button
            className={`${
              !table.getCanPreviousPage()
                ? "bg-gray-100 next-btn-disable"
                : "hover:bg-gray-200 hover:curstor-pointer bg-gray-100 next-btn-active"
            } rounded p-1`}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="w-5 h-5 text-white">{"<"}</span>
          </button>
          {/*<span className="flex items-center gap-1">*/}
          {/*  <input*/}
          {/*    min={1}*/}
          {/*    max={table.getPageCount()}*/}
          {/*    type="number"*/}
          {/*    value={table.getState().pagination.pageIndex + 1}*/}
          {/*    onChange={(e) => {*/}
          {/*      const page = e.target.value ? Number(e.target.value) - 1 : 0;*/}
          {/*      table.setPageIndex(page);*/}
          {/*    }}*/}
          {/*    className="border p-1 rounded w-10 text-white"*/}
          {/*  />*/}
          {/*  {table.getPageCount()}*/}
          {/*</span>*/}
          <button
            className={`${
              !table.getCanNextPage()
                ? "bg-gray-100 next-btn-disable"
                : "hover:bg-gray-200 hover:curstor-pointer bg-gray-100 next-btn-active"
            } rounded p-1`}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="w-5 h-5 text-white">{">"}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataGrid;
