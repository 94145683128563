import React, { FC } from "react";
import styles from "./ImageCard.module.scss";
import ImportIcon from "assets/svg/import.svg";
import classNames from "classnames";

type ImageCardProps = {
  imgUrl: string;
};

const ImageCard: FC<ImageCardProps> = ({ imgUrl }) => {
  const imageName = imgUrl.split("/");
  const lastItem = imageName[imageName.length - 1];

  const downloadImage = async () => {
    try {
      const response = await fetch(imgUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = lastItem; // Adjust the filename as needed
      link.click();
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <div className={classNames(styles.container, "flex flex-column gap8")}>
      <a
        className={classNames(styles.imgContainer, "cursor-pointer")}
        target="_blank"
        href={imgUrl}
        rel="noreferrer"
      >
        <img src={imgUrl} alt="" style={{ borderRadius: "3.4px" }} />
      </a>
      <div className="flex a-items-c justify-space-between gap16">
        <p
          className={classNames(
            styles.nameText,
            "text-nowrap text-ellipsis overflow-hidden",
          )}
        >
          {lastItem}
        </p>
        <div
          className={classNames(styles.iconContainer, "cursor-pointer")}
          onClick={downloadImage}
        >
          <img src={ImportIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
