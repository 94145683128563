import React, { FC } from "react";
import Modal from "react-modal";
import styles from "./InvitedActionsModal.module.scss";
import CloseIcon from "assets/svg/close-icon.svg";
import classNames from "classnames";
import WhiteButton from "../../buttons/WhiteButton/WhiteButton";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(26, 26, 26, 1)",
    boxShadow: "0 24px 45.20000076293945px 0 rgba(0, 0, 0, 0.25)",
    borderRadius: 24,
    padding: 24,
  },
};

type InvitedActionsModalProps = {
  isOpen: boolean;
  onClose?: () => void | undefined;
  title: string;
  remindAction: () => void;
  revokeAction: () => void;
};

const InvitedActionsModal: FC<InvitedActionsModalProps> = ({
  isOpen,
  onClose,
  title,
  remindAction,
  revokeAction,
}) => {
  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.header,
            "flex a-items-c justify-space-between",
          )}
        >
          <div className="light-text">{title}</div>
          <div onClick={onClose} className="cursor-pointer">
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className={classNames(styles.horizontalLine, "mt-4")} />
        <div className="flex gap8 mt-4" style={{ justifyContent: "end" }}>
          <WhiteButton
            text="Revoke invite"
            onClick={revokeAction}
            customStyles={"black-btn custom-studios-btn"}
          />

          <WhiteButton
            text={"Send reminder"}
            customStyles={classNames("custom-studios-btn")}
            onClick={remindAction}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InvitedActionsModal;
