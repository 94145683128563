import React, { useState } from "react";
import http from "helpers/http";
import { useNavigate } from "react-router-dom";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import styles from "./ResetPassword.module.scss";
import classNames from "classnames";
import PasswordInput from "components/fields/PasswordInput/PasswordInput";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";
import { isValidatePassword } from "utils/validatiors";
const ResetPassword = () => {
  const navigate = useNavigate();
  const replaceSpacesWithPlus = (url: string) => {
    return url.replace(/\s/g, "+");
  };

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, toggleModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = async () => {
    if (isValidatePassword(newPassword)) {
      setIsLoading(true);
      try {
        let tokenData = "";
        if (token) tokenData = replaceSpacesWithPlus(token);
        const res: any = await http.post("/user/reset-password", {
          email,
          token: tokenData,
          newPassword,
        });
        if (res?.success) {
          setIsLoading(false);
          navigate("/login");
        }
      } catch (e: any) {
        setModalMessage(
          "An error occurred during the reset password. Try again later.",
        );
        setIsLoading(false);
        toggleModal(true);
      }
    } else {
      setModalMessage(
        "Passwords must be at least 6 characters.\nPasswords must have at least one non alphanumeric character.\nPasswords must have at least one digit ('0'-'9').\nPasswords must have at least one uppercase ('A'-'Z').",
      );
      toggleModal(true);
    }
  };

  const closeNotificationModal = () => {
    toggleModal(false);
  };

  const onChangePassword = (val: string) => {
    if (modalMessage) {
      setModalMessage("");
    }
    setNewPassword(val);
  };

  return (
    <div className="full-width full-height bg-dark flex a-items-c padding24">
      <div
        className="border-light border-radius32 flex flex-column a-items-c flex-start full-width full-height"
        style={{
          backgroundColor: "rgba(26, 26, 26, 1)",
          borderWidth: 1.5,
          paddingTop: "20vh",
        }}
      >
        <div
          className={classNames("flex flex-column a-items-c", styles.container)}
        >
          <h2>Reset your password</h2>
          <div
            style={{ height: 44, marginTop: "5vh" }}
            className={"full-width"}
          >
            <PasswordInput
              label="Password"
              placeholder="***************"
              onChange={onChangePassword}
              inputVal={newPassword}
            />
            <WhiteButton
              text="Reset"
              customStyles="full-width t-align-center border-radius16 flex a-items-c mt40"
              onClick={resetPassword}
              isDisabled={!newPassword || !!modalMessage || isLoading}
            />
          </div>
        </div>
      </div>
      <NotificationModal
        onClose={closeNotificationModal}
        isOpen={isModalOpen}
        text={modalMessage}
      />
    </div>
  );
};

export default ResetPassword;
