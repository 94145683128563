export const copyToClipboard = (text: string) => {
  // Create a temporary textarea element
  console.log("text", text);
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);

  // Select and copy the text
  textarea.select();
  document.execCommand("copy");

  // Remove the temporary textarea element
  document.body.removeChild(textarea);
};
