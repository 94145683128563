import axios, { AxiosRequestConfig } from "axios";
import {
  clearToken,
  getRefreshToken,
  getToken,
  getUserEmail,
  setRefreshToken,
  setToken,
} from "./localStorageHelpers";
import { clearPersistor } from "../store";
export interface IRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
  _disableErrorHandler?: boolean;
}

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  "Access-Control-Allow-Headers":
    "x-requested-with,Content-Type,origin,authorization,accept,client-sent-security-token",
};

export const apiInstance = axios.create({
  baseURL: "https://studiopodapi.com/api", //prod
  // baseURL: "https://vitnif-001-site8.ftempurl.com/api", //dev
  headers,
});

export const fetchRefreshToken = async () => {
  try {
    const localRefreshToken = getRefreshToken();
    const userEmail = getUserEmail();

    const res = await apiInstance.post("/auth/refresh-token", {
      refreshToken: localRefreshToken,
      email: userEmail,
    });
    const { accessToken, refreshToken } = res.data.data;
    if (accessToken) {
      setToken(accessToken);
    }
    if (refreshToken) {
      setRefreshToken(refreshToken);
    }
    return { accessToken, refreshToken };
  } catch (error) {
    clearToken();
    clearPersistor();
    window.location.href = "/login";
    console.error("Error refreshing token:", error);
    throw error;
  }
};

apiInstance.interceptors.request.use(
  (config) => {
    const auth = getToken();
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${auth}`;
    return config;
  },
  (error) => Promise.reject(error),
);

let refreshingFunc: any = undefined;

apiInstance.interceptors.response.use(
  async (response) => response,
  async (err) => {
    const originalConfig: IRequestConfig = err.config;
    const refreshToken = getRefreshToken();
    if (err.response.status === 401 && refreshToken) {
      try {
        if (!refreshingFunc) refreshingFunc = fetchRefreshToken();
        await refreshingFunc;
        return apiInstance(originalConfig);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          console.log(error?.response?.data || error?.message);
        }
        return Promise.reject(err);
      } finally {
        refreshingFunc = undefined;
      }
    }
    return Promise.reject(err);
  },
);

// eslint-disable-next-line no-async-promise-executor
const makeHttpRequest = (apiCall: any) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const response = await apiCall();
      resolve(response.data);
    } catch (e: any) {
      reject(e.response?.data);
    }
  });

export const http = {
  get: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.get(url, options)),
  post: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.post(url, data, options)),
  put: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.put(url, data, options)),
  patch: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.patch(url, data, options)),
  delete: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.delete(url, options)),
};

export default http;
