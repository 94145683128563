import React, { useEffect, useState } from "react";
import styles from "./ManageStudio.module.scss";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import MenuItem from "@mui/material/MenuItem";
import ArrowDownIcon from "assets/svg/arrow-down.svg";
import GlobalIcon from "assets/svg/global-icon.svg";
import SelectedRadioBtn from "assets/svg/chosed-radio-icon.svg";
import DefaultRadioBtn from "assets/svg/default-radio-icon.svg";
import classNames from "classnames";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import { useAppDispatch, useAppSelector } from "store";
import { setActiveStudiosTab } from "store/slices/tabs";
import { studiosTabName } from "constants/tabs";
import "react-datepicker/dist/react-datepicker.css";
import IOSSwitch from "../IOSswitcher/IOSswitcher";
import BreadcrumbsComponent from "components/BreadcrumbsComponent/BreadcrumbsComponent";
import moment from "moment";
import http from "helpers/http";
import { hiddenLoader, showLoader } from "store/slices/loader";
import PhotoConfiguration from "./components/PhotoConfiguration/PhotoConfiguration";
import { PoseProfile } from "store/slices/studios";
import Menu from "@mui/material/Menu";
import WhiteGlobalIcon from "assets/svg/global.svg";

// export type BackgroundImage = {
//   id: 0;
//   url: "string";
// };

const ManageStudio = () => {
  const dispatch = useAppDispatch();

  const { activeStudioDetailsId, studioReminderPeriods } = useAppSelector(
    (state) => state.studios,
  );

  const [studioName, setStudioName] = useState("");
  const [selectedPhotoReview, setSelectedPhotoReview] = useState(false);
  const [isShowGalleryChecked, toggleShowGallery] = useState(false);
  const [isSuggestMobileDeviceChecked, toggleSuggestMobileDevice] =
    useState(false);
  const [isSendAutomaticRemindersChecked, toggleSendAutomaticReminders] =
    useState(false);
  const [remindNumber, setRemindNumber] = useState("0");
  const [studioType, setStudioType] = useState("Public");
  const [studioStartDate, setStudioStartDate] = useState(new Date());
  const [studioCredits, setStudioCredits] = useState({
    creditsPurchased: 0,
    creditsUsed: 0,
  });
  const [userGuidelines, setUserGuidelines] = useState("");
  const [selectedBackgroundOption, setSelectedBackgroundOption] = useState(1);
  const [selectedPose, setSelectedPose] = useState<PoseProfile | null>(null);
  const [isAllowPhotoUpload, setIsAllowPhotoUpload] = useState(false);
  const [reminderPeriod, setReminderPeriod] = useState(1);
  const [backgroundImages, setBackgroundImages] = useState<any[]>([]);
  const [backgroundColors, setBackgroundColors] = useState<any[]>([]);
  const [isNotificationModalOpen, toggleNotificationModal] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorPeriodEl, setAnchorPeriodEl] = useState<null | HTMLElement>(
    null,
  );
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePeriodMenuClose = () => {
    setAnchorPeriodEl(null);
  };

  const fetchStudioDetails = async () => {
    dispatch(showLoader());
    try {
      const res: any = await http.get(
        `/studio/getDetails/${activeStudioDetailsId}`,
      );
      const {
        name,
        creditsPurchased,
        creditsUsed,
        photoConfiguration,
        photoReviewAndAccess,
        inviteReminder,
      } = res.data;

      const resStudioType = res.data.studioType === 2 ? "Public" : "Private";
      setStudioName(name);
      setStudioType(resStudioType);
      setStudioStartDate(res.data.startDate);
      setStudioCredits({ creditsPurchased, creditsUsed });
      setSelectedPose(photoConfiguration.poseProfile);
      setUserGuidelines(photoConfiguration.guidelines);
      setSelectedBackgroundOption(photoConfiguration.backgroundOption);
      setIsAllowPhotoUpload(photoConfiguration.isAllowPhotoUpload);
      setSelectedPhotoReview(photoReviewAndAccess.isAutoAcceptSubmissions);
      toggleShowGallery(photoReviewAndAccess.isShowGallery);
      toggleSuggestMobileDevice(photoReviewAndAccess.isSuggestMobileDevice);
      setRemindNumber(inviteReminder.reminderInterval);
      toggleSendAutomaticReminders(inviteReminder.isSendAutomaticReminders);
      setReminderPeriod(inviteReminder.reminderPeriod);
      setBackgroundImages(photoConfiguration.backgroundImages);
      setBackgroundColors(photoConfiguration.backgroundColors);
      dispatch(hiddenLoader());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchStudioDetails().catch();
  }, []);

  const onChangeRemindNumber = (val: string) => {
    if (+val > 31) {
      setRemindNumber("31");
    } else if (+val < 0) {
      setRemindNumber("1");
    } else {
      setRemindNumber(val);
    }
  };
  const onChangeStudioName = (val: string) => {
    setStudioName(val);
  };

  const handlePeriodMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPeriodEl(event.currentTarget);
  };
  const onToggleShowGallery = () => {
    toggleShowGallery((prev) => !prev);
  };

  const onToggleSendAutomaticReminders = () => {
    toggleSendAutomaticReminders((prev) => !prev);
  };

  const onToggleSuggestMobileDevice = () => {
    toggleSuggestMobileDevice((prev) => !prev);
  };

  const onUpdateStudio = async () => {
    if (
      selectedBackgroundOption === 2 &&
      !backgroundColors.length &&
      !backgroundImages.length
    ) {
      setNotificationText("You must choose at least one background");
      toggleNotificationModal(true);
    } else if (studioName) {
      try {
        dispatch(showLoader());
        await http.put("/studio/update", {
          id: activeStudioDetailsId,
          name: studioName,
          startDate: studioStartDate,
          studioType: studioType === "Public" ? 2 : 1,
          photoReviewAndAccess: {
            isAutoAcceptSubmissions: selectedPhotoReview,
            isShowGallery: isShowGalleryChecked,
            isSuggestMobileDevice: isSuggestMobileDeviceChecked,
          },
          inviteReminder: {
            reminderPeriod: reminderPeriod,
            reminderInterval: +remindNumber !== 0 ? +remindNumber : 1,
            isSendAutomaticReminders: isSendAutomaticRemindersChecked,
          },
          photoConfiguration: {
            name: "default",
            isCustomProfile: true,
            isAllowPhotoUpload: isAllowPhotoUpload,
            guidelines: userGuidelines,
            backgroundOption: selectedBackgroundOption,
            poseProfile: selectedPose,
            backgroundColors:
              selectedBackgroundOption === 1 || selectedBackgroundOption === 3
                ? []
                : backgroundColors,
            backgroundImages:
              selectedBackgroundOption === 1 || selectedBackgroundOption === 3
                ? []
                : backgroundImages,
          },
          disclaimerContent: {
            isShowDisclaimerMessage: true,
            message: "string",
          },
          formFields: {
            firstName: "First name",
            firstNameOrder: 1,
            lastName: "Last name",
            lastNameOrder: 2,
            email: "Email",
            emailOrder: 3,
            phone: "Phone",
            phoneOrder: 4,
            isRequiredPhone: false,
            isShowPhone: true,
          },
          siteBranding: {
            backgroundColor: "#141414",
            backgroundImage: {
              id: 0,
              imageBase64: null,
              url: null,
            },
            logoImage: {
              id: 0,
              imageBase64: null,
              url: null,
            },
            primaryColor: "#F1F1F1",
            secondaryColor: "#BABABA",
            filledButtonTextColor: "#3E3E3E",
          },
        });
        dispatch(hiddenLoader());
        dispatch(setActiveStudiosTab(studiosTabName.list));
      } catch (e) {
        dispatch(hiddenLoader());
        console.log(e);
      }
    }
  };

  const onSetBackgroundImage = (image: string) => {
    setBackgroundImages([...backgroundImages, { imageBase64: image }]);
  };

  const onSetBackgroundColor = (color: string) => {
    setBackgroundColors([...backgroundColors, { hex: color }]);
  };

  const onDeleteColor = (color: string) => {
    const newArray = backgroundColors.filter((item) => item.hex !== color);
    setBackgroundColors(newArray);
  };

  const onDeleteImage = (image: string) => {
    const newArray = backgroundImages.filter((item) =>
      item.imageBase64 ? item.imageBase64 !== image : item.url !== image,
    );
    setBackgroundImages(newArray);
  };

  const breadcrumbs = [
    <div
      key="1"
      onClick={() => dispatch(setActiveStudiosTab(studiosTabName.list))}
      className="text-white cursor-pointer breadcrumbs-element"
    >
      Studios
    </div>,
    <div key="2" className="text-white cursor-pointer breadcrumbs-element">
      Studio Name
    </div>,
  ];

  return (
    <>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
      <div className="flex justify-space-between a-items-c">
        <h2>Edit studio</h2>
        <div className="flex gap8">
          <WhiteButton
            text="Cancel"
            onClick={() => dispatch(setActiveStudiosTab(studiosTabName.list))}
            customStyles={"black-btn custom-studios-btn"}
          />
          <WhiteButton
            text="Save edits"
            customStyles={"custom-studios-btn"}
            onClick={onUpdateStudio}
            isDisabled={!studioName}
          />
        </div>
      </div>
      <div className="mt40">
        <div className={styles.studioGrayContainer}>
          <div className="flex justify-space-around gap-32">
            <div className="flex a-items-c gap8 flex-start full-width">
              <p className="light-text t-align-left text-nowrap">
                Studio Name:
              </p>
              <div style={{ width: "100%" }}>
                <DefaultInput
                  onChange={onChangeStudioName}
                  inputVal={studioName}
                  maxLength={100}
                  placeholder={"Enter Studio Name"}
                />
              </div>
            </div>
            <div className={"flex a-items-c flex-start gap8"}>
              <p className="light-text t-align-left text-nowrap">
                Studios Type:
              </p>
              <div
                onClick={handleMenuOpen}
                className={"flex a-items-c cursor-pointer"}
                style={{
                  background: "rgba(26, 26, 26, 1)",
                  height: 40,
                  paddingLeft: 8,
                  paddingRight: 8,
                  borderRadius: 8,
                }}
              >
                <img src={WhiteGlobalIcon} alt="" width={24} height={24} />
                <span
                  style={{
                    width: 100,
                    marginLeft: 10,
                    color: "rgba(152, 152, 152, 1)",
                    fontFamily: "MatterRegular",
                  }}
                >
                  {studioType}
                </span>
                <img src={ArrowDownIcon} alt="" width={24} height={24} />
              </div>
              <Menu
                id="menu-basic"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: {
                    background: "rgba(30, 30, 30, 1)",
                    borderRadius: "16px",
                    borderWidth: 0,
                    color: "rgba(152, 152, 152, 1)",
                    fontSize: 12,
                    fontFamily: "MatterRegular",
                  },
                }}
                PaperProps={{ sx: { background: "inherit" } }}
              >
                <MenuItem
                  onClick={() => {
                    setStudioType("Public");
                    handleMenuClose();
                  }}
                >
                  Public
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setStudioType("Private");
                    handleMenuClose();
                  }}
                >
                  Private
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div>
            <div className="flex a-items-c gap8 flex-start mt-20 mb-20">
              <div
                className={classNames(styles.statusCircle, {
                  ["bg-color-green"]:
                    studioCredits.creditsPurchased - studioCredits.creditsUsed >
                    0,
                  ["bg-color-orange"]:
                    studioCredits.creditsPurchased -
                      studioCredits.creditsUsed ===
                    0,
                })}
              />
              <div className="flex gap8 full-width t-align-left flex-start a-items-c">
                <p className="font12 gray3-text t-align-left">
                  Started on {moment(studioStartDate).format("MMM D, YYYY")}
                </p>
                <div className={styles.verticalLine} />
                <p className="font12 gray3-text">
                  {studioCredits.creditsPurchased - studioCredits.creditsUsed >
                  0
                    ? "No end date"
                    : "On hold"}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.horizontallLine} />
          <div>
            <div className="mt-20 flex justify-space-between">
              <div className="flex flex-column gap8" style={{ width: "30%" }}>
                <p className="font11 gray3-text">Credits Used</p>
                <p className="font24 light-text">{studioCredits.creditsUsed}</p>
              </div>
              <div className="flex flex-column gap8" style={{ width: "30%" }}>
                <p className="font11 gray3-text">Credits Purchased</p>
                <p className="font24 light-text">
                  {studioCredits.creditsPurchased}
                </p>
              </div>
              <div className="flex flex-column gap8" style={{ width: "30%" }}>
                <p className="font11 gray3-text">Remaining Credits</p>
                <p
                  className={classNames("font24 light-text", {
                    ["green-text"]:
                      studioCredits.creditsPurchased -
                        studioCredits.creditsUsed >
                      0,
                    ["orange-text"]:
                      studioCredits.creditsPurchased -
                        studioCredits.creditsUsed ===
                      0,
                  })}
                >
                  {studioCredits.creditsPurchased - studioCredits.creditsUsed}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt40 flex gap20 flex-start a-items-start">
          <div className={styles.blockContainer}>
            <div className="flex flex-start gap8 a-items-c">
              <img src={GlobalIcon} alt="" />{" "}
              <p className="gold-text">Photo Review & Access</p>
            </div>
            <div className="bg-dark-gray padding16 border-radius16 mt-20 flex flex-column gap24">
              <div
                className={"flex flex-start a-items-start gap8 cursor-pointer"}
                onClick={() => setSelectedPhotoReview(false)}
              >
                <img
                  src={selectedPhotoReview ? DefaultRadioBtn : SelectedRadioBtn}
                  alt=""
                />
                <div>
                  <p className="light-text font13">Require Submission Review</p>
                  <p className="gray2-text font12 mt6">
                    All submissions for this studio must be accepted or
                    rejected.
                  </p>
                </div>
              </div>
              <div
                className={"flex flex-start a-items-start gap8 cursor-pointer"}
                onClick={() => setSelectedPhotoReview(true)}
              >
                <img
                  src={
                    !selectedPhotoReview ? DefaultRadioBtn : SelectedRadioBtn
                  }
                  alt=""
                />
                <div>
                  <p className="light-text font13">Auto-Accept Submissions</p>
                  <p className="gray2-text font12 mt6">
                    All submissions for this studio are automatically accepted.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <div className={"flex flex-start a-items-start gap8"}>
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={onToggleShowGallery}
                  checked={isShowGalleryChecked}
                />
                <div>
                  <p className="light-text font13">Show Gallery</p>
                  <p className="gray2-text font12 mt6">
                    Display a gallery screen to contacts once their submission
                    is approved.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <div className={"flex flex-start a-items-start gap8"}>
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={onToggleSuggestMobileDevice}
                  checked={isSuggestMobileDeviceChecked}
                />
                <div>
                  <p className="light-text font13">Suggest Mobile Device</p>
                  <p className="gray2-text font12 mt6">
                    if the user is using non-mobile device, show them a message
                    suggesting they use their phone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.blockContainer)}>
            <div className="flex flex-start gap8 a-items-c">
              <img src={GlobalIcon} alt="" />{" "}
              <p className="gold-text">Invite Reminders</p>
            </div>
            <div className="flex flex-column full-height mt-20">
              <div className={"flex flex-start a-items-start gap8"}>
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={onToggleSendAutomaticReminders}
                  checked={isSendAutomaticRemindersChecked}
                />
                <div>
                  <p className="light-text font13">Invite Reminders</p>
                  <p className="gray2-text font12 mt6">
                    Automatically send reminder emails to contacts who have been
                    and have yet to submit.{" "}
                  </p>
                </div>
              </div>
              {isSendAutomaticRemindersChecked && (
                <div>
                  <p className="light-text font13 mt-2">Reminder frequency</p>
                  <div className="flex a-items-c flex-start mt6 gap8">
                    <p className="gray2-text font12 text-nowrap">Email every</p>
                    <div className={"position-relative"}>
                      <DefaultInput
                        onChange={onChangeRemindNumber}
                        inputVal={remindNumber}
                        customClassNames="small-input"
                        maxLength={2}
                        type={"number"}
                        min={1}
                        max={31}
                      />
                    </div>
                    <div
                      onClick={handlePeriodMenuOpen}
                      className={"flex a-items-c cursor-pointer"}
                      style={{
                        background: "rgba(26, 26, 26, 1)",
                        height: 40,
                        paddingLeft: 8,
                        paddingRight: 8,
                        borderRadius: 8,
                      }}
                    >
                      <span
                        style={{
                          width: 100,
                          marginLeft: 10,
                          color: "rgba(152, 152, 152, 1)",
                          fontFamily: "MatterRegular",
                        }}
                      >
                        {
                          studioReminderPeriods.filter(
                            (item) => item.id === reminderPeriod,
                          )[0].name
                        }
                      </span>
                      <img src={ArrowDownIcon} alt="" width={24} height={24} />
                    </div>
                    <Menu
                      id="menu-basic"
                      anchorEl={anchorPeriodEl}
                      open={Boolean(anchorPeriodEl)}
                      onClose={handlePeriodMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                        sx: {
                          // Style the entire MenuList container
                          background: "rgba(30, 30, 30, 1)",
                          borderRadius: "16px",
                          borderWidth: 0,
                          color: "rgba(152, 152, 152, 1)",
                          fontSize: 12,
                          fontFamily: "MatterRegular",
                        },
                      }}
                      PaperProps={{ sx: { background: "inherit" } }}
                    >
                      {studioReminderPeriods.map((period, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            setReminderPeriod(period.id);
                            handlePeriodMenuClose();
                          }}
                        >
                          {period.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <PhotoConfiguration
          setSelectedPose={setSelectedPose}
          selectedPose={selectedPose}
          userGuidelines={userGuidelines}
          selectedBackgroundOption={selectedBackgroundOption}
          setSelectedBackgroundOption={setSelectedBackgroundOption}
          isAllowPhotoUpload={isAllowPhotoUpload}
          setIsAllowPhotoUpload={setIsAllowPhotoUpload}
          backgroundImages={backgroundImages}
          setBackgroundImages={onSetBackgroundImage}
          onSetBackgroundColor={onSetBackgroundColor}
          backgroundColors={backgroundColors}
          onDeleteColor={onDeleteColor}
          onDeleteImage={onDeleteImage}
          toggleNotificationModal={toggleNotificationModal}
          isNotificationModalOpen={isNotificationModalOpen}
          setNotificationText={setNotificationText}
          notificationText={notificationText}
        />
      </div>
    </>
  );
};

export default ManageStudio;
