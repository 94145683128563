import React, { ChangeEvent, FC, KeyboardEventHandler, useState } from "react";
import styles from "./PasswordInput.module.scss";
import OpenEyeIcon from "assets/svg/eye-open.svg";
import CloseEyeIcon from "assets/svg/eye-close.svg";
import classNames from "classnames";

type DefaultInputProps = {
  label?: string;
  placeholder: string;
  onChange: (val: string) => void;
  inputVal?: string;
  maxLength?: number;
  error?: string;
  errorWithoutText?: boolean;
  onKeyDown?: KeyboardEventHandler;
};
const PasswordInput: FC<DefaultInputProps> = ({
  label,
  placeholder,
  onChange,
  inputVal,
  maxLength = 25,
  error,
  errorWithoutText,
  onKeyDown,
}) => {
  const [val, setVal] = useState(inputVal || "");
  const [inputType, setInputType] = useState("password");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(value);
    setVal(value);
  };

  const toggleEye = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  return (
    <div className="position-relative">
      <p className={styles.label}>{label}</p>
      <input
        type={inputType}
        value={inputVal || val}
        className={classNames(styles.input, {
          ["input-error"]: !!error || errorWithoutText,
        })}
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
      />
      <div onClick={toggleEye} className={styles.iconContainer}>
        <img src={inputType === "text" ? CloseEyeIcon : OpenEyeIcon} alt="" />
      </div>
      {error && (
        <p className="error-text position-absolute font12" style={{ left: 0 }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default PasswordInput;
