import { createSlice } from "@reduxjs/toolkit";

type TabsState = {
  activeTab:
    | "studios"
    | "outputs"
    | "dashboard"
    | "contacts"
    | "submissions"
    | "gallery"
    | "resources"
    | "messageQuestion";
  studiosActiveTab: "list" | "create" | "edit";
  contactsActiveListId: number;
  contactsActiveTab: "list" | "contact";
  outputsActiveTab: "list" | "edit";
  submissionsActiveListId: number;
};

const initialState: TabsState = {
  activeTab: "dashboard",
  studiosActiveTab: "list",
  contactsActiveListId: 0,
  contactsActiveTab: "list",
  outputsActiveTab: "list",
  submissionsActiveListId: 0,
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveStudiosTab: (state, action) => {
      state.studiosActiveTab = action.payload;
    },
    setActiveContactsActiveListId: (state, action) => {
      state.contactsActiveListId = action.payload;
    },
    setActiveSubmissionsActiveListId: (state, action) => {
      state.submissionsActiveListId = action.payload;
    },
    setActiveContactsActiveTab: (state, action) => {
      state.contactsActiveTab = action.payload;
    },
    setActiveOutputsActiveTab: (state, action) => {
      state.outputsActiveTab = action.payload;
    },
    clearTabsState: () => {
      return initialState;
    },
  },
});

export const {
  setActiveTab,
  clearTabsState,
  setActiveStudiosTab,
  setActiveContactsActiveListId,
  setActiveContactsActiveTab,
  setActiveOutputsActiveTab,
  setActiveSubmissionsActiveListId,
} = tabsSlice.actions;

export default tabsSlice.reducer;
