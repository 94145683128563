import React, { ChangeEvent } from "react";
import styles from "./SearchInput.module.scss";
import SearchIcon from "assets/svg/search.svg";

const SearchInput = ({
  onChange,
  inputVal,
}: {
  onChange: (val: string) => void;
  inputVal?: string;
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <>
      <input
        id={"search-input"}
        className={styles.input}
        placeholder="Search contacts..."
        onChange={handleChange}
        value={inputVal}
      ></input>
      <img src={SearchIcon} alt="" className={styles.searchIcon} />
    </>
  );
};

export default SearchInput;
