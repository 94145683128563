import React, { useLayoutEffect, useState } from "react";
import styles from "./StudiosList.module.scss";
import PlusBtn from "assets/svg/plus-icon.svg";
import { useAppDispatch, useAppSelector } from "store";
import { setActiveStudiosTab } from "store/slices/tabs";
import { studiosTabName } from "constants/tabs";
import StudioCard from "../StudioCard/StudioCard";
import http from "helpers/http";
import { updateStudioList } from "store/slices/studios";
import { hiddenLoader, showLoader } from "store/slices/loader";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import ActionsModal from "components/modals/ActionsModal/ActionsModal";
import { isValidEmail } from "utils/validatiors";

const StudiosList = () => {
  const { studiosList } = useAppSelector((state) => state.studios);
  const dispatch = useAppDispatch();

  const [isInviteModalOpen, toggleInviteModal] = useState(false);
  const [inviteStudioId, setInviteStudioId] = useState(0);
  const [inviteEmail, setInviteEmail] = useState("");
  const [error, setError] = useState("");
  const fetchStudiosList = async () => {
    try {
      dispatch(showLoader());
      const resGetList: any = await http.get("/Studio/getAll", {
        params: {
          PageSize: 100,
          PageNumber: 1,
        },
      });
      dispatch(updateStudioList(resGetList.data.items));
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  useLayoutEffect(() => {
    fetchStudiosList().catch();
  }, []);

  const onChangeInviteEmail = (val: string) => {
    if (error) {
      setError("");
    }
    setInviteEmail(val);
  };

  const onCloseInviteModal = () => toggleInviteModal(false);

  const onClickInvite = (val: number) => {
    setInviteStudioId(val);
    toggleInviteModal(true);
  };

  const onInviteContact = async () => {
    const emails = inviteEmail.split(",");
    if (emails.every((el) => isValidEmail(el.trim()))) {
      try {
        dispatch(showLoader());
        const res: any = await http.post("/invite/contact", {
          studioId: inviteStudioId,
          email: inviteEmail,
        });
        dispatch(hiddenLoader());
        if (res.success) {
          toggleInviteModal(false);
        } else {
          setError("Something went wrong");
        }
      } catch (e) {
        console.log(e);
        dispatch(hiddenLoader());
      }
    } else {
      setError(
        "You have entered an invalid email address or separate sign. Please try again.",
      );
    }
  };

  return (
    <>
      <div className="flex justify-space-between a-items-c">
        <h2>Studios</h2>
        <button className={styles.addBtn}>
          <div
            className={styles.plusBtn}
            onClick={() => dispatch(setActiveStudiosTab(studiosTabName.create))}
          >
            <img src={PlusBtn} alt="" />
          </div>{" "}
          <p
            className="font14 light-text"
            onClick={() => dispatch(setActiveStudiosTab(studiosTabName.create))}
          >
            Create new studio
          </p>
        </button>
      </div>
      <div className="flex gap16 mt40 flex-start" style={{ flexWrap: "wrap" }}>
        {studiosList.length ? (
          studiosList.map((studio) => (
            <StudioCard
              key={studio.id}
              studio={studio}
              onClickInvite={onClickInvite}
            />
          ))
        ) : (
          <h2>You don`t have Studios. Please create.</h2>
        )}
      </div>
      <ActionsModal
        successActionText={"Send Invite"}
        title="Invite contacts"
        isOpen={isInviteModalOpen}
        onClose={onCloseInviteModal}
        successAction={onInviteContact}
      >
        <div className="mt-4">
          <DefaultInput
            placeholder="Enter contact emails (comma-separated)"
            onChange={onChangeInviteEmail}
            inputVal={inviteEmail}
            maxLength={256}
            error={error}
          />
        </div>
      </ActionsModal>
    </>
  );
};

export default StudiosList;
