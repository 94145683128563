import React from "react";
import styles from "./Dashboard.module.scss";
import classNames from "classnames";
import DataGrid from "./components/DataGrid/DataGrid";
import { useAppDispatch, useAppSelector } from "store";
import {
  setActiveOutputsActiveTab,
  setActiveStudiosTab,
  setActiveTab,
} from "store/slices/tabs";
import { outputsTabName, studiosTabName, tabsName } from "constants/tabs";
import PlusBtn from "assets/svg/plus-icon.svg";
import { setActiveOutputId } from "store/slices/outputs";
import StudioCard from "./components/StudioCard/StudioCard";

const Dashboard = () => {
  const { outputs } = useAppSelector((state) => state.outputs);
  const { studiosList } = useAppSelector((state) => state.studios);

  const dispatch = useAppDispatch();

  const onClickOutput = (id: number) => {
    dispatch(setActiveTab(tabsName.outputs));
    dispatch(setActiveOutputId(id));
    dispatch(setActiveOutputsActiveTab(outputsTabName.edit));
  };

  return (
    <div className="full-height full-width">
      <h2>Discover your Studio Pod</h2>
      <h6 className="mt40 mb16">Latest Studios</h6>
      <div className="flex gap16 flex-start">
        {studiosList.length ? (
          studiosList
            .slice(0, 2)
            .map((studio) => <StudioCard studio={studio} key={studio.id} />)
        ) : (
          <div className={styles.addBtnContainer}>
            <button className={styles.addBtn}>
              <div
                className={styles.plusBtn}
                onClick={() => {
                  dispatch(setActiveTab(tabsName.studios));
                  dispatch(setActiveStudiosTab(studiosTabName.create));
                }}
              >
                <img src={PlusBtn} alt="" />
              </div>{" "}
              <p
                className="font14 light-text"
                onClick={() => {
                  dispatch(setActiveTab(tabsName.studios));
                  dispatch(setActiveStudiosTab(studiosTabName.create));
                }}
              >
                Create new studio
              </p>
            </button>
          </div>
        )}
      </div>
      <div className={styles.outputsContainer}>
        <h6>Outputs</h6>
        <div className={"gray3-text font14"}>
          Overview of your latest exports
        </div>
        <div className="mt16 flex gap24">
          {outputs.map((output) => (
            <div
              key={output.id}
              className={classNames(
                "flex flex-start gap12 full-width a-items-c cursor-pointer",
                styles.outputContainer,
              )}
              onClick={() => onClickOutput(output.id)}
            >
              <div>
                <img
                  src={output.outputSampleImageUrl}
                  alt=""
                  width={48}
                  height={48}
                  style={{ borderRadius: 12 }}
                />
              </div>
              <h6>{output.name}</h6>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.outputsContainer}>
        <h6>Contacts</h6>
        <div className={"gray3-text font14"}>
          Overview of your latest exports
        </div>
        <div>
          <DataGrid />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
