import { createSlice } from "@reduxjs/toolkit";
import { Submission } from "./submissions";

export type ContactSession = {
  sessionId: 87;
  state: 1;
  studioId: 14;
  studioName: "myy";
  submittedDate: string;
  submissions: Submission[];
};

export type Contact = {
  createdAt: string;
  createdDate: string;
  email: string;
  firstName: string | null;
  id: number;
  lastName: string | null;
  phone: string | null;
  submissionsCount: 0;
  contactLists: List[];
  sessions: ContactSession[];
};

export type List = {
  id: number;
  name: string;
  contacts: Contact[];
};

type ContactsState = {
  allContacts: Contact[];
  contactsLists: List[];
  activeContactDetailsId: number;
  activeContactDetailsData: Contact | null;
  predictions: Contact[];
  contactsExportData: any;
};

const initialState: ContactsState = {
  allContacts: [],
  contactsLists: [{ name: "All Contacts", id: 0, contacts: [] }],
  activeContactDetailsId: 0,
  activeContactDetailsData: null,
  predictions: [],
  contactsExportData: {},
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    updateAllContacts: (state, action) => {
      state.allContacts = [...action.payload];
      const selectedList = state.contactsLists.find((item) => item.id === 0);
      if (selectedList) {
        selectedList.contacts = [...action.payload];
      }
    },
    updateContactsLists: (state, action) => {
      const newArray = action.payload.filter(
        (item: any) =>
          !state.contactsLists.some((item2) => item2.id === item.id),
      );
      state.contactsLists = [...state.contactsLists, ...newArray];
    },
    deleteContactsList: (state, action) => {
      const newStateArray = [...state.contactsLists];
      newStateArray.splice(action.payload, 1);
      state.contactsLists = [...newStateArray];
    },
    updateContactsList: (state, action) => {
      const newStateArray = state.contactsLists.map((contactsList) => {
        // Check if the current object's id matches the id of the object you want to update
        if (contactsList.id === action.payload.id) {
          return { ...contactsList, name: action.payload.name };
        }
        // If it doesn't match, return the original object unchanged
        return contactsList;
      });
      state.contactsLists = [...newStateArray];
    },
    updateListData: (state, action) => {
      const { id, contacts } = action.payload;
      const selectedList = state.contactsLists.find((item) => item.id === id);
      if (selectedList) {
        selectedList.contacts = [...contacts];
      }
    },
    setActiveContactDetailsId: (state, action) => {
      state.activeContactDetailsId = action.payload;
    },
    setActiveContactDetailsData: (state, action) => {
      state.activeContactDetailsData = action.payload;
    },
    setPredictions: (state, action) => {
      state.predictions = [...action.payload];
    },
    setContactsExportData: (state, action) => {
      state.contactsExportData = { ...action.payload };
    },
    clearContactsList: () => {
      return initialState;
    },
  },
});

export const {
  updateAllContacts,
  clearContactsList,
  updateContactsLists,
  updateListData,
  setActiveContactDetailsId,
  setActiveContactDetailsData,
  deleteContactsList,
  updateContactsList,
  setPredictions,
  setContactsExportData,
} = contactsSlice.actions;

export default contactsSlice.reducer;
