export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidatePassword = (password: string) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+-=]{6,}$/;
  return passwordRegex.test(password);
};
