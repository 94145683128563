import React, { useLayoutEffect, useState } from "react";
import BreadcrumbsComponent from "components/BreadcrumbsComponent/BreadcrumbsComponent";
import { outputsTabName } from "constants/tabs";
import { useAppDispatch, useAppSelector } from "store";
import { setActiveOutputsActiveTab } from "store/slices/tabs";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import styles from "./EditOutputComponent.module.scss";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import ArrowDownIcon from "assets/svg/arrow-down.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SelectedRadioBtn from "assets/svg/chosed-radio-icon.svg";
import DefaultRadioBtn from "assets/svg/default-radio-icon.svg";
import IOSSwitch from "../../../Studios/components/IOSswitcher/IOSswitcher";
import UploadIcon from "assets/svg/upload-icon.svg";
import { HexColorPicker } from "react-colorful";
import EditIcon from "assets/svg/edit-icon.svg";
import DeleteIcon from "assets/svg/delete-icon.svg";
import GenerateIcon from "assets/svg/ocean-protocol-(ocean).svg";
import http from "helpers/http";
import { hiddenLoader, showLoader } from "store/slices/loader";
import { setOutputs } from "store/slices/outputs";
import classNames from "classnames";

const EditOutputComponent = () => {
  const dispatch = useAppDispatch();
  const { activeOutputId, outputsOptions } = useAppSelector(
    (state) => state.outputs,
  );

  const [outputName, setOutputName] = useState("");
  const [anchorPhotoFilterEl, setAnchorPhotoFilterEl] =
    useState<null | HTMLElement>(null);
  const [anchorImageFormatEl, setAnchorImageFormatEl] =
    useState<null | HTMLElement>(null);
  const [filterType, setFilterType] = useState(1);
  const [imageFormat, setImageFormat] = useState(1);
  const [selectedCropFactor, setCropFactor] = useState(1);
  const [selectedCropPosition, setCropPosition] = useState(1);
  const [selectedResize, setResize] = useState(1);
  const [displayContact, toggleDisplayContact] = useState(false);
  const [autoEnhance, toggleAutoEnhance] = useState(false);
  const [originalImageUrl, setOriginalImageUrl] = useState("");
  const [previewImage, setPreviewImage] = useState("");

  const [backdropColor, setBackdropColor] = useState<{
    id: number;
    color: { id: number | null; hex: string | null };
  } | null>(null);
  const [isShowColorPicker, showColorPicker] = useState(false);

  const [anchorBackdropEl, setAnchorBackdropEl] = useState<null | HTMLElement>(
    null,
  );

  const [overlayImage, setOverlayImage] = useState<{
    id: number | null;
    image: { id: number; publicId: string | null; url: string | null };
  } | null>(null);

  const [backdropImage, setBackdropImage] = useState<{
    id: number | null;
    image: { id: number; publicId: string | null; url: string | null };
  } | null>(null);

  const [customBackdropColor, setCustomBackdropColor] = useState("");

  const handleBackdropMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!isShowColorPicker) setAnchorBackdropEl(event.currentTarget);
  };

  const handleBackdropMenuClose = () => setAnchorBackdropEl(null);

  const onToggleDisplayContact = () => {
    toggleDisplayContact((prev) => !prev);
  };

  const onToggleAutoEnhance = () => {
    toggleAutoEnhance((prev) => !prev);
  };

  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPhotoFilterEl(event.currentTarget);
  };
  const handleFilterMenuClose = () => setAnchorPhotoFilterEl(null);

  const handleImageFormatMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorImageFormatEl(event.currentTarget);
  };
  const handleImageFormatMenuClose = () => setAnchorImageFormatEl(null);

  const onChangeOutputName = (val: string) => {
    setOutputName(val);
  };

  const handleBackdropUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;
        if (base64String) {
          try {
            dispatch(showLoader());
            const res: any = await http.post(
              "/output/upload/image-backdrop-preview",
              {
                id: activeOutputId,
                image: { id: 1, imageBase64: base64String },
              },
            );
            if (res.success) {
              setBackdropColor(null);
              setBackdropImage(res.data);
            }
            dispatch(hiddenLoader());
          } catch (e) {
            dispatch(hiddenLoader());
            console.log(e);
          }
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    setAnchorBackdropEl(null);
  };

  const handleOverlayImageUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;
        if (base64String) {
          try {
            dispatch(showLoader());
            const res: any = await http.post(
              "/output/upload/image-overlay-preview",
              {
                id: activeOutputId,
                image: { id: 1, imageBase64: base64String },
              },
            );
            if (res.success) {
              setOverlayImage(res.data);
            }
            dispatch(hiddenLoader());
          } catch (e) {
            dispatch(hiddenLoader());
            console.log(e);
          }
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const fetchOutputData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get(`/Output/getDetails/${activeOutputId}`);
      if (res.success) {
        const {
          name,
          isDisplayToContactInStudio,
          autoEnhanceImage,
          outputPhotoFilter,
          outputCropFactor,
          outputCropPosition,
          outputImageFormat,
          outputResize,
          overlayImage,
          outputOriginalImageUrl,
          backdropColor,
          backdropImage,
        } = res.data;
        setOutputName(name);
        toggleDisplayContact(isDisplayToContactInStudio);
        toggleAutoEnhance(autoEnhanceImage);
        setFilterType(outputPhotoFilter);
        setCropFactor(outputCropFactor);
        setCropPosition(outputCropPosition);
        setImageFormat(outputImageFormat);
        setResize(outputResize);
        setOverlayImage({ id: activeOutputId, image: { ...overlayImage } });
        setOriginalImageUrl(outputOriginalImageUrl);
        if (activeOutputId)
          setBackdropColor({
            id: activeOutputId,
            color: { id: 1, hex: backdropColor.hex },
          });
        setBackdropImage({
          id: activeOutputId,
          image: {
            publicId: backdropImage.publicId,
            url: backdropImage.url,
            id: backdropImage.id,
          },
        });
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const onSetBackgroundColor = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.post(
        "/output/upload/color-backdrop-preview",
        {
          id: activeOutputId,
          color: { id: 1, hex: customBackdropColor },
        },
      );
      if (res.success) {
        setBackdropColor(res.data);
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const onGeneratePreview = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.post("/output/generate-preview", {
        id: activeOutputId,
        name: outputName,
        outputOriginalImageUrl: null,
        outputSampleImageUrl: null,
        backdropColor: {
          id: 1,
          hex: backdropColor?.color.hex,
        },
        backdropImage: {
          id: 1,
          url: backdropImage?.image.url,
          publicId: backdropImage?.image.publicId,
        },
        overlayImage: {
          id: 1,
          url: overlayImage?.image.url,
          publicId: overlayImage?.image.publicId,
        },
        outputPhotoFilter: filterType,
        outputCropFactor: selectedCropFactor,
        outputImageFormat: imageFormat,
        outputCropPosition: selectedCropPosition,
        outputResize: selectedResize,
        isDisplayToContactInStudio: displayContact,
        autoEnhanceImage: autoEnhance,
      });
      if (res.success) {
        setPreviewImage(res.data.url);
      }
      setTimeout(() => {
        dispatch(hiddenLoader());
      }, 4000);
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const updateOutput = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.put("/output/update", {
        id: activeOutputId,
        name: outputName,
        outputOriginalImageUrl: null,
        outputSampleImageUrl: null,
        backdropColor: {
          id: 1,
          hex: backdropColor?.color.hex,
        },
        backdropImage: {
          id: 1,
          url: backdropImage?.image.url,
          publicId: backdropImage?.image.publicId,
        },
        overlayImage: {
          id: 1,
          url: overlayImage?.image.url,
          publicId: overlayImage?.image.publicId,
        },
        outputPhotoFilter: filterType,
        outputCropFactor: selectedCropFactor,
        outputImageFormat: imageFormat,
        outputCropPosition: selectedCropPosition,
        outputResize: selectedResize,
        isDisplayToContactInStudio: displayContact,
        autoEnhanceImage: autoEnhance,
      });
      if (res.success) {
        const res2: any = await http.get("/Output/getAll", {
          params: { PageSize: 100, PageNumber: 1 },
        });
        if (res2.success) {
          dispatch(setActiveOutputsActiveTab(outputsTabName.list));
          dispatch(setOutputs(res.data.items));
        }
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  useLayoutEffect(() => {
    fetchOutputData().catch();
  }, []);

  const breadcrumbs = [
    <div
      key="1"
      onClick={() => dispatch(setActiveOutputsActiveTab(outputsTabName.list))}
      className="text-white cursor-pointer breadcrumbs-element"
    >
      Outputs
    </div>,
    <div key="2" className="text-white cursor-pointer breadcrumbs-element">
      {outputName}
    </div>,
  ];

  return (
    <>
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
      <div className="flex justify-space-between a-items-c">
        <h2>Edit output</h2>
        <div className="flex gap8">
          <WhiteButton
            text="Cancel"
            onClick={() =>
              dispatch(setActiveOutputsActiveTab(outputsTabName.list))
            }
            customStyles={"black-btn custom-studios-btn"}
          />
          <WhiteButton
            text="Save edits"
            customStyles={"custom-studios-btn"}
            onClick={updateOutput}
            isDisabled={!outputName}
          />
        </div>
      </div>
      <div className="mt40 flex gap24 a-items-start">
        <div className={styles.contentContainer}>
          <div className="flex flex-start gap8 a-items-start flex-column">
            <p className="gold-text font11">Output name</p>
            <div style={{ width: "100%" }}>
              <DefaultInput
                onChange={onChangeOutputName}
                inputVal={outputName}
                maxLength={50}
                placeholder={"Enter output name"}
              />
            </div>
          </div>
          <div className="flex gap12">
            <div className={styles.pickerWrapper}>
              <p
                style={{ color: "rgba(131, 129, 126, 1)", marginLeft: 8 }}
                className={"font11 mb-8"}
              >
                Backdrop
              </p>
              <div className={styles.pickerBackdropContainer}>
                {!!backdropColor?.color.hex || !!backdropImage?.image.url ? (
                  <div className="flex justify-space-between full-height full-width a-items-c">
                    {backdropColor?.color.hex ? (
                      <div
                        style={{
                          backgroundColor: `${backdropColor.color.hex}`,
                        }}
                        className={styles.backdropColorContainer}
                      />
                    ) : backdropImage?.image.url ? (
                      <div className={styles.backdropImageContainer}>
                        <img
                          src={backdropImage.image.url}
                          alt=""
                          width={"100%"}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex gap8">
                      {backdropImage?.image.url ? (
                        <div>
                          <label
                            htmlFor={"formId"}
                            className="full-width flex i-align-c gap8 flex-end"
                          >
                            <img
                              src={EditIcon}
                              alt=""
                              style={{ width: 32, height: 32 }}
                            />
                          </label>
                          <input
                            id="formId"
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            name="myImage"
                            style={{ display: "none" }}
                            onChange={handleBackdropUpload}
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            onClick={() => {
                              showColorPicker(true);
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                              style={{ width: 32, height: 32 }}
                            />
                          </div>
                          {isShowColorPicker && (
                            <div
                              className="position-absolute"
                              style={{ zIndex: 1000 }}
                            >
                              <HexColorPicker
                                color={customBackdropColor}
                                onChange={setCustomBackdropColor}
                              />
                              <WhiteButton
                                customStyles={
                                  "mt6 full-width t-align-center flex"
                                }
                                text={`Select ${customBackdropColor}`}
                                onClick={async () => {
                                  await onSetBackgroundColor();
                                  showColorPicker(false);
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                      <div
                        onClick={() => {
                          setBackdropColor(null);
                          setBackdropImage(null);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt=""
                          style={{ width: 32, height: 32 }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      src={UploadIcon}
                      alt=""
                      onClick={handleBackdropMenuOpen}
                    />
                    {isShowColorPicker && (
                      <div
                        className="position-absolute"
                        style={{ zIndex: 1000 }}
                      >
                        <HexColorPicker
                          color={customBackdropColor}
                          onChange={setCustomBackdropColor}
                        />
                        <WhiteButton
                          customStyles={"mt6 full-width t-align-center flex"}
                          text={`Select ${customBackdropColor}`}
                          onClick={async () => {
                            await onSetBackgroundColor();
                            showColorPicker(false);
                          }}
                        />
                      </div>
                    )}
                    <Menu
                      id="menu-basic"
                      anchorEl={anchorBackdropEl}
                      open={Boolean(anchorBackdropEl)}
                      onClose={handleBackdropMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                        sx: {
                          // Style the entire MenuList container
                          background: "rgba(30, 30, 30, 1)",
                          borderRadius: "16px",
                          borderWidth: 0,
                          color: "rgba(152, 152, 152, 1)",
                          fontSize: 12,
                          fontFamily: "MatterRegular",
                        },
                      }}
                      PaperProps={{ sx: { background: "inherit" } }}
                    >
                      <MenuItem>
                        <label
                          htmlFor={"formId2"}
                          className="full-width flex justify-content-start i-align-c gap8"
                        >
                          <p>Upload image</p>
                        </label>
                        <input
                          id="formId2"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          name="myImage"
                          style={{ display: "none" }}
                          onChange={handleBackdropUpload}
                        />
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleBackdropMenuClose();
                          showColorPicker(true);
                        }}
                      >
                        Select color
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            </div>
            <div className={styles.pickerWrapper}>
              <p
                style={{ color: "rgba(131, 129, 126, 1)", marginLeft: 8 }}
                className={"font11 mb-8"}
              >
                Overlay Image
              </p>
              <div className={styles.pickerOverlayContainer}>
                {overlayImage?.image.url ? (
                  <div className="flex justify-space-between full-height full-width">
                    <div className={styles.backdropImageContainer}>
                      <img src={overlayImage.image.url} alt="" width={"100%"} />
                    </div>
                    <div className="flex gap8">
                      <div>
                        <label
                          htmlFor={"formId3"}
                          className="full-width flex i-align-c gap8 flex-end"
                        >
                          <img src={EditIcon} alt="" />
                        </label>
                        <input
                          id="formId3"
                          type="file"
                          accept=".png"
                          name="myImage"
                          style={{ display: "none" }}
                          onChange={handleOverlayImageUpload}
                        />
                      </div>
                      <div
                        style={{ width: 32, height: 32 }}
                        onClick={() => {
                          setOverlayImage(null);
                        }}
                      >
                        <img src={DeleteIcon} alt="" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <label
                      htmlFor={"formId4"}
                      className="full-width flex i-align-c gap8 flex-end"
                    >
                      <img src={UploadIcon} alt="" />
                    </label>
                    <input
                      id="formId4"
                      type="file"
                      accept=".png"
                      name="myImage"
                      style={{ display: "none" }}
                      onChange={handleOverlayImageUpload}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-start gap8 a-items-start flex-column">
            <p className="gold-text font11">Photo filter</p>
            <div style={{ width: "100%" }}>
              <div
                onClick={handleFilterMenuOpen}
                className={
                  "flex a-items-c cursor-pointer justify-space-between"
                }
                style={{
                  background: "rgba(26, 26, 26, 1)",
                  height: 48,
                  paddingLeft: 16,
                  paddingRight: 8,
                  borderRadius: 16,
                }}
              >
                <span
                  style={{
                    width: "100%",
                    marginLeft: 10,
                    color: "rgba(152, 152, 152, 1)",
                    fontFamily: "MatterRegular",
                  }}
                >
                  {outputsOptions &&
                    outputsOptions.photoFilters.filter(
                      (item) => item.Id === filterType,
                    )[0]?.Name}
                </span>
                <img src={ArrowDownIcon} alt="" width={24} height={24} />
              </div>
              <Menu
                id="menu-basic"
                anchorEl={anchorPhotoFilterEl}
                open={Boolean(anchorPhotoFilterEl)}
                onClose={handleFilterMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: {
                    // Style the entire MenuList container
                    background: "rgba(30, 30, 30, 1)",
                    borderRadius: "16px",
                    borderWidth: 0,
                    color: "rgba(152, 152, 152, 1)",
                    fontSize: 12,
                    fontFamily: "MatterRegular",
                  },
                }}
                PaperProps={{ sx: { background: "inherit" } }}
              >
                {outputsOptions &&
                  outputsOptions.photoFilters.map((item) => (
                    <MenuItem
                      key={item.Id}
                      onClick={() => {
                        setFilterType(item.Id);
                        handleFilterMenuClose();
                      }}
                    >
                      {item.Name}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          </div>
          <p className={"font11"} style={{ color: "#F6F6F6", opacity: 0.6 }}>
            Apply additional effects to the image subject. Example: Add a
            black-and-white filter to the person, while leaving the backdrop
            as-is.
          </p>
          <div className="flex flex-start gap8 a-items-start flex-column">
            <p className="gold-text font11">Crop factor</p>
            <div style={{ width: "100%" }} className={styles.cropFactorWrapper}>
              {outputsOptions &&
                outputsOptions.cropFactors.map((item) => (
                  <div
                    key={item.Id}
                    className=""
                    onClick={() => setCropFactor(item.Id)}
                  >
                    <div className="flex flex-start a-items-start gap8 cursor-pointer">
                      <img
                        src={
                          selectedCropFactor === item.Id
                            ? SelectedRadioBtn
                            : DefaultRadioBtn
                        }
                        alt=""
                      />
                      <div>
                        <p className="light-text font12">{item.Name}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex flex-start gap8 a-items-start flex-column">
            <p className="gold-text font11">Image format</p>
            <div style={{ width: "100%" }}>
              <div
                onClick={handleImageFormatMenuOpen}
                className={
                  "flex a-items-c cursor-pointer justify-space-between"
                }
                style={{
                  background: "rgba(26, 26, 26, 1)",
                  height: 48,
                  paddingLeft: 16,
                  paddingRight: 8,
                  borderRadius: 16,
                }}
              >
                <span
                  style={{
                    width: "100%",
                    marginLeft: 10,
                    color: "rgba(152, 152, 152, 1)",
                    fontFamily: "MatterRegular",
                  }}
                >
                  {
                    outputsOptions?.imageFormats.filter(
                      (item) => item.Id === imageFormat,
                    )[0]?.Name
                  }
                </span>
                <img src={ArrowDownIcon} alt="" width={24} height={24} />
              </div>
              <Menu
                id="menu-basic"
                anchorEl={anchorImageFormatEl}
                open={Boolean(anchorImageFormatEl)}
                onClose={handleImageFormatMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: {
                    // Style the entire MenuList container
                    background: "rgba(30, 30, 30, 1)",
                    borderRadius: "16px",
                    borderWidth: 0,
                    color: "rgba(152, 152, 152, 1)",
                    fontSize: 12,
                    width: "100%",
                    fontFamily: "MatterRegular",
                  },
                }}
                PaperProps={{ sx: { background: "inherit" } }}
              >
                {outputsOptions &&
                  outputsOptions.imageFormats.map((format) => (
                    <MenuItem
                      key={format.Id}
                      onClick={() => {
                        setImageFormat(format.Id);
                        handleImageFormatMenuClose();
                      }}
                    >
                      {format.Name}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          </div>
          <div className="flex flex-start gap8 a-items-start flex-column">
            <p className="gold-text font11">Crop position</p>
            <div
              style={{ width: "100%" }}
              className={styles.cropPositionWrapper}
            >
              {outputsOptions?.cropPositions.map((item) => (
                <div
                  key={item.Id}
                  className=""
                  onClick={() => setCropPosition(item.Id)}
                >
                  <div className="flex flex-start a-items-start gap8 cursor-pointer">
                    <img
                      src={
                        selectedCropPosition === item.Id
                          ? SelectedRadioBtn
                          : DefaultRadioBtn
                      }
                      alt=""
                    />
                    <div>
                      <p className="light-text font12">{item.Name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-start gap8 a-items-start flex-column">
            <p className="gold-text font11">Resize</p>
            <div style={{ width: "100%" }} className={styles.cropFactorWrapper}>
              {outputsOptions &&
                outputsOptions.resizes.map((item) => (
                  <div
                    key={item.Id}
                    className=""
                    onClick={() => setResize(item.Id)}
                  >
                    <div className="flex flex-start a-items-start gap8 cursor-pointer">
                      <img
                        src={
                          selectedResize === item.Id
                            ? SelectedRadioBtn
                            : DefaultRadioBtn
                        }
                        alt=""
                      />
                      <div>
                        <p className="light-text font12">{item.Name}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt16 flex flex-start gap24">
            <div className={"flex flex-start a-items-c gap8"}>
              <IOSSwitch
                sx={{ m: 1 }}
                onChange={onToggleDisplayContact}
                checked={displayContact}
              />
              <div>
                <p className="gray2-text font12 mt6">
                  Display to contact in Studio
                </p>
              </div>
            </div>
            <div className={"flex flex-start a-items-c gap8"}>
              <IOSSwitch
                sx={{ m: 1 }}
                onChange={onToggleAutoEnhance}
                checked={autoEnhance}
              />
              <div>
                <p className="gray2-text font12 mt6">Auto-enhance image</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          {previewImage && (
            <div className={"flex flex-column gap16"}>
              <p className="gold-text font11">Origin</p>
              <div className={styles.generateImageContainer}>
                <img src={originalImageUrl} alt="" />
              </div>
              <p className="gold-text font11">Preview</p>
              <a
                className={classNames(
                  styles.generateImageContainer,
                  "cursor-pointer",
                )}
                target="_blank"
                href={previewImage}
                rel="noreferrer"
              >
                <img src={previewImage} alt="" />
              </a>
            </div>
          )}
          <div className={styles.generateBtn} onClick={onGeneratePreview}>
            <img src={GenerateIcon} alt="" />
            <p className="font14" style={{ color: "#989898" }}>
              Generate Preview
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOutputComponent;
