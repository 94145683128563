import React from "react";
import animation from "./Animation.json";
import Lottie from "lottie-react";

const Loader = () => (
  <Lottie
    animationData={animation}
    loop
    style={{
      width: "60%",
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
    }}
  />
);

export default Loader;
