export const periods = [
  { id: 1, name: "None" },
  { id: 2, name: "Past day" },
  { id: 3, name: "Past week" },
  { id: 4, name: "Past month" },
  { id: 5, name: "Past year" },
];

export const exportSubmissionStatus = [
  {
    id: null,
    name: "All",
  },
  {
    id: 1,
    name: "Review",
  },
  {
    id: 2,
    name: "Accepted",
  },
  {
    id: 3,
    name: "Rejected",
  },
];

export const imagesFormat = [
  {
    id: null,
    name: "All",
  },
  {
    id: 1,
    name: "JPEG",
  },
  {
    id: 2,
    name: "PNG",
  },
];
