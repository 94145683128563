import React from "react";
import styles from "./SubmissionCard.module.scss";
import { ContactSession } from "store/slices/contacts";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import classNames from "classnames";
import { submissionStatus } from "constants/sybmissions";
const SubmissionCard = ({ session }: { session: ContactSession }) => {
  const btnText =
    session.state === submissionStatus.accepted.id
      ? "Accepted"
      : session.state === submissionStatus.rejected.id
        ? "Reject"
        : "Review";
  return (
    <div className={styles.container}>
      <h6 className="font21 overflow-hidden text-ellipsis full-width  t-align-left mb16">
        {session.studioName}
      </h6>
      <div className="flex a-items-c gap16 mb40">
        <p className="font12 gray3-text t-align-left">
          Submitted {session.submittedDate}
        </p>
        <WhiteButton
          text={btnText}
          customStyles={classNames("custom-studios-btn", {
            ["accepted"]: session.state === submissionStatus.accepted.id,
            ["rejected"]: session.state === submissionStatus.rejected.id,
            ["review"]: session.state === submissionStatus.review.id,
          })}
        />
      </div>
      <div className={"flex gap12"}>
        {session?.submissions.map((item) => (
          <a
            className={styles.imageInnerContainer}
            key={item.id}
            target="_blank"
            href={item.url}
            rel="noreferrer"
          >
            <img src={item.url} alt="" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SubmissionCard;
