import React, { useState } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  getPaginationRowModel,
  Row,
} from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "store";
import { InvitedContact, setInvitedData } from "store/slices/submissions";
import InvitedActionsModal from "components/modals/InvitedActionsModal/InvitedActionsModal";
import { hiddenLoader, showLoader } from "store/slices/loader";
import http from "helpers/http";
import DropDownElement from "../DropDownElement/DropDownElement";
import { periods } from "../../constants";

const columnHelper = createColumnHelper<InvitedContact>();

const columns = [
  columnHelper.accessor("contactEmail", {
    cell: (info) => info.getValue(),
    header: () => "Email",
    size: 150,
  }),
  columnHelper.accessor("contactFirstName", {
    cell: (info) => info.getValue(),
    header: () => "First Name",
    size: 200,
  }),
  columnHelper.accessor("contactLastName", {
    cell: (info) => info.getValue(),
    header: () => "Last Name",
    size: 200,
  }),
  columnHelper.accessor("studioName", {
    header: () => "Studio",
    cell: (info) => info.renderValue(),
    size: 120,
  }),
  columnHelper.accessor("createdAt", {
    header: () => "Created at",
    cell: (info) => info.renderValue(),
    size: 120,
  }),
];
const InvitedDataGrid = ({
  selectedStudio,
  selectedPeriod,
  setSelectedStudio,
  setSelectedPeriod,
  setPageIndex,
  pageIndex,
  setPageSize,
  totalPages,
}: {
  selectedStudio: { id: number; name: string } | null;
  setSelectedStudio: (val: { id: number; name: string }) => void;
  selectedPeriod: { id: number; name: string } | null;
  setSelectedPeriod: (val: { id: number; name: string }) => void;
  setPageIndex: (val: number) => void;
  pageIndex: number;
  setPageSize: (val: number) => void;
  totalPages: number;
}) => {
  const dispatch = useAppDispatch();
  const { invitedData } = useAppSelector((state) => state.submissions);

  const [isActionsModalOpen, toggleActionsModal] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [activeInviteId, setActiveInviteId] = useState(0);
  const { studioNames } = useAppSelector((state) => state.studios);

  const table = useReactTable({
    data: invitedData,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onCloseActionsModal = () => toggleActionsModal(false);

  const fetchInvitedData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/invite/getAll", {
        params: { PageSize: 100, PageNumber: 1 },
      });
      if (res.success) {
        dispatch(setInvitedData(res.data.items));
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const revokeContactInvite = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get(
        `/invite/revoke-invite/${activeInviteId}`,
      );
      if (res.success) {
        await fetchInvitedData();
        toggleActionsModal(false);
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const remindContactInvite = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get(
        `/invite/send-reminder/${activeInviteId}`,
      );
      if (res.success) {
        toggleActionsModal(false);
      }
      dispatch(hiddenLoader());
    } catch (e) {
      console.log(e);
      dispatch(hiddenLoader());
    }
  };

  const onOpenContactActions = (row: Row<InvitedContact>) => {
    setActiveInviteId(row.original.id);
    toggleActionsModal(true);
  };

  const handleNextPage = () => {
    if (totalPages !== pageIndex + 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageIndex !== 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  return (
    <>
      <div
        className="padding-h24 mt-20 flex flex-end a-items-c"
        style={{ height: 30 }}
      >
        <div className="flex gap8 a-items-c">
          <p className={"gold-text font11"}>Studio</p>
          <div>
            <DropDownElement
              selectedStudio={selectedStudio}
              setSelectedStudio={setSelectedStudio}
              title="All studios"
              data={studioNames}
            />
          </div>
          <div>
            <DropDownElement
              selectedStudio={selectedPeriod}
              setSelectedStudio={setSelectedPeriod}
              title="Submitted after"
              data={periods}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-4 pb-6 full-width padding24">
        <table className={"full-width"} style={{ tableLayout: "fixed" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="table-border-b table-header">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="py-4 font-medium t-align-left"
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none flex min-w-[36px] flex-start position-relative"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <span className="pl-2">↑</span>,
                          desc: <span className="pl-2">↓</span>,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {table.getRowModel()?.rows?.length > 0 ? (
            <tbody style={{ width: "100%" }}>
              {table.getRowModel()?.rows?.map((row) => (
                <tr
                  key={row.id}
                  className="table-border-b custom-table-row"
                  onClick={() => onOpenContactActions(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="pt-[14px] pb-[18px] table-item"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={columns.length} className="text-center py-4">
                  <p className="gray5-text font-s20">No rows</p>
                </td>
              </tr>
            </tbody>
          )}
        </table>

        <div
          className="sm:flex-row flex-col w-full mt-8 items-center text-xs gap24 justify-end"
          style={{ display: "flex" }}
        >
          <div className="sm:mb-0 mb-2">
            <span className="mr-2 text-white">Rows per page:</span>
            <select
              className="p-1 w-16 table-selector-bg"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-2">
            <button
              className={`${
                pageIndex === 0
                  ? "bg-gray-100 next-btn-disable"
                  : "hover:bg-gray-200 hover:curstor-pointer bg-gray-100 next-btn-active"
              } rounded p-1`}
              onClick={() => handlePrevPage()}
              disabled={pageIndex === 0}
            >
              <span className="w-5 h-5 text-white">{"<"}</span>
            </button>
            <button
              className={`${
                totalPages === pageIndex + 1
                  ? "bg-gray-100 next-btn-disable"
                  : "hover:bg-gray-200 hover:curstor-pointer bg-gray-100 next-btn-active"
              } rounded p-1`}
              onClick={() => handleNextPage()}
              disabled={totalPages === pageIndex + 1}
            >
              <span className="w-5 h-5 text-white">{">"}</span>
            </button>
          </div>
        </div>
        <InvitedActionsModal
          isOpen={isActionsModalOpen}
          title={"Contact actions"}
          onClose={onCloseActionsModal}
          revokeAction={revokeContactInvite}
          remindAction={remindContactInvite}
        />
      </div>
    </>
  );
};

export default InvitedDataGrid;
