import React, { useEffect, useState } from "react";
import http from "helpers/http";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";

const SuccessfulMailPage = () => {
  const navigate = useNavigate();
  const replaceSpacesWithPlus = (url: string) => {
    return url.replace(/\s/g, "+");
  };

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [title, setTitle] = useState("");

  const [showLoader, setShowLoader] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        let tokenData = "";
        if (token) tokenData = replaceSpacesWithPlus(token);
        await http.post(`/User/confirmation-email`, {
          email,
          token: tokenData,
        });
        setTitle("Mail has been successfully confirmed");
        setShowLoader(false);
      } catch (e: any) {
        if (e.responseStatus.code === 1001) {
          setTitle("The email has already been confirmed.");
        }
        setShowLoader(false);
        setIsError(true);
      }
    };
    if (token) {
      setTimeout(() => confirmEmail(), 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="full-width full-height bg-dark flex a-items-c">
      {isError ? (
        <div className="flex flex-column a-items-c">
          <h2>{title}</h2>
          <div style={{ height: 44, width: 200, marginTop: "5%" }}>
            <WhiteButton
              text="Back to Sign in"
              customStyles="full-width t-align-center border-radius16 flex a-items-c"
              onClick={() => navigate("/login")}
            />
          </div>
        </div>
      ) : showLoader ? (
        <Loader />
      ) : (
        <div className="flex flex-column a-items-c">
          <h2>{title}</h2>
          <div style={{ height: 44, width: 200, marginTop: "5%" }}>
            <WhiteButton
              text="Back to Sign in"
              customStyles="full-width t-align-center border-radius16 flex a-items-c"
              onClick={() => navigate("/login")}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessfulMailPage;
