import React from "react";

const MessageQuestion = ({ stroke }: { stroke: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 12.2866H8.66658L5.69991 14.2599C5.25991 14.5532 4.66659 14.2399 4.66659 13.7066V12.2866C2.66659 12.2866 1.33325 10.9532 1.33325 8.95325V4.95321C1.33325 2.95321 2.66659 1.61987 4.66659 1.61987H11.3333C13.3333 1.61987 14.6666 2.95321 14.6666 4.95321V8.95325C14.6666 10.9532 13.3333 12.2866 11.3333 12.2866Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99996 7.57324V7.43327C7.99996 6.97993 8.27998 6.73992 8.55998 6.54659C8.83331 6.35992 9.10661 6.11993 9.10661 5.67993C9.10661 5.0666 8.61329 4.57324 7.99996 4.57324C7.38663 4.57324 6.89331 5.0666 6.89331 5.67993"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99708 9.16659H8.00308"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MessageQuestion;
