import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Export.module.scss";
import classNames from "classnames";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";
import moment from "moment";
const Export = () => {
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, toggleModal] = useState(false);
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const dropboxUrl = searchParams.get("dropboxUrl");
  const dropboxDate = searchParams.get("createDate");

  useEffect(() => {
    if (dropboxUrl) {
      const givenDate = moment(dropboxDate);
      navigate("/export");
      const checkTime = () => {
        const now = moment();
        const timeDiff = now.diff(givenDate, "hours");

        return timeDiff >= 24;
      };

      if (!checkTime()) {
        const fileHref = dropboxUrl + "&dl=1";
        const anchor = document.createElement("a");
        anchor.setAttribute("download", "true");
        anchor.setAttribute("href", fileHref);
        anchor.click();
      } else {
        setModalMessage("Export could not be found or has expired.");
        toggleModal(true);
      }
    }
  }, []);

  const closeNotificationModal = () => {
    toggleModal(false);
    navigate("/dashboard");
  };

  return (
    <div className="full-width full-height bg-dark flex a-items-c padding24">
      <div
        className="border-light border-radius32 flex flex-column a-items-c flex-start full-width full-height"
        style={{
          backgroundColor: "rgba(26, 26, 26, 1)",
          borderWidth: 1.5,
          paddingTop: "20vh",
        }}
      >
        <div
          className={classNames("flex flex-column a-items-c", styles.container)}
        >
          <h2>Download export</h2>
          {/*<div className={"full-width flex flex-column gap20"}></div>*/}
        </div>
      </div>
      <NotificationModal
        onClose={closeNotificationModal}
        isOpen={isModalOpen}
        text={modalMessage}
      />
    </div>
  );
};

export default Export;
