import * as React from "react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setRefreshToken,
  setToken,
  setUserEmail,
  setUserRole,
} from "helpers/localStorageHelpers";
import http from "helpers/http";
import styles from "./SignIn.module.scss";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import { isValidEmail } from "utils/validatiors";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";
import PasswordInput from "components/fields/PasswordInput/PasswordInput";
import { jwtDecode } from "jwt-decode";
import { useAppDispatch } from "store";
import { setUserCompanyName } from "store/slices/user";
import { hiddenLoader, showLoader } from "store/slices/loader";

type SignInProps = {
  setFormType: (val: "login" | "signup" | "forgotPass") => void;
};

const SignIn: FC<SignInProps> = ({ setFormType }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, toggleModal] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [emailError, setEmailError] = useState("");

  const resendVerificationCode = async () => {
    await http.post("/User/resend-confirmation-email", { email });
  };

  const errorLogin = (
    <>
      Email confirmation required. Did you not receive a confirmation email?{" "}
      <span
        className="text-with-underline light-gray-text cursor-pointer"
        onClick={resendVerificationCode}
      >
        Resend
      </span>
    </>
  );

  const handleChangeEmail = (val: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmail(val);
  };

  const handleChangePassword = (val: string) => {
    if (errorMessage) {
      setErrorMessage("");
    }
    setPassword(val);
  };

  const onClickLogin = async () => {
    if (isValidEmail(email)) {
      try {
        dispatch(showLoader());
        const res: any = await http.post("/Auth/login", {
          email,
          password,
        });
        if (res?.data.accessToken) {
          const payload: any = jwtDecode(res?.data.accessToken);
          setUserEmail(email);
          setUserRole(
            payload[
              "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ],
          );
          if (payload["http://schemas.studiopod.com/claims/companyname"]) {
            dispatch(
              setUserCompanyName(
                payload["http://schemas.studiopod.com/claims/companyname"],
              ),
            );
          }
          setToken(res.data.accessToken);
          setRefreshToken(res.data.refreshToken);
        }
        dispatch(hiddenLoader());
        navigate("/dashboard");
      } catch (e: any) {
        if (e.responseStatus.code === 1004) {
          setStatusCode(1004);
          toggleModal(true);
        } else {
          setStatusCode(0);
          setEmailError(
            "The email/password combination you entered is incorrect.",
          );
        }
        dispatch(hiddenLoader());
        console.log(e);
      }
    } else {
      setStatusCode(0);
      setEmailError(
        "You have entered an invalid email address. Please try again.",
      );
      dispatch(hiddenLoader());
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      await onClickLogin();
    }
  };

  return (
    <div className={styles.container}>
      <p
        className="light-text t-align-center"
        style={{ fontSize: 23, marginTop: "3vh" }}
      >
        Log in to manage your
        <br /> Studio Pod sessions
      </p>
      <div className="flex flex-column gap16 mt40">
        <DefaultInput
          label="Email"
          placeholder="Enter email address"
          onChange={handleChangeEmail}
          inputVal={email}
          maxLength={256}
          error={emailError}
        />
        <PasswordInput
          label="Password"
          placeholder="***************"
          onChange={handleChangePassword}
          inputVal={password}
          errorWithoutText={!!emailError}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="full-width" style={{ height: 44 }}>
        <WhiteButton
          text="Continue with email"
          customStyles="full-width t-align-center border-radius16 flex a-items-c mt-20"
          onClick={onClickLogin}
          isDisabled={!email || !password || !!errorMessage}
        />
      </div>
      <div className="mt10 flex justify-space-between">
        <div
          className="light-gray-text font14 t-align-right cursor-pointer"
          onClick={() => setFormType("forgotPass")}
        >
          <span className="text-with-underline light-text">
            Forgot password
          </span>
        </div>
        <div
          className="light-gray-text font14 t-align-right cursor-pointer"
          onClick={() => setFormType("signup")}
        >
          Don&apos;t have account?{" "}
          <span className="text-with-underline light-text cursor-pointer">
            Sign up
          </span>
        </div>
      </div>
      <p className={styles.description} style={{ marginTop: "10%" }}>
        By continuing, you acknowledge that you have read and understood, and
        agree to Studio Pod&apos;s
        <span className="text-with-underline light-text">
          {" "}
          Terms of Service
        </span>{" "}
        and
        <span className="text-with-underline light-text"> Privacy Policy</span>.
      </p>
      <NotificationModal
        onClose={() => {
          toggleModal(false);
        }}
        isOpen={isModalOpen}
        text={statusCode === 1004 ? errorLogin : errorMessage}
      />
    </div>
  );
};

export default SignIn;
