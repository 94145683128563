import * as React from "react";
import { FC, useState } from "react";
import http from "helpers/http";
import { setDataToLocalStorage } from "helpers/localStorageHelpers";
import styles from "./SignUp.module.scss";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";
import PasswordInput from "components/fields/PasswordInput/PasswordInput";
import { isValidatePassword, isValidEmail } from "utils/validatiors";
import { useAppDispatch } from "store";
import { hiddenLoader, showLoader } from "store/slices/loader";

type SignUpProps = {
  setFormType: (val: "login" | "signup" | "forgotPass") => void;
};

const SignUp: FC<SignUpProps> = ({ setFormType }) => {
  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [selectedRegistrationType, setRegistrationType] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, toggleModal] = useState(false);
  const [isRegistrationSuccess, registrationSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleChangeFirstName = (val: string) => {
    if (nameError) {
      setNameError("");
    }
    setFirstName(val);
  };

  const handleChangeCompanyName = (val: string) => {
    if (nameError) {
      setNameError("");
    }
    setCompanyName(val);
  };

  const handleChangeLastName = (val: string) => {
    if (nameError) {
      setNameError("");
    }
    setLastName(val);
  };

  const handleChangeEmail = (val: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmail(val);
  };

  const handleChangePassword = (val: string) => {
    if (passwordError) {
      setPasswordError("");
    }
    setPassword(val);
  };

  const closeNotificationModal = () => {
    if (isRegistrationSuccess) {
      setFormType("login");
    }
    toggleModal(false);
  };

  const handleSubmit = async () => {
    dispatch(showLoader());
    if (isValidEmail(email) && isValidatePassword(password)) {
      try {
        if (selectedRegistrationType === "admin") {
          await http.post("/Auth/register-corporate-admin", {
            firstName,
            lastName,
            companyName,
            email,
            password,
          });
        } else {
          await http.post("/Auth/register-individual", {
            firstName,
            lastName,
            email,
            password,
          });
        }
        setDataToLocalStorage({
          key: "userDataForLogin",
          data: JSON.stringify({ email, password }),
        });
        registrationSuccess(true);
        setModalMessage("A confirmation email has been sent.  Please Confirm!");
        toggleModal(true);
        dispatch(hiddenLoader());
      } catch (e: any) {
        console.log(e);
        dispatch(hiddenLoader());
        if (e.responseStatus.code === 1000) {
          setPasswordError(
            "Passwords must be at least 6 characters.\nPasswords must have at least one non alphanumeric character.\nPasswords must have at least one digit ('0'-'9').\nPasswords must have at least one uppercase ('A'-'Z').",
          );
        } else if (e.responseStatus.code === 1002) {
          setEmailError("Email address already exists.");
        }
      }
    } else {
      if (!isValidEmail(email)) {
        setEmailError(
          "You have entered an invalid email address. Please try again.",
        );
      } else {
        setPasswordError(
          "Passwords must be at least 6 characters.\nPasswords must have at least one non alphanumeric character.\nPasswords must have at least one digit ('0'-'9').\nPasswords must have at least one uppercase ('A'-'Z').",
        );
      }
      dispatch(hiddenLoader());
    }
  };

  const isBtnDisabled =
    selectedRegistrationType === "user"
      ? !firstName || !lastName || !email || !password
      : !companyName || !email || !password;

  return (
    <div className={styles.container}>
      <p
        className="light-text t-align-center"
        style={{ fontSize: 23, marginTop: "10%" }}
      >
        Sign up to create your
        <br />
        first Studio Pod session
      </p>
      {!selectedRegistrationType ? (
        <>
          {/*<p className="light-text t-align-center" style={{ marginTop: "15%" }}>*/}
          {/*  Which type of registration <br /> will you choose?*/}
          {/*</p>*/}
          <div
            className="full-width flex gap8"
            style={{ height: 44, marginTop: "54%" }}
          >
            <WhiteButton
              text="as corporate admin"
              customStyles="full-width t-align-center border-radius16 flex a-items-c"
              onClick={() => setRegistrationType("admin")}
            />
            {/*<WhiteButton*/}
            {/*  text="individual"*/}
            {/*  customStyles="full-width t-align-center border-radius16 flex a-items-c"*/}
            {/*  onClick={() => setRegistrationType("user")}*/}
            {/*  isDisabled={true}*/}
            {/*/>*/}
          </div>
        </>
      ) : selectedRegistrationType === "admin" ? (
        <>
          <div className="flex flex-column gap16 mt40">
            <DefaultInput
              label="Company"
              placeholder="Enter company name"
              onChange={handleChangeCompanyName}
              inputVal={companyName}
              error={nameError}
              maxLength={60}
            />
            <DefaultInput
              label="Email"
              placeholder="Enter email address"
              onChange={handleChangeEmail}
              inputVal={email}
              maxLength={256}
              error={emailError}
            />
            <PasswordInput
              label="Password"
              placeholder="***************"
              onChange={handleChangePassword}
              inputVal={password}
              maxLength={25}
              error={passwordError}
            />
          </div>
          <div className="full-width" style={{ height: 44 }}>
            <WhiteButton
              text="Registration"
              customStyles="full-width t-align-center border-radius16 flex a-items-c mt-60"
              onClick={handleSubmit}
              isDisabled={isBtnDisabled}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-column gap16 mt40">
            <div className="flex justify-space-between position-relative">
              <DefaultInput
                label="First Name"
                placeholder="Enter first name"
                onChange={handleChangeFirstName}
                inputVal={firstName}
              />
              <DefaultInput
                label="Last Name"
                placeholder="Enter last name"
                onChange={handleChangeLastName}
                inputVal={lastName}
              />
            </div>

            <DefaultInput
              label="Email"
              placeholder="Enter email address"
              onChange={handleChangeEmail}
              inputVal={email}
              error={emailError}
            />
            <PasswordInput
              label="Password"
              placeholder="***************"
              onChange={handleChangePassword}
              inputVal={password}
              maxLength={25}
              error={passwordError}
            />
          </div>
          <div className="full-width" style={{ height: 44 }}>
            <WhiteButton
              text="Registration"
              customStyles="full-width t-align-center border-radius16 flex a-items-c mt-60"
              onClick={handleSubmit}
              isDisabled={isBtnDisabled}
            />
          </div>
        </>
      )}
      <div
        className="light-gray-text font14 t-align-right mt10 cursor-pointer"
        onClick={() => setFormType("login")}
      >
        Already have an account?{" "}
        <span className="text-with-underline light-text">Sign in</span>
      </div>
      <NotificationModal
        onClose={closeNotificationModal}
        isOpen={isModalOpen}
        text={modalMessage}
      />
    </div>
  );
};

export default SignUp;
