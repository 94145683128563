import React, { FC, ReactElement } from "react";
import Modal from "react-modal";
import styles from "./ActionsModal.module.scss";
import CloseIcon from "assets/svg/close-icon.svg";
import classNames from "classnames";
import WhiteButton from "../../buttons/WhiteButton/WhiteButton";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(26, 26, 26, 1)",
    boxShadow: "0 24px 45.20000076293945px 0 rgba(0, 0, 0, 0.25)",
    borderRadius: 24,
    padding: 24,
  },
};

type ActionsModalProps = {
  children: ReactElement;
  isOpen: boolean;
  onClose?: () => void | undefined;
  title: string;
  successAction: () => void;
  successActionText: string;
  isWarning?: boolean;
  displayCancelBtn?: boolean;
};

const ActionsModal: FC<ActionsModalProps> = ({
  children,
  isOpen,
  onClose,
  title,
  successAction,
  successActionText,
  isWarning,
  displayCancelBtn = true,
}) => {
  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.header,
            "flex a-items-c justify-space-between",
          )}
        >
          <div className="light-text">{title}</div>
          <div onClick={onClose} className="cursor-pointer">
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className={classNames(styles.horizontalLine, "mt-4")} />
        {children}
        <div className="flex gap8 mt-4" style={{ justifyContent: "end" }}>
          {displayCancelBtn && (
            <WhiteButton
              text="Cancel"
              onClick={onClose}
              customStyles={"black-btn custom-studios-btn"}
            />
          )}
          <WhiteButton
            text={successActionText}
            customStyles={classNames("custom-studios-btn", {
              ["red-btn"]: isWarning,
            })}
            onClick={successAction}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ActionsModal;
