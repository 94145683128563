import { createSlice } from "@reduxjs/toolkit";

type UserState = {
  userData: {
    companyName: string;
  };
};

const initialState: UserState = {
  userData: { companyName: "" },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserCompanyName: (state, action) => {
      state.userData.companyName = action.payload;
    },
    clearUserState: () => {
      return initialState;
    },
  },
});

export const { setUserCompanyName, clearUserState } = userSlice.actions;

export default userSlice.reducer;
