import React, { useEffect, useState } from "react";
import http from "helpers/http";
import { useNavigate } from "react-router-dom";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import styles from "./CreateInvite.module.scss";
import classNames from "classnames";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";
import DefaultInput from "../../components/fields/DefaultInput/DefaultInput";
import validator from "validator";

import { isValidEmail } from "../../utils/validatiors";
const CreateInvite = () => {
  const navigate = useNavigate();

  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, toggleModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const studio = searchParams.get("studio");

  const handleChangeEmail = (val: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmail(val);
  };
  const handleChangePhone = (val: string) => {
    setPhone(val);
    if (!val.length) {
      setPhoneError("");
    } else if (validator.isMobilePhone(val)) {
      setPhoneError("");
    } else {
      setPhoneError("Enter your valid Phone Number!");
    }
  };

  useEffect(() => {
    if (!studio) {
      setModalMessage("Invalid invite link");
      toggleModal(true);
    }
  }, []);

  const onCreateInvite = async () => {
    if (isValidEmail(email)) {
      try {
        setLoading(true);
        const res: any = await http.post("/invite/create-admin", {
          studioId: studio,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
        });
        setLoading(false);
        if (res?.success) {
          window.location.href = res.data.sessionUrl;
        }
      } catch (e: any) {
        setLoading(false);
        setModalMessage("You do not have access to send an invitation.");
        toggleModal(true);
      }
    } else {
      setEmailError(
        "You have entered an invalid email address. Please try again.",
      );
    }
  };

  const closeNotificationModal = () => {
    toggleModal(false);
    navigate("/dashboard");
  };

  const onChangeFirstName = (val: string) => {
    if (firstNameError) setFirstNameError("");
    setFirstName(val);
  };

  const onChangeLastName = (val: string) => {
    if (lastNameError) setLastNameError("");
    setLastName(val);
  };

  return (
    <div className="full-width full-height bg-dark flex a-items-c padding24">
      <div
        className="border-light border-radius32 flex flex-column a-items-c flex-start full-width full-height"
        style={{
          backgroundColor: "rgba(26, 26, 26, 1)",
          borderWidth: 1.5,
          paddingTop: "20vh",
        }}
      >
        <div
          className={classNames("flex flex-column a-items-c", styles.container)}
        >
          <h2>Tell us about yourself</h2>
          <div className={"full-width flex flex-column gap20"}>
            <DefaultInput
              label="First Name"
              goldLabel
              placeholder="Enter first name"
              onChange={onChangeFirstName}
              inputVal={firstName}
              maxLength={100}
              isRequired
            />
            <DefaultInput
              label="Last Name"
              goldLabel
              placeholder="Enter last name"
              onChange={onChangeLastName}
              inputVal={lastName}
              maxLength={100}
              isRequired
            />
            <DefaultInput
              label="Email"
              goldLabel
              placeholder="Enter email"
              onChange={handleChangeEmail}
              inputVal={email}
              error={emailError}
              maxLength={256}
              isRequired
            />
            <DefaultInput
              label="Phone"
              goldLabel
              placeholder="Enter phone number"
              onChange={handleChangePhone}
              inputVal={phone}
              maxLength={20}
              error={phoneError}
            />
            <WhiteButton
              text="Next"
              customStyles="full-width t-align-center border-radius16 flex a-items-c mt40"
              onClick={onCreateInvite}
              isDisabled={!firstName || !lastName || !!emailError || isLoading}
            />
          </div>
        </div>
      </div>
      <NotificationModal
        onClose={closeNotificationModal}
        isOpen={isModalOpen}
        text={modalMessage}
      />
    </div>
  );
};

export default CreateInvite;
