import React from "react";
import StudiosList from "./components/StudiosList/StudiosList";
import CreateStudio from "./components/CreateStudio/CreateStudio";
import { useAppSelector } from "store";
import { studiosTabName } from "constants/tabs";
import ManageStudio from "./components/ManageStudio/ManageStudio";

const Studios = () => {
  const { studiosActiveTab } = useAppSelector((state) => state.tabs);
  return (
    <div className="full-height full-width">
      {studiosActiveTab === studiosTabName.list ? (
        <StudiosList />
      ) : studiosActiveTab === studiosTabName.edit ? (
        <ManageStudio />
      ) : (
        <CreateStudio />
      )}
    </div>
  );
};

export default Studios;
