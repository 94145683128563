import React from "react";
import styles from "./ContactExports.module.scss";
import classNames from "classnames";
import { useAppSelector } from "../../store";
import WhiteButton from "../../components/buttons/WhiteButton/WhiteButton";
import ImportIcon from "../../assets/svg/import-black.svg";
const ContactExports = () => {
  const { contactsExportData } = useAppSelector((state) => state.contacts);

  const downloadImages = () => {
    const anchor = document.createElement("a");
    anchor.setAttribute("download", "true");
    anchor.setAttribute("href", contactsExportData?.url);
    anchor.click();
  };
  return (
    <div className="full-width full-height bg-dark flex a-items-c padding24">
      <div
        className="border-light border-radius32 flex flex-column a-items-c flex-start full-width full-height"
        style={{
          backgroundColor: "rgba(26, 26, 26, 1)",
          borderWidth: 1.5,
          paddingTop: "20vh",
        }}
      >
        <div
          className={classNames("flex flex-column a-items-c", styles.container)}
        >
          <h2>Download export</h2>
          <div className={styles.grayContainer}>
            <div className={"flex a-items-c gap20"}>
              <p className={"text-white font21"}>Total files</p>
              <p className={"text-white font21"}>
                {contactsExportData?.totalFiles}
              </p>
            </div>
            <div className={"flex a-items-c gap20"}>
              <p className={"text-white font21"}>Download size</p>
              <p className={"text-white font21"}>
                {contactsExportData?.downloadSize}
              </p>
            </div>
            <div className={"flex a-items-c gap20"}>
              <p className={"text-white font21"}>Total items processed</p>
              <p className={"text-white font21"}>
                {contactsExportData?.totalItemsProcessed}
              </p>
            </div>
            <div className={"flex a-items-c gap20"}>
              <p className={"text-white font21"}>Total items failed</p>
              <p className={"text-white font21"}>
                {contactsExportData?.totalItemsFailed}
              </p>
            </div>
            <div className={"flex a-items-c gap20"}>
              <p className={"text-white font21"}>File type</p>
              <p className={"text-white font21"}>
                {contactsExportData?.fileType}
              </p>
            </div>
            <div className={"flex a-items-c gap20"}>
              <p className={"text-white font21"}>File name</p>
              <p className={"text-white font21"}>
                {contactsExportData?.fileName}
              </p>
            </div>
          </div>
          <WhiteButton
            text="Download"
            onClick={downloadImages}
            icon={ImportIcon}
            customStyles={"custom-studios-btn"}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactExports;
