import { createSlice } from "@reduxjs/toolkit";

export type Studio = {
  createdDate: string;
  creditsPurchased: number;
  creditsUsed: number;
  id: number;
  name: string;
  publicURL: string;
  startDate: string;
  studioType: 1 | 2;
  timeStampBase64: string;
  photosLatestSessions: { url: string }[];
};

type Period = {
  id: number;
  name: string;
};

type PoseExample = {
  id: number;
  url: string;
};

export type PoseProfile = {
  id: number;
  name: string;
  url: string;
  studioPoseProfileExamples: PoseExample[];
};

export type BackgroundOption = {
  Id: number;
  Name: string;
};

type StudiosState = {
  activeStudioDetailsId: number;
  studiosList: Studio[];
  studioReminderPeriods: Period[];
  studioPoseProfiles: PoseProfile[];
  studioBackgroundOptions: BackgroundOption[];
  studioNames: { id: number; name: string }[];
  studioPeriods: { id: number; name: string }[];
};

const initialState: StudiosState = {
  studiosList: [],
  activeStudioDetailsId: 0,
  studioReminderPeriods: [],
  studioPoseProfiles: [],
  studioBackgroundOptions: [],
  studioNames: [],
  studioPeriods: [],
};

export const studiosSlice = createSlice({
  name: "studios",
  initialState,
  reducers: {
    updateStudioList: (state, action) => {
      state.studiosList = [...action.payload];
    },
    setActiveStudioDetailsId: (state, action) => {
      state.activeStudioDetailsId = action.payload;
    },
    setStudioReminderPeriods: (state, action) => {
      state.studioReminderPeriods = [...action.payload];
    },
    setStudioPoseProfiles: (state, action) => {
      state.studioPoseProfiles = [...action.payload];
    },
    setStudioBackgroundOptions: (state, action) => {
      state.studioBackgroundOptions = [...action.payload];
    },
    setStudioNames: (state, action) => {
      state.studioNames = [{ id: 0, name: "All studios" }, ...action.payload];
    },
    setStudioPeriods: (state, action) => {
      state.studioPeriods = [...action.payload];
    },
    clearStudiosList: () => {
      return initialState;
    },
  },
});

export const {
  updateStudioList,
  clearStudiosList,
  setActiveStudioDetailsId,
  setStudioReminderPeriods,
  setStudioPoseProfiles,
  setStudioBackgroundOptions,
  setStudioNames,
  setStudioPeriods,
} = studiosSlice.actions;

export default studiosSlice.reducer;
