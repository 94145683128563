import { createSlice } from "@reduxjs/toolkit";

export type GalleryItem = {
  id: number;
  state: number;
  url: string;
};

type GalleryState = {
  galleryData: GalleryItem[];
};

const initialState: GalleryState = {
  galleryData: [],
};

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setGalleryData: (state, action) => {
      state.galleryData = [...action.payload];
    },
    clearGalleryState: () => {
      return initialState;
    },
  },
});

export const { setGalleryData, clearGalleryState } = gallerySlice.actions;

export default gallerySlice.reducer;
