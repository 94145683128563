import React, { FC } from "react";
import styles from "./WhiteButton.module.scss";
import classNames from "classnames";

type WhiteButtonProps = {
  text: string;
  icon?: string;
  customStyles?: string;
  onClick?: () => void;
  isDisabled?: boolean;
};
const WhiteButton: FC<WhiteButtonProps> = ({
  text,
  icon,
  customStyles = "",
  onClick,
  isDisabled,
}) => {
  return (
    <button
      className={classNames(styles.btn, customStyles)}
      onClick={onClick}
      disabled={isDisabled}
    >
      {icon && <img src={icon} alt="" />}
      {text}
    </button>
  );
};

export default WhiteButton;
