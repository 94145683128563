import React, { useEffect, useState } from "react";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import { MenuItem, Paper, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import AutoHeightGrid from "components/DataGrid/DataGrid";
import ActionsModal from "components/modals/ActionsModal/ActionsModal";
import DefaultInput from "components/fields/DefaultInput/DefaultInput";
import { useAppDispatch, useAppSelector } from "store";
import http from "helpers/http";
import {
  deleteContactsList,
  setContactsExportData,
  updateAllContacts,
  updateContactsList,
  updateContactsLists,
  updateListData,
} from "store/slices/contacts";
import { isValidEmail } from "utils/validatiors";
import { hiddenLoader, showLoader } from "store/slices/loader";
import { styled } from "@mui/material/styles";
import { setActiveContactsActiveListId } from "store/slices/tabs";
import IconButton from "@mui/material/IconButton";
import { Delete, Edit } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MoreIcon from "assets/svg/more-icon.svg";
import validator from "validator";
import ImportIcon from "assets/svg/import3.svg";
import ExportIcon from "assets/svg/export2.svg";
import LongDropDownElement from "../../../Submissions/components/LongDropDownElement/LongDropDownElement";
import { useNavigate } from "react-router-dom";
import NotificationModal from "components/modals/NotificationModal/NotificationModal";
import { setStudioNames } from "store/slices/studios";

const CustomTabs = styled(Tabs)(() => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "rgba(240, 240, 240, 1)",
    height: "1px",
  },
  "& .MuiButtonBase-root": {
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
}));

const CustomTab = styled(Tab)(() => ({
  color: "white",
  fontWeight: "bold",
  "&.Mui-selected": {},
  "&.MuiButtonBase-root": {
    color: "rgba(240, 240, 240, 1)",
    fontSize: "16px",
    fontFamily: "MatterMedium",
  },
}));

const ListsComponent = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const { contactsLists, allContacts } = useAppSelector(
    (state) => state.contacts,
  );
  const { contactsActiveListId } = useAppSelector((state) => state.tabs);

  const [isAddContactModalOpen, toggleAddContactModal] = useState(false);
  const [isAddListModalOpen, toggleAddListModal] = useState(false);
  const [isExportModalOpen, toggleExportModal] = useState(false);
  const [isEditListModalOpen, toggleEditListModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState("");
  const [checkedLists, setCheckedLists] = useState<number[]>([]);
  const [phoneError, setPhoneError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isNotificationModalOpen, toggleNotificationModal] = useState(false);

  const [listName, setListName] = useState("");
  const [listNameError, setListNameError] = useState("");
  const handleChangeFirstName = (val: string) => {
    setFirstName(val);
  };

  const { studioNames } = useAppSelector((state) => state.studios);
  const { outputs } = useAppSelector((state) => state.outputs);

  const [selectedExportStudio, setSelectedExportStudio] = useState<null | {
    id: number | null;
    name: string;
  }>(studioNames[1]);

  const [selectedOutput, setSelectedOutput] = useState<any>(outputs[0]);

  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onOpenDeleteListModal = () => {
    handleMenuClose();
    toggleDeleteModal(true);
  };

  // const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { checked, value } = event.target;
  //   if (checked) {
  //     setCheckedLists([...checkedLists, +value]);
  //   } else {
  //     setCheckedLists(checkedLists.filter((item) => item !== +value));
  //   }
  // };

  const fetchStudioNames = async () => {
    try {
      const res: any = await http.get("/studio/names");
      if (res.success) {
        dispatch(setStudioNames(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCurrentListData = async () => {
    try {
      const listId = contactsLists[contactsActiveListId];
      const res: any =
        (+listId.id as number) === 0
          ? await http.get("/contact/getAll", {
              params: {
                PageSize: 100,
                PageNumber: 1,
              },
            })
          : await http.get(`/contact/getContactsByContactListId/${listId.id}`);
      (+listId.id as number) === 0 &&
        dispatch(updateAllContacts(res.data.items));
      dispatch(updateListData({ id: listId.id, contacts: res.data.items }));
    } catch (e) {
      console.log(e);
    }
  };

  const onDeleteList = async () => {
    try {
      dispatch(showLoader());
      if (contactsActiveListId !== 0) {
        await http.delete(
          `/contactList/delete/${contactsLists[contactsActiveListId].id}`,
        );
      }
      dispatch(deleteContactsList(contactsActiveListId));
      dispatch(setActiveContactsActiveListId(0));
      dispatch(hiddenLoader());
      toggleDeleteModal(false);
    } catch (e) {
      console.log(e);
      dispatch(hiddenLoader());
    }
  };

  useEffect(() => {
    fetchStudioNames().catch();
    fetchCurrentListData().catch();
  }, [contactsActiveListId]);

  const handleChangeLastName = (val: string) => {
    setLastName(val);
  };

  const handleChangeEmail = (val: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmail(val);
  };
  const handleChangePhone = (val: string) => {
    setPhone(val);
    if (!val.length) {
      setPhoneError("");
    } else if (validator.isMobilePhone(val)) {
      setPhoneError("");
    } else {
      setPhoneError("Enter your valid Phone Number!");
    }
  };

  const handleChange = (newValue: number) => {
    dispatch(setActiveContactsActiveListId(newValue));
  };
  const onCloseExport = () => toggleExportModal(false);
  const onCloseAddContact = () => {
    setPhone("");
    setEmail("");
    setFirstName("");
    setLastName("");
    setEmailError("");
    setCheckedLists([]);
    toggleAddContactModal(false);
  };
  const onAddNewContact = async () => {
    if (email) {
      if (isValidEmail(email)) {
        dispatch(showLoader());
        try {
          await http.post("/contact/create", {
            firstName: firstName ? firstName : null,
            lastName: lastName ? lastName : null,
            email,
            phone,
            contactListIds: checkedLists.length ? checkedLists : null,
          });
          const res: any = await http.get("/contact/getAll", {
            params: {
              PageSize: 100,
              PageNumber: 1,
            },
          });
          dispatch(updateAllContacts(res?.data?.items));
          onCloseAddContact();
          dispatch(hiddenLoader());
        } catch (e: any) {
          if (e?.responseStatus?.code === 1034) {
            setEmailError("Email is already taken");
          }
          dispatch(hiddenLoader());
        }
      } else {
        setEmailError("Email is not valid");
      }
    } else {
      setEmailError("Email is required");
    }
  };

  const onChangeListName = (val: string) => {
    if (listNameError) {
      setListNameError("");
    }
    setListName(val);
  };

  const onCloseAddList = () => {
    setListName("");
    setListNameError("");
    toggleAddListModal(false);
  };

  const onCloseEditList = () => {
    setListName("");
    setListNameError("");
    toggleEditListModal(false);
  };

  const onOpenRenameList = () => {
    handleMenuClose();
    setListName(contactsLists[contactsActiveListId].name);
    toggleEditListModal(true);
  };
  const onExport = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.post("/contact/export", {
        isIncludePhotoURL: isChecked,
        studioId: selectedExportStudio?.id,
        outputPresetId: selectedOutput.id,
      });
      if (res.success) {
        dispatch(setContactsExportData(res.data));
        dispatch(hiddenLoader());
        onCloseExport();
        navigation("/contact-exports");
      }
      dispatch(hiddenLoader());
      console.log("res", res);
    } catch (e) {
      console.log(e);
      dispatch(hiddenLoader());
    }
  };

  const onAddNewList = async () => {
    if (listName) {
      try {
        dispatch(showLoader());
        await http.post("/contactList/create", {
          name: listName,
        });
        const res: any = await http.get("/contactList/getAll", {
          params: { PageSize: 100, PageNumber: 1 },
        });
        dispatch(updateContactsLists(res.data.items));
        dispatch(hiddenLoader());
        onCloseAddList();
      } catch (e) {
        dispatch(hiddenLoader());

        console.log(e);
      }
    } else {
      setListNameError("List name is required");
    }
  };

  const onEditListName = async () => {
    dispatch(showLoader());
    try {
      await http.put("/contactList/rename", {
        id: contactsLists[contactsActiveListId].id,
        name: listName,
      });
      dispatch(
        updateContactsList({
          id: contactsLists[contactsActiveListId].id,
          name: listName,
        }),
      );
      dispatch(hiddenLoader());
      toggleEditListModal(false);
      setListName("");
    } catch (e) {
      console.log(e);
      dispatch(hiddenLoader());
    }
  };

  const onImportContacts = async (event: any) => {
    if (event.target.files[0]) {
      try {
        dispatch(showLoader());
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        const res: any = await http.post("/contact/import", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        dispatch(hiddenLoader());
        if (!res.success) {
          toggleNotificationModal(true);
        } else {
          fetchCurrentListData().catch();
        }
      } catch (e) {
        console.log(e);
        dispatch(hiddenLoader());
        toggleNotificationModal(true);
      }
    }
  };

  return (
    <div className="full-height full-width">
      <div className="flex justify-space-between a-items-c">
        <h2>Contact Lists</h2>
        <div className="flex gap8">
          <WhiteButton
            text="+ Add list"
            onClick={() => toggleAddListModal(true)}
            customStyles={"black-btn custom-studios-btn"}
          />
          <WhiteButton
            text="Add Contact"
            customStyles={"custom-studios-btn"}
            onClick={() => toggleAddContactModal(true)}
          />
        </div>
      </div>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "rgba(17, 17, 17, 1)",
          border: "1px solid rgba(255, 255, 255, 0.16)",
          borderRadius: "24px",
          marginTop: "40px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <CustomTabs
            value={contactsActiveListId}
            onChange={(e, value) => handleChange(value)}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            {contactsLists.map((item) => (
              <CustomTab label={item.name} key={item.id} />
            ))}
          </CustomTabs>
          <div
            className="padding-h24 mt-20 flex justify-space-between a-items-c"
            style={{ height: 30 }}
          >
            <p className="light-text">
              Showing{" "}
              {contactsLists[contactsActiveListId].contacts
                ? contactsLists[contactsActiveListId].contacts.length
                : allContacts.length}{" "}
              of{" "}
              {contactsLists[contactsActiveListId].contacts
                ? contactsLists[contactsActiveListId].contacts.length
                : allContacts.length}
            </p>
            {contactsActiveListId === 0 && (
              <div className={"flex gap12"}>
                <label className={"small-btn2"} htmlFor={"formId"}>
                  <img src={ImportIcon} alt="" />
                  <p className="gray3-text font12">Import</p>
                </label>
                <input
                  id="formId"
                  type="file"
                  accept=".csv"
                  name="myImage"
                  style={{ display: "none" }}
                  onChange={onImportContacts}
                />
                <div
                  className={"small-btn2"}
                  onClick={() => toggleExportModal(true)}
                >
                  <img src={ExportIcon} alt={""} />
                  <p className="gray3-text font12">Export</p>
                </div>
              </div>
            )}
            {contactsActiveListId !== 0 && (
              <div>
                <IconButton
                  aria-label="more actions"
                  edge="end"
                  color="info"
                  onClick={handleMenuOpen}
                >
                  <img src={MoreIcon} alt="" />
                </IconButton>
                <Menu
                  id="menu-basic"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: {
                      // Style the entire MenuList container
                      background: "rgba(30, 30, 30, 1)",
                      borderRadius: "16px",
                      borderWidth: 0,
                      color: "rgba(152, 152, 152, 1)",
                      fontSize: 12,
                      fontFamily: "MatterRegular",
                    },
                  }}
                  PaperProps={{ sx: { background: "inherit" } }}
                >
                  <MenuItem onClick={onOpenRenameList}>
                    <Edit
                      sx={{
                        mr: 1.5,
                        color: "rgba(152, 152, 152, 1)",
                      }}
                    />
                    Rename list
                  </MenuItem>
                  <MenuItem onClick={onOpenDeleteListModal}>
                    <Delete
                      sx={{
                        mr: 1.5,
                        color: "rgba(152, 152, 152, 1)",
                      }}
                    />
                    Delete list
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
          <AutoHeightGrid />
        </Box>
      </Paper>
      <ActionsModal
        successActionText="Add new contact"
        title="Add New Contact"
        isOpen={isAddContactModalOpen}
        onClose={onCloseAddContact}
        successAction={onAddNewContact}
      >
        <div className="flex  mt-4 justify-space-between gap20">
          <div className="flex flex-column gap16 full-width">
            <DefaultInput
              label="First Name"
              goldLabel
              placeholder="Enter first name"
              onChange={handleChangeFirstName}
              inputVal={firstName}
              maxLength={100}
            />
            <DefaultInput
              label="Last Name"
              goldLabel
              placeholder="Enter last name"
              onChange={handleChangeLastName}
              inputVal={lastName}
              maxLength={100}
            />
            <DefaultInput
              label="Email"
              goldLabel
              placeholder="Enter email"
              onChange={handleChangeEmail}
              inputVal={email}
              error={emailError}
              maxLength={256}
            />
            <DefaultInput
              label="Phone"
              goldLabel
              placeholder="Enter phone number"
              onChange={handleChangePhone}
              inputVal={phone}
              maxLength={20}
              error={phoneError}
            />
          </div>
          {/*{contactsLists.length && (*/}
          {/*  <div style={{ width: "30%" }} className="overflow-hidden">*/}
          {/*    <p className="font14 light-text mb-10">Contact lists</p>*/}
          {/*    <div*/}
          {/*      className="flex flex-column a-items-start full-height flex-start"*/}
          {/*      style={{*/}
          {/*        height: 316,*/}
          {/*        overflow: "scroll",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {contactsLists*/}
          {/*        .filter((item) => item.id !== 0)*/}
          {/*        .map((item) => {*/}
          {/*          return (*/}
          {/*            <div key={item.id} className="flex a-items-c gap8">*/}
          {/*              <input*/}
          {/*                type="checkbox"*/}
          {/*                id={`checkbox-${item.id}`}*/}
          {/*                value={item.id}*/}
          {/*                checked={checkedLists.includes(item.id)}*/}
          {/*                onChange={handleCheckboxChange}*/}
          {/*              />*/}
          {/*              <label*/}
          {/*                htmlFor={`checkbox-${item.id}`}*/}
          {/*                className="font16 light-text text-nowrap overflow-ellipsis"*/}
          {/*              >*/}
          {/*                {item.name}*/}
          {/*              </label>*/}
          {/*            </div>*/}
          {/*          );*/}
          {/*        })}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </ActionsModal>
      <ActionsModal
        successActionText="Create List"
        title="New List"
        isOpen={isAddListModalOpen}
        onClose={onCloseAddList}
        successAction={onAddNewList}
      >
        <div className="mt-4">
          <DefaultInput
            placeholder="Enter list name"
            onChange={onChangeListName}
            inputVal={listName}
            maxLength={100}
            error={listNameError}
          />
        </div>
      </ActionsModal>
      <ActionsModal
        isOpen={isDeleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        successAction={onDeleteList}
        title={"Delete list"}
        successActionText={"Delete"}
        isWarning
      >
        <p className="text-white mt10">
          Are you sure you want to delete this contact list? The associated
          contacts will not be deleted.
        </p>
      </ActionsModal>
      <ActionsModal
        successActionText="Rename list"
        title="Rename List"
        isOpen={isEditListModalOpen}
        onClose={onCloseEditList}
        successAction={onEditListName}
      >
        <div className="mt-4">
          <DefaultInput
            placeholder="Enter list name"
            onChange={onChangeListName}
            inputVal={listName}
            maxLength={100}
            error={listNameError}
          />
        </div>
      </ActionsModal>
      <ActionsModal
        successActionText="Export"
        title="Export contacts"
        isOpen={isExportModalOpen}
        onClose={onCloseExport}
        successAction={onExport}
      >
        <div className="mt-4 flex flex-column a-items-start">
          <p className="text-white">All contacts will be exported</p>
          <div className="flex a-items-c gap8 mt16">
            <input
              type="checkbox"
              id={`checkbox-1`}
              checked={isChecked}
              onChange={() => setIsChecked((prev) => !prev)}
            />
            <label
              htmlFor={`checkbox-1`}
              className="font16 light-text text-nowrap overflow-ellipsis cursor-pointer"
            >
              Include photo URL in export
            </label>
          </div>
          {isChecked && (
            <div className={"full-width"}>
              <div className="mt-4">
                <div className="flex a-items-start gap8 flex-column flex-start">
                  <p className="text-white font12">
                    Use photo submitted to studio
                  </p>
                  <LongDropDownElement
                    selectedElement={selectedExportStudio}
                    setSelectedElement={setSelectedExportStudio}
                    title={"All studios"}
                    data={studioNames.slice(1)}
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex a-items-start gap8 flex-column flex-start">
                  <p className="text-white font12">With output</p>
                  <LongDropDownElement
                    selectedElement={selectedOutput}
                    setSelectedElement={setSelectedOutput}
                    title={"All outputs"}
                    data={outputs}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </ActionsModal>
      <NotificationModal
        onClose={() => toggleNotificationModal(false)}
        isOpen={isNotificationModalOpen}
        text={"An error occurred during document processing"}
      />
    </div>
  );
};

export default ListsComponent;
