import React from "react";

const ResourcesIcon = ({ stroke }: { stroke: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33325 6.66659H4.66659C5.99992 6.66659 6.66659 5.99992 6.66659 4.66659V3.33325C6.66659 1.99992 5.99992 1.33325 4.66659 1.33325H3.33325C1.99992 1.33325 1.33325 1.99992 1.33325 3.33325V4.66659C1.33325 5.99992 1.99992 6.66659 3.33325 6.66659Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 6.66659H12.6666C13.9999 6.66659 14.6666 5.99992 14.6666 4.66659V3.33325C14.6666 1.99992 13.9999 1.33325 12.6666 1.33325H11.3333C9.99992 1.33325 9.33325 1.99992 9.33325 3.33325V4.66659C9.33325 5.99992 9.99992 6.66659 11.3333 6.66659Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 14.6666H12.6666C13.9999 14.6666 14.6666 13.9999 14.6666 12.6666V11.3333C14.6666 9.99992 13.9999 9.33325 12.6666 9.33325H11.3333C9.99992 9.33325 9.33325 9.99992 9.33325 11.3333V12.6666C9.33325 13.9999 9.99992 14.6666 11.3333 14.6666Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33325 14.6666H4.66659C5.99992 14.6666 6.66659 13.9999 6.66659 12.6666V11.3333C6.66659 9.99992 5.99992 9.33325 4.66659 9.33325H3.33325C1.99992 9.33325 1.33325 9.99992 1.33325 11.3333V12.6666C1.33325 13.9999 1.99992 14.6666 3.33325 14.6666Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ResourcesIcon;
