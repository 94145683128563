import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { contactsTabName } from "constants/tabs";
import { setActiveContactsActiveTab } from "store/slices/tabs";
import BreadcrumbsComponent from "components/BreadcrumbsComponent/BreadcrumbsComponent";
import "./styles.scss";
import http from "helpers/http";
import { setActiveContactDetailsData } from "store/slices/contacts";
import { hiddenLoader, showLoader } from "store/slices/loader";
import moment from "moment/moment";
import styles from "./ContactDetails.module.scss";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import EditContactDetails from "../EditContactDetails/EditContactDetails";
import SubmissionCard from "./components/SubmissionCard/SubmissionCard";

const ContactDetails = () => {
  const dispatch = useAppDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const { activeContactDetailsId, activeContactDetailsData } = useAppSelector(
    (state) => state.contacts,
  );

  const fetchContactData = async () => {
    dispatch(showLoader());
    const res: any = await http.get(
      `/contact/getContactDetailsById/${activeContactDetailsId}`,
    );
    if (res.success) {
      dispatch(setActiveContactDetailsData(res.data));
    }
    dispatch(hiddenLoader());
  };

  useEffect(() => {
    fetchContactData();
  }, [isEditMode]);

  const onClickEdit = () => {
    setIsEditMode(true);
  };

  const breadcrumbs = [
    <div
      key="1"
      onClick={() => dispatch(setActiveContactsActiveTab(contactsTabName.list))}
      className="text-white cursor-pointer breadcrumbs-element"
    >
      Contacts
    </div>,
    <div
      key="2"
      className="text-white cursor-pointer breadcrumbs-element text-nowrap text-ellipsis overflow-hidden"
      style={{ maxWidth: 300 }}
      onClick={() => isEditMode && setIsEditMode(false)}
    >
      {!!activeContactDetailsData?.firstName ||
      !!activeContactDetailsData?.lastName
        ? `${activeContactDetailsData.firstName || ""} ${
            activeContactDetailsData.lastName || ""
          }`
        : activeContactDetailsData?.email}
    </div>,
    isEditMode && (
      <div key="2" className="text-white cursor-pointer breadcrumbs-element">
        Edit
      </div>
    ),
  ];

  return (
    <div className="full-height full-width overflow-scroll">
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
      {isEditMode ? (
        <EditContactDetails setIsEditMode={setIsEditMode} />
      ) : (
        <>
          <div className="flex flex-column flex-start a-items-start mt-4">
            {(!!activeContactDetailsData?.firstName ||
              !!activeContactDetailsData?.lastName) && (
              <h2>
                {activeContactDetailsData.firstName || ""}
                {activeContactDetailsData.lastName || ""}
              </h2>
            )}
            {activeContactDetailsData?.createdDate && (
              <p
                className="gray2-text
          "
              >
                Contact since{" "}
                {moment(activeContactDetailsData.createdDate).format(
                  "MMMM DD, yyyy",
                )}
              </p>
            )}
          </div>
          <div className={styles.grayContainer}>
            <div className="flex justify-space-between full-width a-items-c">
              <p className="text-white t-align-left">Contact information</p>
              <WhiteButton
                text={"Edit"}
                customStyles="custom-studios-btn"
                onClick={onClickEdit}
              />
            </div>
            <div className="gray-horizontal-line full-width mt10" />
            <div className="mt-4 flex justify-space-between full-width">
              <div className="text-white flex flex-column gap8">
                <p className="font10 gray2-text">First name</p>
                <p
                  className="font14  text-nowrap text-ellipsis overflow-hidden"
                  style={{ maxWidth: 185 }}
                >
                  {activeContactDetailsData?.firstName
                    ? activeContactDetailsData?.firstName
                    : "Not indicated"}
                </p>
              </div>
              <div className="text-white flex flex-column gap8">
                <p className="font10 gray2-text">Last name</p>
                <p
                  className="font14 text-nowrap text-ellipsis overflow-hidden"
                  style={{ maxWidth: 185 }}
                >
                  {activeContactDetailsData?.lastName
                    ? activeContactDetailsData?.lastName
                    : "Not indicated"}
                </p>
              </div>
              <div className="text-white flex flex-column gap8">
                <p className="font10 gray2-text">Email address</p>
                <p
                  className="font14 text-nowrap text-ellipsis overflow-hidden"
                  style={{ maxWidth: 185 }}
                >
                  {activeContactDetailsData?.email
                    ? activeContactDetailsData?.email
                    : "Not indicated"}
                </p>
              </div>
              <div className="text-white flex flex-column gap8">
                <p className="font10 gray2-text">Phone number</p>
                <p className="font14">
                  {activeContactDetailsData?.phone
                    ? activeContactDetailsData?.phone
                    : "Not indicated"}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.grayContainer}>
            <div className="flex justify-space-between full-width a-items-c">
              <p className="text-white t-align-left">Photo submissions</p>
            </div>
            <div className="mt10 t-align-center full-width flex flex-column gap24">
              {activeContactDetailsData?.sessions?.length ? (
                activeContactDetailsData.sessions.map((item) => (
                  <SubmissionCard key={item.sessionId} session={item} />
                ))
              ) : (
                <>
                  <div className="gray-horizontal-line full-width mt10" />
                  <p className="text-white">
                    This contact has no photo submissions
                  </p>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactDetails;
