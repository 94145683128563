import React, { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuth } from "../helpers/localStorageHelpers";

type ProtectedRouteProps = {
  redirectTo: string;
  children: any;
};
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  redirectTo = "/login",
  children,
}) => {
  if (!isAuth()) {
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
