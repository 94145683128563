import React, { FC, useState } from "react";
import styles from "./StudioCard.module.scss";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import CopyLink from "assets/svg/copy-link.svg";
// import CopyIcon from "assets/svg/copy.svg";
import {
  setActiveStudioDetailsId,
  Studio,
  updateStudioList,
} from "store/slices/studios";
import moment from "moment/moment";
import classNames from "classnames";
import GlobalIcon from "assets/svg/global-white.svg";
import GpsSlashIcon from "assets/svg/gps-slash.svg";
import { useAppDispatch } from "store";
import { setActiveStudiosTab } from "store/slices/tabs";
import { studiosTabName } from "constants/tabs";
import HappyIcon from "../../../../../../assets/svg/happy-svgrepo-com.svg";
import ArrowDownIcon from "../../../../../../assets/svg/arrow-down.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CopyIcon from "../../../../../../assets/svg/copy.svg";
import { copyToClipboard } from "../../../../../../utils/utils";
import PictureIcon from "../../../../../../assets/svg/picture-icon.svg";
import {
  hiddenLoader,
  showLoader,
} from "../../../../../../store/slices/loader";
import http from "../../../../../../helpers/http";

type StudioCardProps = {
  studio: Studio;
  onClickInvite: (val: number) => void;
};

const StudioCard: FC<StudioCardProps> = ({ studio, onClickInvite }) => {
  const { id, name, creditsPurchased, creditsUsed, startDate, studioType } =
    studio;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const onClickManageStudio = () => {
    dispatch(setActiveStudioDetailsId(id));
    dispatch(setActiveStudiosTab(studiosTabName.edit));
  };

  const changeStudioType = async ({
    studioId,
    studioType,
  }: {
    studioId: number;
    studioType: number;
  }) => {
    try {
      dispatch(showLoader());
      const res: any = await http.put("/studio/change-type", {
        studioId,
        studioType,
      });
      if (res.success) {
        const resGetList: any = await http.get("/Studio/getAll", {
          params: {
            PageSize: 100,
            PageNumber: 1,
          },
        });
        dispatch(updateStudioList(resGetList.data.items));
        handleMenuClose();
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  return (
    <div className={styles.studioContainer}>
      <div className={styles.imagesContainer}>
        {/*<img src={require("assets/images/examples/Photos.png")} alt="" />*/}
        <div className={styles.headshotsContainer}>
          {studio.photosLatestSessions?.length ? (
            <div className="full-height full-width flex gap8 flex-start">
              <div className={styles.bigImage}>
                <img src={studio.photosLatestSessions[0].url} alt={""} />
              </div>
              <div className="flex flex-wrap gap8 flex-start">
                {studio.photosLatestSessions.slice(1, 7).map((image, index) => {
                  return (
                    <div key={index} className={styles.smallImage}>
                      <img src={image.url} alt={""} />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="flex a-items-c gap8">
              <h6>This studio does not have headshots</h6>
              <img src={HappyIcon} alt="" width={40} height={40} />
            </div>
          )}
        </div>
        <div className="flex justify-space-between a-items-c mt10 full-width">
          <div
            className="flex flex-column justify-space-between a-items-start"
            style={{ height: 44, width: "60%" }}
          >
            <h6 className={"font21 overflow-hidden text-ellipsis full-width"}>
              {name}
            </h6>
            <div className="flex a-items-c gap8">
              <div
                className={classNames(styles.statusCircle, {
                  ["bg-color-green"]: creditsPurchased - creditsUsed > 0,
                  ["bg-color-orange"]: creditsPurchased - creditsUsed === 0,
                })}
              />{" "}
              <div className="flex gap8 a-items-c">
                <p className="font12 gray3-text">
                  Started on {moment(startDate).format("MMM D, YYYY")}
                </p>
                <div className={styles.verticalLine} />
                <p className="font12 gray3-text">
                  {creditsPurchased - creditsUsed > 0
                    ? "No end date"
                    : "On hold"}
                </p>
              </div>
            </div>
          </div>
          <div className={"flex gap8"}>
            <WhiteButton
              text="Invite"
              customStyles={"custom-studios-btn"}
              onClick={() => onClickInvite(id)}
            />
            <WhiteButton
              text="Manage"
              customStyles={"custom-studios-btn"}
              onClick={onClickManageStudio}
            />
          </div>
        </div>
        <div className="flex height40 a-items-c mt-20 gap4">
          <div
            className={classNames(
              styles.studioTypeContainer,
              "flex gap8 a-items-c",
            )}
          >
            <div className={"flex a-items-c flex-start gap8"}>
              <div
                onClick={handleMenuOpen}
                className={"flex a-items-c cursor-pointer"}
                style={{
                  background: "rgba(26, 26, 26, 1)",
                  height: 40,
                  paddingLeft: 8,
                  paddingRight: 8,
                  borderRadius: 8,
                }}
              >
                <img
                  src={studio.studioType === 2 ? GlobalIcon : GpsSlashIcon}
                  alt=""
                  width={24}
                  height={24}
                />
                <span
                  style={{
                    width: 100,
                    marginLeft: 10,
                    color: "rgba(152, 152, 152, 1)",
                    fontFamily: "MatterRegular",
                  }}
                >
                  {studioType === 2 ? "Public" : "Private"}
                </span>
                <img src={ArrowDownIcon} alt="" width={24} height={24} />
              </div>
              <Menu
                id="menu-basic"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: {
                    background: "rgba(30, 30, 30, 1)",
                    borderRadius: "16px",
                    borderWidth: 0,
                    color: "rgba(152, 152, 152, 1)",
                    fontSize: 12,
                    fontFamily: "MatterRegular",
                  },
                }}
                PaperProps={{ sx: { background: "inherit" } }}
              >
                <MenuItem
                  onClick={() =>
                    changeStudioType({
                      studioId: studio.id,
                      studioType: 2,
                    })
                  }
                >
                  Public
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    changeStudioType({
                      studioId: studio.id,
                      studioType: 1,
                    })
                  }
                >
                  Private
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="full-width border-light full-height border-radius8 flex a-items-c justify-space-between padding8">
            <div className={classNames("flex a-items-c gap8 select-none")}>
              <img src={CopyLink} alt="" width={20} />
              <span className={styles.overflowLink}>{studio.publicURL}</span>
            </div>
            <div className="flex a-items-c gap8">
              <div
                style={{ width: 24, height: 24 }}
                className="border-light border-radius4 flex a-items-c cursor-pointer"
              >
                <img
                  src={CopyIcon}
                  alt=""
                  onClick={() => copyToClipboard(studio.publicURL)}
                />
              </div>
              <a
                style={{ width: 24, height: 24 }}
                target="_blank"
                href={`https://www.thestudiogo.io/create-invite?studio=${studio.id}`} //prod
                // href={`https://dev.dmbdmi6zm03os.amplifyapp.com/create-invite?studio=${studio.id}`} //dev
                rel="noreferrer"
                className="border-light border-radius4 flex a-items-c cursor-pointer"
              >
                <img src={PictureIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt40 flex justify-space-between">
          <div className="flex flex-column gap8" style={{ width: "30%" }}>
            <p className="font11 gray3-text">Credits Used</p>
            <p className="font24 light-text">{creditsUsed}</p>
          </div>
          <div className="flex flex-column gap8" style={{ width: "30%" }}>
            <p className="font11 gray3-text">Credits Purchased</p>
            <p className="font24 light-text">{creditsPurchased}</p>
          </div>
          <div className="flex flex-column gap8" style={{ width: "30%" }}>
            <p className="font11 gray3-text">Remaining Credits</p>
            <p
              className={classNames("font24 light-text", {
                ["green-text"]: creditsPurchased - creditsUsed > 0,
                ["orange-text"]: creditsPurchased - creditsUsed === 0,
              })}
            >
              {creditsPurchased - creditsUsed}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudioCard;
