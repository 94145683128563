import React from "react";
import animation from "./Animation.json";
import Lottie from "lottie-react";

const AppLoader = () => (
  <div
    className="position-absolute full-width full-height flex a-items-c"
    style={{ backgroundColor: "#0d0b0bcc", zIndex: 99999 }}
  >
    <Lottie
      animationData={animation}
      loop
      style={{
        width: "40%",
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
      }}
    />
  </div>
);

export default AppLoader;
