import React, { useLayoutEffect } from "react";
import { Paper } from "@mui/material";
import styles from "./ListComponent.module.scss";
import VisibleIcon from "assets/svg/gallery-visible.svg";
import NonVisibleIcon from "assets/svg/gallery-slash.svg";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "store";
import { setActiveOutputsActiveTab } from "store/slices/tabs";
import { outputsTabName } from "constants/tabs";
import { setActiveOutputId, setOutputs } from "store/slices/outputs";
import http from "helpers/http";
import { hiddenLoader, showLoader } from "store/slices/loader";
const ListsComponent = () => {
  const dispatch = useAppDispatch();
  const { outputs, outputsOptions } = useAppSelector((state) => state.outputs);

  const fetchOutputs = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/Output/getAll", {
        params: { PageSize: 100, PageNumber: 1 },
      });
      if (res.success) {
        dispatch(setOutputs(res.data.items));
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  useLayoutEffect(() => {
    fetchOutputs().catch();
  }, []);

  const openOutputEdit = (id: number) => {
    dispatch(setActiveOutputId(id));
    dispatch(setActiveOutputsActiveTab(outputsTabName.edit));
  };

  return (
    <div className="full-height full-width overflow-scroll">
      <div className="flex justify-space-between a-items-c">
        <h2>Outputs</h2>
      </div>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "rgba(17, 17, 17, 1)",
          border: "1px solid rgba(255, 255, 255, 0.16)",
          borderRadius: "24px",
          marginTop: "40px",
          paddingLeft: "24px",
          paddingTop: "24px",
          paddingBottom: "24px",
          paddingRight: "24px",
        }}
      >
        <div className="flex">
          <div
            className="gray4-text font12"
            style={{ width: "80px", paddingLeft: 16 }}
          >
            Sample
          </div>
          <div
            className="gray4-text font12"
            style={{ width: "55%", paddingLeft: 16 }}
          >
            Name
          </div>
          <div
            className="gray4-text font12"
            style={{ width: "20%", paddingLeft: 16 }}
          >
            Size
          </div>
          <div
            className="gray4-text font12"
            style={{ width: "15%", paddingLeft: 16 }}
          >
            Visibility
          </div>
        </div>
        <div className={"mt16 flex flex-column gap16"}>
          {outputsOptions &&
            outputs.map((item) => (
              <div
                className={classNames(styles.outputWrapper)}
                key={item.id}
                onClick={() => openOutputEdit(item.id)}
              >
                <div className={styles.imgContainer}>
                  <img src={item.outputSampleImageUrl} alt="" />
                </div>
                <div className={styles.nameContainer}>
                  <div style={{ color: "#F0F0F0" }} className={"font14"}>
                    {item.name}
                  </div>
                  <div className="flex font12 a-items-c gap4 flex-start">
                    <p className={"gray3-text weight400"}>
                      {
                        outputsOptions.photoFilters.find(
                          (filter) => filter.Id === item.outputPhotoFilter,
                        )?.Name
                      }
                    </p>
                    <div className={styles.verticalLine} />
                    <p className={"gray3-text weight400"}>
                      {
                        outputsOptions.cropFactors.find(
                          (cropFactor) =>
                            cropFactor.Id === item.outputCropFactor,
                        )?.Name
                      }
                    </p>
                    <div className={styles.verticalLine} />
                    <p className={"gray3-text weight400"}>
                      {
                        outputsOptions.imageFormats.find(
                          (format) => format.Id === item.outputImageFormat,
                        )?.Name
                      }
                    </p>
                  </div>
                </div>
                <div className={styles.sizeContainer}>
                  <p
                    style={{ color: "#747474" }}
                    className={"font14 weight400"}
                  >
                    {
                      outputsOptions.resizes.find(
                        (resize) => resize.Id === item.outputResize,
                      )?.Name
                    }
                  </p>
                </div>
                <div className={styles.visibilityContainer}>
                  <img
                    src={
                      item.isDisplayToContactInStudio
                        ? VisibleIcon
                        : NonVisibleIcon
                    }
                    alt=""
                  />
                </div>
              </div>
            ))}
        </div>
      </Paper>
    </div>
  );
};

export default ListsComponent;
