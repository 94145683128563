import React, { FC, ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Modal } from "@mui/material";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "32px",
  border: "2px solid #000",
  boxShadow: 24,
  bgcolor: "background.paper",
  p: 4,
};

type NotificationModalProps = {
  onClose: () => void;
  isOpen: boolean;
  text: string | ReactNode;
};

const NotificationModal: FC<NotificationModalProps> = ({
  onClose,
  isOpen,
  text,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {typeof text === "string" ? text : text}
        </Typography>
        <Button
          onClick={onClose}
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            textTransform: "none",
            borderRadius: "16px",
            height: "40px",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default NotificationModal;
