import React, { useLayoutEffect, useState } from "react";
import { Paper, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "store";
import { setActiveSubmissionsActiveListId } from "store/slices/tabs";
import InvitedDataGrid from "./components/InvitedDataGrid/InvitedDataGrid";
import { hiddenLoader, showLoader } from "store/slices/loader";
import http from "helpers/http";
import {
  setAllAcceptedData,
  setAllRejectedData,
  setAllReviewData,
  setAllSubmissionsData,
  setInvitedData,
} from "store/slices/submissions";
import AllSubmissionsDataGrid from "./components/AllSubmissionsDataGrid/AllSubmissionsDataGrid";
import { setStudioNames, setStudioPeriods } from "store/slices/studios";
import WhiteButton from "components/buttons/WhiteButton/WhiteButton";
import ExportIcon from "assets/svg/export2.svg";
import ActionsModal from "components/modals/ActionsModal/ActionsModal";
import LongDropDownElement from "./components/LongDropDownElement/LongDropDownElement";
import { imagesFormat, exportSubmissionStatus } from "./constants";
import { submissionStatus } from "constants/sybmissions";
import NotificationModal from "../../../../components/modals/NotificationModal/NotificationModal";

const CustomTabs = styled(Tabs)(() => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "rgba(240, 240, 240, 1)",
    height: "1px",
  },
  "& .MuiButtonBase-root": {
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
}));

const CustomTab = styled(Tab)(() => ({
  color: "white",
  fontWeight: "bold",
  "&.MuiButtonBase-root": {
    color: "rgba(116, 116, 116, 1)",
    fontSize: "14px",
    fontFamily: "MatterMedium",
    textTransform: "none",
  },
  "&.Mui-selected": {
    color: "rgba(240, 240, 240, 1)",
  },
}));

const Submissions = () => {
  const dispatch = useAppDispatch();
  const { submissionsLists } = useAppSelector((state) => state.submissions);
  const { submissionsActiveListId } = useAppSelector((state) => state.tabs);
  const [isExportModalOpen, toggleExportModal] = useState(false);
  const { studioNames } = useAppSelector((state) => state.studios);
  const [isNotificationModalOpen, toggleNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Current page index (0-based)
  const [pageSize, setPageSize] = useState(10); // Items per page

  const [selectedStudio, setSelectedStudio] = useState<null | {
    id: number;
    name: string;
  }>(null);

  const [selectedExportStudio, setSelectedExportStudio] = useState<null | {
    id: number | null;
    name: string;
  }>(null);

  const [selectedExportFormat, setSelectedExportFormat] = useState<null | {
    id: number | null;
    name: string;
  }>(null);

  const [selectedExportSubmissionStatus, setSelectedExportSubmissionStatus] =
    useState<null | {
      id: number | null;
      name: string;
    }>(null);

  const [selectedPeriod, setSelectedPeriod] = useState<null | {
    id: number;
    name: string;
  }>(null);

  const handleChangeList = (newValue: number) => {
    setPageIndex(0);
    setPageSize(10);
    setSelectedStudio(null);
    dispatch(setActiveSubmissionsActiveListId(newValue));
  };

  const fetchInvitedData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/invite/getAll", {
        params: {
          PageSize: pageSize,
          PageNumber: pageIndex + 1,
          StudioId: selectedStudio ? selectedStudio.id : 0,
          SubmittedAfter: selectedPeriod ? selectedPeriod.id : 1,
        },
      });
      if (res.success) {
        setTotalPages(res.data.metadata.totalPages);
        dispatch(setInvitedData(res.data.items));
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const fetchAllSubmissionsData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/session/getAllCompleted", {
        params: {
          PageSize: pageSize,
          PageNumber: pageIndex + 1,
          StudioId: selectedStudio ? selectedStudio.id : 0,
          SubmittedAfter: selectedPeriod ? selectedPeriod.id : 1,
        },
      });
      if (res.success) {
        dispatch(setAllSubmissionsData(res.data.items));
        setTotalPages(res.data.metadata.totalPages);
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const fetchStudioNames = async () => {
    try {
      const res: any = await http.get("/studio/names");
      if (res.success) {
        dispatch(setStudioNames(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAfterPeriods = async () => {
    try {
      const res: any = await http.get("/submission/submitted-after-periods");
      dispatch(setStudioPeriods(res));
    } catch (e) {
      console.log(e);
    }
  };

  const fetchReviewData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/session/getAllCompletedByState", {
        params: {
          state: 1,
          PageSize: pageSize,
          PageNumber: pageIndex + 1,
          StudioId: selectedStudio ? selectedStudio.id : 0,
          SubmittedAfter: selectedPeriod ? selectedPeriod.id : 1,
        },
      });
      if (res.success) {
        setTotalPages(res.data.metadata.totalPages);
        dispatch(setAllReviewData(res.data.items));
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const fetchRejectedData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/session/getAllCompletedByState", {
        params: {
          state: 3,
          PageSize: pageSize,
          PageNumber: pageIndex + 1,
          StudioId: selectedStudio ? selectedStudio.id : 0,
          SubmittedAfter: selectedPeriod ? selectedPeriod.id : 1,
        },
      });
      if (res.success) {
        setTotalPages(res.data.metadata.totalPages);
        dispatch(setAllRejectedData(res.data.items));
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const fetchAcceptedData = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.get("/session/getAllCompletedByState", {
        params: {
          state: 2,
          PageSize: pageSize,
          PageNumber: pageIndex + 1,
          StudioId: selectedStudio ? selectedStudio.id : 0,
          SubmittedAfter: selectedPeriod ? selectedPeriod.id : 1,
        },
      });
      if (res.success) {
        setTotalPages(res.data.metadata.totalPages);
        dispatch(setAllAcceptedData(res.data.items));
      }
      dispatch(hiddenLoader());
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  const closeNotificationModal = () => {
    toggleNotificationModal(false);
  };

  const onCloseExportModal = () => {
    setSelectedExportFormat(null);
    setSelectedExportSubmissionStatus(null);
    setSelectedExportStudio(null);
    toggleExportModal(false);
  };

  const onOpenExportModal = () => toggleExportModal(true);

  const onExport = async () => {
    try {
      dispatch(showLoader());
      const res: any = await http.post("/session/export-photos", {
        studioId: selectedExportStudio?.id ? selectedExportStudio.id : 0,
        state: selectedExportSubmissionStatus?.id
          ? selectedExportSubmissionStatus.id
          : null,
        imageFormat: selectedExportFormat?.id ? selectedExportFormat.id : null,
      });
      if (res.success) {
        if (res.data.numberPhoto) {
          setNotificationMessage(
            "Your submissions are currently being exported. We will send you an email once the export is completed.",
          );
        } else {
          setNotificationMessage(
            "Export does not occur because not one photo matches the selected filters",
          );
        }
        dispatch(hiddenLoader());
        onCloseExportModal();
        setTimeout(() => toggleNotificationModal(true), 400);
      }
    } catch (e) {
      dispatch(hiddenLoader());
      console.log(e);
    }
  };

  useLayoutEffect(() => {
    fetchStudioNames().catch();
    fetchAfterPeriods().catch();
    if (submissionsActiveListId === 4) fetchInvitedData().catch();
    if (submissionsActiveListId === 0) fetchAllSubmissionsData().catch();
    if (submissionsActiveListId === 1) fetchReviewData().catch();
    if (submissionsActiveListId === 3) fetchRejectedData().catch();
    if (submissionsActiveListId === 2) fetchAcceptedData().catch();
  }, [
    submissionsActiveListId,
    selectedStudio,
    selectedPeriod,
    pageIndex,
    pageSize,
  ]);

  return (
    <div className="full-height full-width">
      <div className="flex justify-space-between a-items-c mb40">
        <h2>Submissions</h2>
        <WhiteButton
          icon={ExportIcon}
          onClick={onOpenExportModal}
          text="Export Photos"
          customStyles={"black-btn custom-studios-btn"}
        />
      </div>
      <div>
        <CustomTabs
          value={submissionsActiveListId}
          onChange={(e, value) => handleChangeList(value)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
        >
          {submissionsLists.map((item) => (
            <CustomTab label={item.name} key={item.id} />
          ))}
        </CustomTabs>
      </div>
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "rgba(17, 17, 17, 1)",
          border: "1px solid rgba(255, 255, 255, 0.16)",
          borderRadius: "24px",
          marginTop: "40px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {submissionsActiveListId === 0 && (
            <AllSubmissionsDataGrid
              fetchSubmissionsData={fetchAllSubmissionsData}
              dataType="all"
              setSelectedStudio={setSelectedStudio}
              selectedStudio={selectedStudio}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              setPageSize={setPageSize}
              totalPages={totalPages}
            />
          )}
          {submissionsActiveListId === 1 && (
            <AllSubmissionsDataGrid
              dataType={submissionStatus.review.name}
              fetchSubmissionsData={fetchReviewData}
              setSelectedStudio={setSelectedStudio}
              selectedStudio={selectedStudio}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              setPageSize={setPageSize}
              totalPages={totalPages}
            />
          )}
          {submissionsActiveListId === 3 && (
            <AllSubmissionsDataGrid
              dataType={submissionStatus.rejected.name}
              fetchSubmissionsData={fetchRejectedData}
              setSelectedStudio={setSelectedStudio}
              selectedStudio={selectedStudio}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              setPageSize={setPageSize}
              totalPages={totalPages}
            />
          )}
          {submissionsActiveListId === 2 && (
            <AllSubmissionsDataGrid
              dataType={submissionStatus.accepted.name}
              fetchSubmissionsData={fetchAcceptedData}
              setSelectedStudio={setSelectedStudio}
              selectedStudio={selectedStudio}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              setPageSize={setPageSize}
              totalPages={totalPages}
            />
          )}
          {submissionsActiveListId === 4 && (
            <InvitedDataGrid
              setSelectedStudio={setSelectedStudio}
              selectedStudio={selectedStudio}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              setPageSize={setPageSize}
              totalPages={totalPages}
            />
          )}
        </Box>
      </Paper>
      <ActionsModal
        successActionText="Export"
        title="Export photos"
        isOpen={isExportModalOpen}
        onClose={onCloseExportModal}
        successAction={onExport}
      >
        <>
          <div className="mt-4">
            <div className="flex a-items-start gap8 flex-column flex-start">
              <p className="text-white font12">Studio</p>
              <LongDropDownElement
                selectedElement={selectedExportStudio}
                setSelectedElement={setSelectedExportStudio}
                title={"All studios"}
                data={studioNames}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex a-items-start gap8 flex-column flex-start">
              <p className="text-white font12">Submission status</p>
              <LongDropDownElement
                selectedElement={selectedExportSubmissionStatus}
                setSelectedElement={setSelectedExportSubmissionStatus}
                title={"All"}
                data={exportSubmissionStatus}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="flex a-items-start gap8 flex-column flex-start">
              <p className="text-white font12">Image format</p>
              <LongDropDownElement
                selectedElement={selectedExportFormat}
                setSelectedElement={setSelectedExportFormat}
                title={"All"}
                data={imagesFormat}
              />
            </div>
          </div>
        </>
      </ActionsModal>
      <NotificationModal
        onClose={closeNotificationModal}
        isOpen={isNotificationModalOpen}
        text={notificationMessage}
      />
    </div>
  );
};

export default Submissions;
