import React from "react";
import { slide as Menu } from "react-burger-menu";
import LogoIcon from "../../assets/svg/logo.svg";
import { userRole } from "../../constants/userRole";
import classNames from "classnames";
import {
  contactsTabName,
  outputsTabName,
  studiosTabName,
  tabsName,
} from "../../constants/tabs";
import {
  setActiveContactsActiveTab,
  setActiveOutputsActiveTab,
  setActiveStudiosTab,
  setActiveSubmissionsActiveListId,
  setActiveTab,
} from "../../store/slices/tabs";
import ResourcesIcon from "../../assets/svg/resources";
import MessageQuestion from "../../assets/svg/MessageQuestion";
import { getUserRole } from "../../helpers/localStorageHelpers";
import { useAppDispatch, useAppSelector } from "../../store";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const user = getUserRole();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { activeTab } = useAppSelector((state) => state.tabs);
  const { companyName } = useAppSelector((state) => state.user.userData);

  return (
    <Menu width={340}>
      <div className={"drawerContainer"}>
        <div className="logoContainer">
          <img src={LogoIcon} alt="" />
        </div>
        <div className="studioBtnContainer">
          <div className="studioBtn flex flex-start gap16">
            <div className="studioBtnBox border-light light-gray-text flex a-items-c">
              RT
            </div>
            <div className="flex flex-column">
              <p className="studioBtnText light-text font14">{companyName}</p>
              {/*<p className="light-gray-text font10">Ringo Kid</p>*/}
            </div>
          </div>
        </div>
        <div className="drawerListContainer flex flex-column gap20">
          {user === userRole.corporateAdmin && (
            <p
              className={classNames("drawerListItem cursor-pointer", {
                ["activeDrawerListItem"]: activeTab === tabsName.dashboard,
                ["defaultDrawerListItem"]: activeTab !== tabsName.dashboard,
              })}
              onClick={() => {
                dispatch(setActiveTab(tabsName.dashboard));
                navigation("/dashboard");
              }}
            >
              Dashboard
            </p>
          )}
          {user === userRole.corporateAdmin && (
            <p
              className={classNames("drawerListItem cursor-pointer", {
                ["activeDrawerListItem"]: activeTab === tabsName.studios,
                ["defaultDrawerListItem"]: activeTab !== tabsName.studios,
              })}
              onClick={() => {
                dispatch(setActiveStudiosTab(studiosTabName.list));
                dispatch(setActiveTab(tabsName.studios));
                navigation("/studios");
              }}
            >
              Studios
            </p>
          )}
          <p
            className={classNames("drawerListItem cursor-pointer", {
              ["activeDrawerListItem"]: activeTab === tabsName.outputs,
              ["defaultDrawerListItem"]: activeTab !== tabsName.outputs,
            })}
            onClick={() => {
              dispatch(setActiveOutputsActiveTab(outputsTabName.list));
              dispatch(setActiveTab(tabsName.outputs));
              navigation("/outputs");
            }}
          >
            Outputs
          </p>
          <p
            className={classNames("drawerListItem cursor-pointer", {
              ["activeDrawerListItem"]: activeTab === tabsName.contacts,
              ["defaultDrawerListItem"]: activeTab !== tabsName.contacts,
            })}
            onClick={() => {
              dispatch(setActiveContactsActiveTab(contactsTabName.list));
              dispatch(setActiveTab(tabsName.contacts));
              navigation("/contacts");
            }}
          >
            Contacts
          </p>
          <p
            className={classNames("drawerListItem cursor-pointer", {
              ["activeDrawerListItem"]: activeTab === tabsName.submissions,
              ["defaultDrawerListItem"]: activeTab !== tabsName.submissions,
            })}
            onClick={() => {
              dispatch(setActiveSubmissionsActiveListId(0));
              dispatch(setActiveTab(tabsName.submissions));
              navigation("/submissions");
            }}
          >
            Submissions
          </p>
          <p
            className={classNames("drawerListItem cursor-pointer", {
              ["activeDrawerListItem"]: activeTab === tabsName.gallery,
              ["defaultDrawerListItem"]: activeTab !== tabsName.gallery,
            })}
            onClick={() => {
              dispatch(setActiveTab(tabsName.gallery));
              navigation("/gallery");
            }}
          >
            Gallery
          </p>
        </div>
        <div
          className="drawerBottomContainer gap20 flex flex-column position-absolute"
          style={{ bottom: 20 }}
        >
          <div
            onClick={() => dispatch(setActiveTab(tabsName.resources))}
            className="flex flex-start gap8 a-items-c cursor-pointer"
          >
            <ResourcesIcon
              stroke={
                activeTab === tabsName.resources
                  ? "rgba(240, 240, 240, 1)"
                  : "#747474"
              }
            />
            <p
              className={classNames("drawerBottomListItem font12", {
                ["activeBottomListItem"]: activeTab === tabsName.resources,
                ["defaultBottomListItem"]: activeTab !== tabsName.resources,
              })}
            >
              Resources
            </p>
          </div>
          <div
            onClick={() => dispatch(setActiveTab(tabsName.messageQuestion))}
            className="flex flex-start gap8 a-items-c cursor-pointer"
          >
            <MessageQuestion
              stroke={
                activeTab === tabsName.messageQuestion
                  ? "rgba(240, 240, 240, 1)"
                  : "#747474"
              }
            />
            <p
              className={classNames("drawerBottomListItem font12", {
                ["activeBottomListItem"]:
                  activeTab === tabsName.messageQuestion,
                ["defaultBottomListItem"]:
                  activeTab !== tabsName.messageQuestion,
              })}
            >
              Help Desk
            </p>
          </div>
          <p className="light-gray-text font12">
            Copyright © 2024. Studio Pod.
          </p>
        </div>
      </div>
    </Menu>
  );
};

export default Sidebar;
