import React from "react";
import ListsComponent from "./components/ListsComponent/ListsComponent";
import ContactDetails from "./components/ContactDetails/ContactDetails";
import { useAppSelector } from "store";
import { contactsTabName } from "constants/tabs";

const Contacts = () => {
  const { contactsActiveTab } = useAppSelector((state) => state.tabs);
  return (
    <>
      {contactsActiveTab === contactsTabName.list ? (
        <ListsComponent />
      ) : (
        <ContactDetails />
      )}
    </>
  );
};

export default Contacts;
