export type User = {
  token: string;
  isUnlockAccount: boolean;
};
export const isAuth = () => !!localStorage.getItem("token");
export const getToken = () => localStorage.getItem("token");
export const clearToken = () => localStorage.removeItem("token");

export const setToken = (data: string) => localStorage.setItem("token", data);

export const setRefreshToken = (token: string) =>
  localStorage.setItem("refreshToken", token);

export const getRefreshToken = () => localStorage.getItem("refreshToken");

export const setUserEmail = (email: string) =>
  localStorage.setItem("userEmail", email);

export const getUserEmail = () => localStorage.getItem("userEmail");

export const setUserRole = (data: string) =>
  localStorage.setItem("userRole", data);

export const getUserRole = () => localStorage.getItem("userRole");
export const clearUserRole = () => localStorage.removeItem("userRole");
export const clearUserEmail = () => localStorage.removeItem("userEmail");
export const clearRefreshToken = () => localStorage.removeItem("refreshToken");

export const setDataToLocalStorage = ({
  key,
  data,
}: {
  key: string;
  data: string;
}) => localStorage.setItem(key, data);

export const getDataFromLocalStorage = (key: string) =>
  localStorage.getItem(key);
