import React, { useState } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  getPaginationRowModel,
  Row,
} from "@tanstack/react-table";
import { Contact, setActiveContactDetailsId } from "store/slices/contacts";
import { contactsTabName, tabsName } from "constants/tabs";
import { setActiveContactsActiveTab, setActiveTab } from "store/slices/tabs";
import "./styles.scss";
import { useAppDispatch, useAppSelector } from "store";

const columnHelper = createColumnHelper<Contact>();

const columns = [
  columnHelper.accessor("firstName", {
    cell: (info) => info.getValue(),
    header: () => "First Name",
    size: 200,
  }),
  columnHelper.accessor("lastName", {
    cell: (info) => info.getValue(),
    header: () => "Last Name",
    size: 200,
  }),
  columnHelper.accessor("email", {
    cell: (info) => info.getValue(),
    header: () => "Email",
    size: 150,
  }),
  columnHelper.accessor("submissionsCount", {
    header: () => "Submissions",
    cell: (info) => info.renderValue(),
    size: 120,
  }),
  columnHelper.accessor("createdAt", {
    header: () => "Created at",
    cell: (info) => info.renderValue(),
    size: 150,
  }),
];
const DataGrid = () => {
  const dispatch = useAppDispatch();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { contactsLists } = useAppSelector((state) => state.contacts);

  const table = useReactTable({
    data: contactsLists[0].contacts,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onOpenContactDetails = (row: Row<Contact>) => {
    dispatch(setActiveContactDetailsId(row.original.id));
    dispatch(setActiveTab(tabsName.contacts));
    dispatch(setActiveContactsActiveTab(contactsTabName.contact));
  };

  return (
    <div className="flex flex-col pt-4 pb-6 full-width">
      <table className={"full-width"} style={{ tableLayout: "fixed" }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="border-b table-header table-border-b"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="py-4 font-medium t-align-left"
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none flex min-w-[36px] flex-start position-relative"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <span className="pl-2">↑</span>,
                        desc: <span className="pl-2">↓</span>,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {table.getRowModel()?.rows?.length > 0 ? (
          <tbody style={{ width: "100%" }}>
            {table.getRowModel()?.rows?.map((row) => (
              <tr
                key={row.id}
                className="border-b custom-table-row table-border-b"
                onClick={() => onOpenContactDetails(row)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="pt-[14px] pb-[18px] table-item">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={columns.length} className="text-center py-4">
                <p className="text-white font-s20">No contacts found.</p>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default DataGrid;
