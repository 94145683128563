import React, { forwardRef, useEffect, useState } from "react";
import "./styles.scss";
import SearchInput from "../fields/SearchInput/SearchInput";
import Button from "@mui/material/Button";
import MenuIcon from "assets/svg/menu-icon.svg";
import { clearPersistor, useAppDispatch, useAppSelector } from "store";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { clearToken } from "helpers/localStorageHelpers";
import classNames from "classnames";
import {
  setActiveContactDetailsId,
  setPredictions,
} from "../../store/slices/contacts";
import {
  setActiveContactsActiveTab,
  setActiveTab,
} from "../../store/slices/tabs";
import { contactsTabName, tabsName } from "../../constants/tabs";
import Sidebar from "../BurgerMenu/BurgerMenu";

type TopContainerProps = {
  setSearchTerm: (val: string) => void;
  searchTerm: string;
};

const TopContainer = forwardRef<HTMLDivElement, TopContainerProps>(
  (props, ref) => {
    const { setSearchTerm, searchTerm } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { allContacts, predictions } = useAppSelector(
      (state) => state.contacts,
    );
    const { companyName } = useAppSelector((state) => state.user.userData);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);

      // Cleanup function to remove event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    console.log("windowWidth", windowWidth);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onLogout = () => {
      clearPersistor();
      clearToken();
      navigate("/login");
    };

    const handleChange = (val: string) => {
      setSearchTerm(val);
      if (val.length > 0) {
        if (allContacts.length) {
          const newPredictions = allContacts.filter((user) => {
            if (user.firstName && user.lastName) {
              return (
                user.firstName.toLowerCase().startsWith(val.toLowerCase()) ||
                user.lastName.toLowerCase().startsWith(val.toLowerCase())
              );
            } else if (user.firstName && !user.lastName) {
              return user.firstName.toLowerCase().startsWith(val.toLowerCase());
            } else if (!user.firstName && user.lastName) {
              return user.lastName.toLowerCase().startsWith(val.toLowerCase());
            }
          });
          dispatch(setPredictions([...newPredictions]));
        }
      } else {
        dispatch(setPredictions([]));
      }
    };

    const openContactDetails = (id: number) => {
      dispatch(setPredictions([]));
      setSearchTerm("");
      dispatch(setActiveTab(tabsName.contacts));
      dispatch(setActiveContactDetailsId(id));
      dispatch(setActiveContactsActiveTab(contactsTabName.contact));
    };

    return (
      <div className="topContainer flex justify-space-between">
        {windowWidth < 1029 && (
          <div style={{ width: 40, height: 40 }}>
            <Sidebar />
          </div>
        )}
        <div className="searchInputContainer position-relative">
          <SearchInput onChange={handleChange} inputVal={searchTerm} />
          {!!predictions.length && (
            <div
              ref={ref}
              className={classNames(
                "position-absolute full-width predictionsContainer border-light",
              )}
            >
              {predictions.map((item) => (
                <div
                  key={item.id}
                  className={"prediction"}
                  onClick={() => openContactDetails(item.id)}
                >
                  {item.firstName} {item.lastName}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="menuContainer flex a-items-c gap8">
          <span className="light-text font14">{companyName}</span>
          <Button
            sx={{
              height: 44,
              backgroundColor: "rgba(30, 30, 30, 1)",
              borderRadius: 6,
              padding: "8px 12px",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              gap: 1,
            }}
            onClick={handleClick}
          >
            <div className={"menuAvatar"} />
            <img src={MenuIcon} alt="" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              sx: {
                background: "rgba(30, 30, 30, 1)",
                borderRadius: "16px",
                borderWidth: 0,
                color: "rgba(152, 152, 152, 1)",
                fontSize: 12,
                fontFamily: "MatterRegular",
              },
            }}
            PaperProps={{ sx: { background: "inherit" } }}
          >
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    );
  },
);

export default TopContainer;

TopContainer.displayName = "TopContainer";
