export const tabsName = {
  studios: "studios",
  outputs: "outputs",
  dashboard: "dashboard",
  contacts: "contacts",
  submissions: "submissions",
  gallery: "gallery",
  resources: "resources",
  messageQuestion: "messageQuestion",
};

export const studiosTabName = {
  list: "list",
  create: "create",
  edit: "edit",
};

export const contactsTabName = {
  list: "list",
  contact: "contact",
};

export const outputsTabName = {
  list: "list",
  edit: "edit",
};
