import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./views/HomePage/HomePage";
import "./styles/styles.scss";
import Layout from "./views/Layout/Layout";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import ProtectedRoute from "routes/ProtectedRoute";
import SuccessfulMailPage from "./views/SuccessfulMailPage/SuccessfulMailPage";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import CreateInvite from "./views/CreateInvite/CreateInvite";
import Export from "./views/Export/Export";
import ContactExports from "views/ContactExports/ContactExports";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Black
      dark: "rgba(17, 17, 17, 1)",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            backgroundColor: "primary.main",
            color: "white",
          },
        },
      ],
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: "regular" },
          style: {
            backgroundColor: "primary.dark",
            color: "white",
          },
        },
      ],
    },
  },
});

const privateRoutes = [
  {
    path: "/",
    redirectTo: "/login",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/dashboard",
    redirectTo: "/login",
    element: <Layout />,
  },
  {
    path: "/studios",
    redirectTo: "/login",
    element: <Layout />,
  },
  {
    path: "/outputs",
    redirectTo: "/login",
    element: <Layout />,
  },
  {
    path: "/contacts",
    redirectTo: "/login",
    element: <Layout />,
  },
  {
    path: "/submissions",
    redirectTo: "/login",
    element: <Layout />,
  },
  {
    path: "/gallery",
    redirectTo: "/login",
    element: <Layout />,
  },
];

function App() {
  useEffect(() => {
    window.history.pushState(null, document.title, location.href);
    window.addEventListener("popstate", function (event) {
      console.log("event", event);
      window.history.pushState(null, document.title, location.href);
    });
  });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              {privateRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <ProtectedRoute redirectTo={route.redirectTo}>
                      {route.element}
                    </ProtectedRoute>
                  }
                />
              ))}
              <Route path="/login" element={<HomePage />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/successful-mail-verification"
                element={<SuccessfulMailPage />}
              />
              <Route path="/create-invite" element={<CreateInvite />} />
              <Route path="/export" element={<Export />} />
              <Route path="/contact-exports" element={<ContactExports />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
