import React, { ChangeEvent, FC, useState } from "react";
import styles from "./DefaultInput.module.scss";
import classNames from "classnames";

type DefaultInputProps = {
  label?: string;
  placeholder?: string;
  onChange: (val: string) => void;
  inputVal?: string;
  type?: string;
  error?: string;
  maxLength?: number;
  customClassNames?: string;
  errorWithoutText?: boolean;
  min?: number;
  max?: number;
  goldLabel?: boolean;
  isRequired?: boolean;
};
const DefaultInput: FC<DefaultInputProps> = ({
  label,
  placeholder = "",
  onChange,
  inputVal,
  type = "text",
  error,
  maxLength = 500,
  customClassNames,
  errorWithoutText,
  min,
  max,
  goldLabel = false,
  isRequired = false,
}) => {
  const [val, setVal] = useState(inputVal || "");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(value);
    setVal(value);
  };

  return (
    <div className="position-relative">
      {!!label && (
        <p
          className={classNames(styles.label, {
            [styles.goldLabel]: goldLabel,
            [styles.required]: isRequired,
          })}
        >
          {label}
        </p>
      )}
      <input
        type={type}
        value={inputVal || val}
        className={classNames(
          styles.input,
          { [styles.inputWithData]: !!inputVal || !!val },
          { ["input-error"]: !!error || errorWithoutText },
          customClassNames,
        )}
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength}
        min={min}
        max={max}
      />
      {error && (
        <p className="error-text position-absolute font12" style={{ left: 0 }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default DefaultInput;
